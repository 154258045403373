import { BookingResponseModel } from "../../http/response/booking/booking-response.model";

export class BookingExportModel extends BookingResponseModel {

    foodOrders: FoodOrderModel[] = [];
}

export class FoodOrderModel {

    constructor(type: string, value: string, total: number) {
        this.type = type;
        this.value = value;
        this.total = total;
    }

    type!: string;
    value!: string;
    total!: number;
}