import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layout-passport',
  templateUrl: './layout-passport.component.html'
})
export class LayoutPassportComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
