export class BookingMoveRequestModel {

    constructor(channelDateId: number, bookingIds: number[]) {
        this.channelDateId = channelDateId;
        this.bookingIds = bookingIds;
    }

    channelDateId!: number;
    bookingIds: number[] = []
    
}
