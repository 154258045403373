import { Injectable } from '@angular/core';
import { NavigationEnd } from '@angular/router';
import { NavigationHistoryModel } from '@shared/models/common/core/navigation-history.model';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {

  private history: NavigationHistoryModel[] = [];

  // Maximum history limit.
  private static readonly  maxHistoryLength = 10;

  constructor() {

  }

  addUrlHistory(event: NavigationEnd, title: string): void {
    // console.log(`Url: ${event.urlAfterRedirects}, Title: ${title}`)
    this.history.push(new NavigationHistoryModel(event.urlAfterRedirects, title));
    if (this.history.length > NavigationService.maxHistoryLength) {
      this.history.shift();
    }
  }

  getUrlHistory(): NavigationHistoryModel[] {
    return this.history;
  }

  getPreviousPage(): NavigationHistoryModel {
    return this.history.length > 1 ? this.history[this.history.length - 2] : new NavigationHistoryModel('/');
  }
}