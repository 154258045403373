<nz-drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" [nzWidth]="720" nzPlacement="right" [nzTitle]="titleTempRef"
    [nzFooter]="footerTpl" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div class="tnt-drawer-event-edit">
            <form nz-form [formGroup]="eventDateForm" nzLayout="vertical">

                <div class="tnt-drawer-event-edit__title">
                    {{ eventDate?.event?.name }}
                </div>
                <nz-divider></nz-divider>

                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <div class="tnt-drawer-event-edit__subtitle">
                            Which venue will your event be held at? If you aren’t sure, leave blank.
                        </div>
        
                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input formControlName="venueName" placeholder="Venue" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>

                        <div class="tnt-drawer-event-edit__subtitle">
                            Which city will your event be held at? If you aren’t sure, leave blank.
                        </div>     

                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input formControlName="venueCity" placeholder="City" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>

                        <div class="tnt-drawer-event-edit__subtitle">
                            Additional information and notes.
                        </div>    

                        <nz-form-item>
                            <nz-form-control>
                                <textarea nz-input formControlName="notes" placeholder="Notes" 
                                    [nzAutosize]="{ minRows: 4, maxRows: 4 }" nzSize="large"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>


                <div class="tnt-drawer-event-edit__title">Date and time</div>
                <nz-divider></nz-divider>

                <div class="tnt-drawer-event-edit__subtitle">
                    When does your event start, and at what time?
                </div>

                <div nz-row nzGutter="24" formGroupName="dateTime">
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control nzErrorTip="Please input date and time!">
                                <nz-date-picker formControlName="date" nzSize="large" nzFormat="dd/MM/yyyy"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="12">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-time-picker formControlName="time" nzSize="large" nzFormat="HH:mm"></nz-time-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div class="tnt-drawer-event-edit__title">Capacity & Sold Out Alert</div>
                <nz-divider></nz-divider>

                <div class="tnt-drawer-event-edit__subtitle">
                    What’s your events capacity and set up alerting if want to know when you have nearly sold out.
                </div>

                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-input-number nzSize="large" formControlName="capacity" [nzStep]="1" nzPlaceHolder="Number of tickets"></nz-input-number>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div class="tnt-drawer-event-edit__subtitle">
                    Alert when {{ eventDateForm.controls['alert']!.get('value')!.value ? eventDateForm.controls['alert']!.get('value')!.value : 0 }} tickets are sold.
                </div>

                <div nz-row formGroupName="alert">
                    <div nz-col nzFlex="60px">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-switch formControlName="status" (ngModelChange)="onAlertSwitchChanged($event)"></nz-switch>
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzMd="8" nzFlex="auto">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-input-number nzSize="large" formControlName="value" [nzStep]="1" nzPlaceHolder="Number of tickets"
                                [nzDisabled]="!eventDateForm.controls['alert']!.get('status')!.value"></nz-input-number>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

            </form>
        </div>
        
    </ng-container>

    <ng-template #footerTpl>
        <div class="tnt-widget-drawer__footer">
          <button nz-button (click)="close()">Cancel</button>
          <button nz-button nzType="primary" [nzLoading]="isUpdatingEventDate" (click)="onUpdateClick()">Update</button>
        </div>
      </ng-template>
</nz-drawer>

<ng-template #titleTempRef>
    <div nz-row class="tnt-widget-table__header">
        <div nz-col nzSpan="22">
            <i class="fas fa-edit"></i> Edit Event Date
        </div>
        <div nz-col nzSpan="2" class="tnt-widget-table__header__cross">
            <span (click)="close()">x</span>
        </div>
    </div>
</ng-template>


