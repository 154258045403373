import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, expand, map, reduce, tap, Observable } from 'rxjs';
import { PaginationUtil } from '@shared/utils/pagination-util';
import * as moment from 'moment-timezone';
import { environment } from '@env/environment';

import { MixpanelService } from './mixpanel.service';

import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { PaginationRequestModel } from '@shared/models/http/request/common/pagination-request.model';
import { PaginationResponseModel } from '@shared/models/http/response/common/pagination-response.model';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';
import { EventDateRequestQueryModel } from '@shared/models/http/request/event-date/event-date-request-query.model';
import { EventDateCreateRequestModel } from '@shared/models/http/request/event-date/event-date-create-request.model';
import { EventDateUpdateRequestModel } from '@shared/models/http/request/event-date/event-date-update-request.model';
import { EventDateCreateMultipleRequestModel } from '@shared/models/http/request/event-date/event-date-create-multiple-request.model';
import { EventDateUpdateMultipleRequestModel } from '@shared/models/http/request/event-date/event-date-update-multiple-request.model';

@Injectable({
  providedIn: 'root'
})
export class EventDateService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  constructor(
    private http: HttpClient,
    private mixpanelService: MixpanelService
  ) { }

  listWithPagination(eventDateRequestQueryModel: EventDateRequestQueryModel, paginationRequestModel: PaginationRequestModel): Observable<PaginationResponseModel<EventDateResponseModel>> {
    let params = this.generateListQuery(eventDateRequestQueryModel, paginationRequestModel);
    return this.http.get<PaginationResponseModel<EventDateResponseModel>>(`${this.apiUrl}/events/dates`, {
      params,
    });
  }

  listWithoutPagination(eventDateRequestQueryModel: EventDateRequestQueryModel): Observable<EventDateResponseModel[]> {
    const paginationRequestModel = new PaginationRequestModel(1, PaginationUtil.defaultPageSize);
    return this.listWithPagination(eventDateRequestQueryModel, paginationRequestModel).pipe(
      expand(response => {
        paginationRequestModel.page++;
        if (response && response.totalPages && paginationRequestModel.page <= response.totalPages) {
          return this.listWithPagination(eventDateRequestQueryModel, paginationRequestModel);
        }
        return EMPTY;
      }),
      map((obj: any) => obj.data),
      reduce((acc, x: any) => acc.concat(x))
    );
  }
 
  getById(id: number): Observable<EventDateResponseModel> {
    return this.http.get<ApiDataResponseModel<EventDateResponseModel>>(`${this.apiUrl}/events/dates/${id}`).pipe(
      map((response: ApiDataResponseModel<EventDateResponseModel>) => {
        return response.data;
      })
    );
  }

  create(eventDateCreateRequestModel: EventDateCreateRequestModel): Observable<EventDateResponseModel> {
    return this.http.post<ApiDataResponseModel<EventDateResponseModel>>(`${this.apiUrl}/events/dates`, eventDateCreateRequestModel).pipe(
      map((response: ApiDataResponseModel<EventDateResponseModel>) => {
        return response.data;
      }),
      tap((eventDate: EventDateResponseModel) => {
        this.mixpanelService.track('User created an Event Date', eventDate);
      })
    );
  }

  // Bulk Create
  createMultiple(eventDateCreateMultipleRequestModel: EventDateCreateMultipleRequestModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/bulk/events/dates`, eventDateCreateMultipleRequestModel).pipe(
      tap(() => {
        this.mixpanelService.track('User created multiple (Bulk) Event Date', eventDateCreateMultipleRequestModel);
      })
    );
  }

  update(id: number, eventDateUpdateRequestModel: EventDateUpdateRequestModel): Observable<EventDateResponseModel> {
    return this.http.put<ApiDataResponseModel<EventDateResponseModel>>(`${this.apiUrl}/events/dates/${id}`, eventDateUpdateRequestModel).pipe(
      map((response: ApiDataResponseModel<EventDateResponseModel>) => {
        return response.data;
      }),
      tap((eventDate: EventDateResponseModel) => {
        this.mixpanelService.track('User updated an Event Date', eventDate);
      })
    );
  }

  // Bulk Update
  updateMultiple(eventDateUpdateMultipleRequestModel: EventDateUpdateMultipleRequestModel): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}/bulk/events/dates`, eventDateUpdateMultipleRequestModel).pipe(
      tap(() => {
        this.mixpanelService.track('User updated multiple (Bulk) Event Date', eventDateUpdateMultipleRequestModel);
      })
    );
  }

  delete(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/events/dates/${id}`).pipe(
      tap(() => {
        this.mixpanelService.track('User deleted an Event Date', { eventDateId: id });
      })
    );
  }

  // Bulk Delete
  deleteMultiple(eventId: number, ids: number[]): Observable<void> {
    let params = new HttpParams().set('EventId', eventId.toString());
    ids.forEach(id => {
      params = params.append('EventDateIds', id.toString());
    });
    return this.http.delete<void>(`${this.apiUrl}/bulk/events/dates`, { params }).pipe(
      tap(() => {
        this.mixpanelService.track('User deleted multiple (Bulk) Event Date', { eventDateIds: ids });
      })
    );
  }

  private generateListQuery(eventDateRequestQueryModel: EventDateRequestQueryModel, paginationRequestModel?: PaginationRequestModel): HttpParams {
    let params = PaginationUtil.setPaginationParams(paginationRequestModel);

    if (eventDateRequestQueryModel.eventId) {
      params = params.append('eventId', eventDateRequestQueryModel.eventId);
    }

    if (eventDateRequestQueryModel.venueId) {
      params = params.append('venueId', eventDateRequestQueryModel.venueId);
    }

    if (eventDateRequestQueryModel.from) {
      const from = moment(eventDateRequestQueryModel.from, environment.timeZone).format('YYYY-MM-DD HH:mm:ss');
      params = params.append('from', from);
    }

    if (eventDateRequestQueryModel.to) {
      const to = moment(eventDateRequestQueryModel.to, environment.timeZone).format('YYYY-MM-DD HH:mm:ss');
      params = params.append('to', to);
    }

    return params;
  }
}
