import { MetaDataModel } from "@shared/models/common/core/meta-data.model";

export class MetaDataUtil {

    static set(metaData: MetaDataModel) {
        localStorage.setItem('meta_data', JSON.stringify(metaData));
    }

    static get(): MetaDataModel {
        const metaDataString = localStorage.getItem('meta_data');
        if (!metaDataString) {
            return new MetaDataModel();
        }

        const metaData = JSON.parse(metaDataString);
        if (metaData.eventSearchDateRange && metaData.eventSearchDateRange.startDate) {
            metaData.eventSearchDateRange.startDate = new Date(metaData.eventSearchDateRange.startDate);
        }
        if (metaData.eventSearchDateRange && metaData.eventSearchDateRange.endDate) {
            metaData.eventSearchDateRange.endDate = new Date(metaData.eventSearchDateRange.endDate);
        }
        return metaData;
    }

    static clear() {
        localStorage.removeItem('meta_data');
    }
}