<nz-drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" [nzWidth]="720" nzPlacement="right" [nzTitle]="titleTempRef"
    [nzFooter]="footerTpl" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div class="tnt-drawer-event-edit">

            <nz-alert nzType="error" nzMessage="Important warning"
                nzDescription="You can update the following fields in bulk for the selected event dates. Please note that any updates will affect all selected event dates."></nz-alert>

            <br>

            <form nz-form [formGroup]="eventDateForm" nzLayout="vertical">
                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <div class="tnt-drawer-event-edit__title">Venue</div>
                        <div class="tnt-drawer-event-edit__subtitle">
                            Which venue will the selected events be held at? If left blank, this field will not be updated.
                        </div>
                
                        <nz-divider></nz-divider>
        
                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input formControlName="venueName" placeholder="Venue" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzSpan="24">
                        <div class="tnt-drawer-event-edit__title">City</div>
                        <div class="tnt-drawer-event-edit__subtitle">
                            Which city will the selected events be held at? If left blank, this field will not be updated.
                        </div>

                        <nz-divider></nz-divider>

                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input formControlName="venueCity" placeholder="City" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>

                    <div nz-col nzSpan="24">
                        <div class="tnt-drawer-event-edit__title">Time</div>
                        <div class="tnt-drawer-event-edit__subtitle">
                            What time do the selected events start? If left blank, this field will not be updated.
                        </div>

                        <nz-divider></nz-divider>

                        <div nz-row nzGutter="24" formGroupName="dateTime">
                            <div nz-col nzSpan="12">
                                <nz-form-item>
                                    <nz-form-control>
                                        <nz-time-picker formControlName="time" nzSize="large" nzFormat="HH:mm"></nz-time-picker>
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </div>

                    <div nz-col nzSpan="24">
                        <div class="tnt-drawer-event-edit__title">Capacity</div>
                        <div class="tnt-drawer-event-edit__subtitle">
                            What is the capacity of the selected events? If left blank, this field will not be updated.
                        </div>

                        <nz-divider></nz-divider>

                        <nz-form-item>
                            <nz-form-control>
                                <nz-input-number nzSize="large" formControlName="capacity" [nzStep]="1" nzPlaceHolder="Number of tickets"></nz-input-number>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>

    <ng-template #footerTpl>
        <div class="tnt-widget-drawer__footer">
          <button nz-button (click)="close()">Cancel</button>
          <button nz-button nzType="primary" (click)="onDeleteButtonClick()" nzDanger>Delete</button>
          <button nz-button nzType="primary" [nzLoading]="isUpdatingEventDates" (click)="onUpdateButtonClick()" [disabled]="!eventDateForm.valid || eventDateForm.pristine">Update</button>
        </div>
      </ng-template>
</nz-drawer>

<ng-template #titleTempRef>
    <div nz-row class="tnt-widget-table__header">
        <div nz-col nzSpan="22">
            <i class="fas fa-edit"></i> Edit Selected Event Dates
        </div>
        <div nz-col nzSpan="2" class="tnt-widget-table__header__cross">
            <span (click)="close()">x</span>
        </div>
    </div>
</ng-template>


