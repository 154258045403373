import { Component, OnInit, TemplateRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { validateFormGroup } from '@shared/utils/form.util';

import { MailHelper } from '@shared/utils/mail-helper';
import { MailService } from '@shared/services/mail.service';
import { UserService } from '@core/services/user.service';

import { BookingStatusEnum } from '@shared/enums/booking-status.enum';
import { RecipientModel } from '@shared/models/common/recipient.model';
import { MailCustomArgumentModel, MailRequestModel } from '@shared/models/http/request/mail/mail-request.model';
import { BookingResponseModel } from '@shared/models/http/response/booking/booking-response.model';

export enum ModeEnum {
  FORM,
  PREVIEW,
}

export enum EmailsCancelledStatusEnum {
  NO_CANCELLED,
  ALL_CANCELLED,
  SOME_CANCELLED,
}

@UntilDestroy()
@Component({
  selector: 'app-email-drawer',
  templateUrl: './email-drawer.component.html',
})
export class EmailDrawerComponent implements OnInit {

  ModeEnum = ModeEnum;
  EmailsCancelledStatusEnum = EmailsCancelledStatusEnum;

  mode = ModeEnum.FORM;
  modal!: NzModalRef;

  bookings: BookingResponseModel[] = [];
  filteredBookings: BookingResponseModel[] = [];

  emailForm!: UntypedFormGroup;
  emailData = '';

  recipients: RecipientModel[] = [];

  to?: string;
  missingEmailCount = 0;

  testEmailForm!: UntypedFormGroup;
  testEmailModal!: NzModalRef;

  isEmailSending = false;
  isTestEmailSending = false;

  isDrawerVisible = false;

  Editor = ClassicEditor;
  editorConfig = {
    placeholder: 'Type some text...',
    toolbar: {
      items: [
        'heading',
        '|',
        'bold',
        'italic',
        '|',
        'bulletedList',
        'numberedList',
        '|',
        'insertTable',
        '|',
        'undo',
        'redo'
      ]
    }
  };

  isBulkEmail = false;
  isSentToCancelledBookings = false;
  selectedEmailsCancelledStatusEnum!: EmailsCancelledStatusEnum;

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private mailService: MailService,
    private modalService: NzModalService
  ) { }

  ngOnInit(): void {
    this.emailForm = this.fb.group({
      to: [{value: null, disabled: true}, [Validators.required]],
      subject: [null, [Validators.required]]
    });

    this.testEmailForm = this.fb.group({
      email: [null, [ Validators.required, Validators.email ] ]
    });
  }

  async open(bookings: BookingResponseModel | BookingResponseModel[]): Promise<void> {
    this.isDrawerVisible = false;
    this.isSentToCancelledBookings = false;

    if (Array.isArray(bookings)) {
      this.bookings = bookings;
      this.isBulkEmail = true;
    } else {
      this.bookings = [bookings];
      this.isBulkEmail = false;
    }

    // If all bookings cancelled, switch on #sentToCancelledBookings button and allow email to cancelled booking.
    this.setSelectedEmailsCancelledStatus();
    if (this.selectedEmailsCancelledStatusEnum === EmailsCancelledStatusEnum.ALL_CANCELLED) {
      this.onCancelledEmailSwitchChanged(true);
    } else {
      this.onCancelledEmailSwitchChanged(false);
    }

    this.initEditor();
    this.initEmailForm();
    this.setRecipients();
    this.validateRecipients();
  }

  close(): void {
    this.isDrawerVisible = false;
  }

  setSelectedEmailsCancelledStatus() {
    const cancelledBooking = this.bookings.filter(item => item.customer.email && item.status !== BookingStatusEnum.CANCELLED && item.status !== BookingStatusEnum.CANCELLED_BY_DINER);
    if (!cancelledBooking.length) {
      this.selectedEmailsCancelledStatusEnum = EmailsCancelledStatusEnum.ALL_CANCELLED;
    } else if (this.bookings.length === cancelledBooking.length) {
      this.selectedEmailsCancelledStatusEnum = EmailsCancelledStatusEnum.NO_CANCELLED;
    } else {
      this.selectedEmailsCancelledStatusEnum = EmailsCancelledStatusEnum.SOME_CANCELLED;
    }
  }

  initEditor() {
    // CKEditor toolbar resize hack!
    const ckEditorElement = document.querySelector('.ck-editor') as HTMLElement | null;
    if (ckEditorElement) {
      ckEditorElement.dispatchEvent(new Event('resize'));
      ckEditorElement.style.width = "99.99%";
      setTimeout(() => {
        ckEditorElement.style.width = "100%";
      }, 100);
    }
  }

  initEmailForm() {
    // Reset Form
    this.emailData = '';
    this.emailForm.reset();
    this.testEmailForm.reset();
    this.mode = ModeEnum.FORM;
  }

  handleOk() {
    if (this.missingEmailCount > 0) {
      let errorMessage;
      if (this.missingEmailCount === 1) {
        errorMessage = `${this.missingEmailCount} diner does not have an e-mail address.`
      } else {
        errorMessage = `${this.missingEmailCount} diners do not have an e-mail address.`
      }
      this.modalService.confirm({
        nzTitle: 'Important warning',
        nzContent: errorMessage,
        nzOkText: 'Send it anyway',
        nzOnOk: () => {
          this.sendEmails();
        }
      });
    } else {
      this.sendEmails();
    }
  }

  async sendEmails() {
    this.isEmailSending = true;
    // We send ChannelDateId to SendGrid. So we map bookings by ChannelDateId.
    const mappedBookingsByChannelDate = this.getBookingsByChannelDate();
    // We send separate emails for each channel!
    for (const [key, value] of mappedBookingsByChannelDate) {
      const emailRequestModal = this.generateMailRequestModel(key, value);
      console.log('Chunked Emails : ', emailRequestModal);
      await firstValueFrom(this.mailService.send(emailRequestModal).pipe(untilDestroyed(this))).catch((err) => {
        this.modalService.error({
          nzTitle: 'Email',
          nzContent: 'An error occurred while sending e-mail. Please try again later.'
        });
        console.log('Error while sending Email. Error : ', err);
        this.isEmailSending = false;
      })
    };

    this.isEmailSending = false;
    this.modalService.success({
      nzTitle: 'Email',
      nzContent: 'Your e-mail has been sent successfully.',
      nzOnOk: () => {
        this.close();
      }
    });
  }

  generateMailRequestModel(channelDateId: number, mapItem: BookingResponseModel[]): MailRequestModel {
    const user = this.userService.getActiveUser()!;
    // Filter bookings by channelDateId (It required! we pass channelDateId to SendGrid) 
    const chunkedBookings = this.filteredBookings.filter(item => item.channelDate.id === channelDateId);
    // Set Recipient Emails.
    let emails: string[] = [];
    for (const booking of chunkedBookings) {
      if (booking.customer.email) {
        emails = [...emails, booking.customer.email!];
      }
    }
    // Get subject from the email form.
    const subject = this.emailForm.value.subject;
    // Set custom argumants
    const customArgs = new MailCustomArgumentModel(user.companyId, channelDateId, mapItem[0].channelDate.eventDateId);
    return new MailRequestModel(user.companyId, emails, subject, this.emailData, customArgs);
  }

  getBookingsByChannelDate(): Map<number, BookingResponseModel[]> {
    let bookingGroupMap = new Map<number, BookingResponseModel[]>();
    for (const booking of this.bookings) {
      if (!bookingGroupMap.has(booking.channelDate.id)) {
        bookingGroupMap.set(booking.channelDate.id, this.bookings.filter(item => item.channelDate.id === booking.channelDate.id));
      }
    }
    return bookingGroupMap;
  }

  onTestEmailBtnClick(testEmailTemplateContent: TemplateRef<{}>) {
    this.testEmailModal = this.modalService.create({
      nzTitle: 'Send Test Email',
      nzOkText: 'Send',
      nzContent: testEmailTemplateContent,
      nzMaskClosable: false,
      nzOnOk: () => new Promise(async resolve => {
        await this.handleTestEmailBtnOk().then(() => {
          resolve();
        }).catch((err) => {
          return;
        });
        this.testEmailModal.updateConfig({
          nzOkLoading: false
        });
      })
    });
  }

  handleTestEmailBtnOk(): Promise<void> {
    return this.sendTestEmail();
  }

  sendTestEmail() {
    return new Promise<void>((resolve, reject) => {
      
      validateFormGroup(this.testEmailForm);
      if (this.testEmailForm.invalid) {
        reject();
        return;
      }

      this.isTestEmailSending = true;
      const testMailRequestModel = this.generateTestMailRequestModel()
      this.mailService.send(testMailRequestModel).pipe(untilDestroyed(this))
        .subscribe(() => {
          this.isTestEmailSending = false;
          this.modalService.success({
            nzTitle: 'Email',
            nzContent: 'Your e-mail has been sent successfully.'
          });
          resolve();
        }, err => {
          console.log('Error while sending Test Email. Error : ', err);
          this.isTestEmailSending = false;
          this.modalService.error({
            nzTitle: 'Email',
            nzContent: 'An error occurred while sending e-mail. Please try again later.'
          });
          reject();
        });
    });
  }

  generateTestMailRequestModel(): MailRequestModel {
    const user = this.userService.getActiveUser()!;
    const emailForm = this.emailForm.value;
    const testEmailForm = this.testEmailForm.value;
    // Set custom argumants
    const customArgs = new MailCustomArgumentModel(user.companyId, this.filteredBookings[0].channelDate.id, this.filteredBookings[0].channelDate.eventDateId);
    return new MailRequestModel(user.companyId, [testEmailForm.email], emailForm.subject, this.emailData, customArgs);
  }

  isFormValid(): boolean {
    const email = this.emailForm.value;
    if (!email.subject || email.subject.trim() === '') {
      return false;
    }

    if (!this.emailData || this.emailData.trim() === '') {
      return false;
    }

    return true;
  }

  onCancelledEmailSwitchChanged(isSentToCancelledBookings: boolean) {
    this.isSentToCancelledBookings = isSentToCancelledBookings;
    if (this.isBulkEmail && !isSentToCancelledBookings) {
      this.filteredBookings = this.bookings.filter(item => item.status !== BookingStatusEnum.CANCELLED && item.status !== BookingStatusEnum.CANCELLED_BY_DINER);
    } else {
      this.filteredBookings = [...this.bookings];
    }

    this.setRecipients();
    this.patchRecipientsToForm();
  }

  setRecipients() {
    this.recipients = [];
    this.filteredBookings.forEach(booking => {
      if (booking.customer) {
        this.recipients = [... this.recipients, new RecipientModel(booking.customer.name ?? '', booking.customer.email!)];
      }
    });
  }

  validateRecipients() {
    const error = MailHelper.validateEmails(this.recipients);
    if (error) {
      this.modalService.confirm({
        nzTitle: 'Important warning',
        nzContent: error.message,
        nzOkDisabled: error.code === 1000,
        nzOkText: 'Email diners anyway',
        nzOnOk: () => {
          this.patchRecipientsToForm();
          this.isDrawerVisible = true;
        }
      });
    } else {
      this.patchRecipientsToForm();
      this.isDrawerVisible = true;
    }
  }

  patchRecipientsToForm() {
    // Set Recipients.
    const filteredRecipients = this.recipients.filter(item => item.email && item.email.trim().length);
    this.missingEmailCount = this.recipients.length - filteredRecipients.length;
    this.recipients = [...filteredRecipients];

    // Generate 'To' text.
    this.to = 'To : ';
    for(let i = 0; i < this.recipients.length; i++) {
      this.to = this.to + `${this.recipients[i].name} <${this.recipients[i].email}>; `;
      if (i === 1) {
        if (this.recipients.length > 2) {
          this.to = this.to + `and ${this.recipients.length - 2} more...`;
        } 
        break;
      }
    }
    // Patch text to form.
    this.emailForm.controls['to']?.patchValue(this.to);
  }
}
