import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { validateFormGroup } from '@shared/utils/form.util';
import { NzMessageService } from 'ng-zorro-antd/message';
import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment-timezone';
import { environment } from '@env/environment';
import { DateTimeUtil } from '@shared/utils/date-time-util';

import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { UserContextUtil } from '@shared/utils/user-context-util';

import { UserService } from '@core/services/user.service';
import { EventDateService } from '@shared/services/event-date.service';
import { PermissionService } from '@core/services/permission.service';
import { ChannelDateService } from '@shared/services/channel-date.service';
import { BookingParserService } from '@shared/services/booking-parser.service';
import { BookingProviderService } from '@shared/services/booking-provider.service';
import { ChannelTicketTypeService } from '@shared/services/channel-ticket-type.service';

import { BookingStatusEnum } from '@shared/enums/booking-status.enum';
import { BookingProviderEnum } from '@shared/enums/booking-provider.enum';

import { BookingResponseModel } from '@shared/models/http/response/booking/booking-response.model';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';
import { BookingProviderResponseModel } from '@shared/models/http/response/booking-provider/booking-provider-response.model';
import { BookingParserCreateRequestModel } from '@shared/models/http/request/booking-parser/booking-parser-create-request.model';
import { BookingParserUpdateRequestModel } from '@shared/models/http/request/booking-parser/booking-parser-update-request.model';
import { ChannelDateResponseModel } from '@shared/models/http/response/channel/date/channel-date-response.model';
import { ChannelDateRequestQueryModel } from '@shared/models/http/request/channel/date/channel-date-request-query.model';
import { ChannelTicketTypeRequestQueryModel } from '@shared/models/http/request/channel/ticket-type/channel-ticket-type-request-query.model';

// @see BookingStatusEnum
export enum StatusEnum {
  CONFIRMED = 'confirmed',
  CANCELLED = 'cancelled',
}

export enum FormActionEnum {
  CREATE,
  UPDATE,
}

export class TicketTypeModel {

  constructor(id: number, name?: string) {
    this.id = id;
    this.name = name;
  }

  id!: number;
  name?: string;
}

@UntilDestroy()
@Component({
  selector: 'app-booking-drawer',
  templateUrl: './booking-drawer.component.html',
})
export class BookingDrawerComponent implements OnDestroy, OnInit {

  @Input() channels: ChannelDateResponseModel[] = [];

  @Output() public onBookingUpdate = new EventEmitter<BookingResponseModel>();
  @Output() public onBookingCreate = new EventEmitter<BookingResponseModel>();
  @Output() public onChannelDateCreate = new EventEmitter<ChannelDateResponseModel>();

  formAction!: FormActionEnum;
  bookingForm!: UntypedFormGroup;
  channelTicketTypeForm!: UntypedFormGroup;

  StatusEnum = StatusEnum;
  BookingProviderEnum = BookingProviderEnum;

  ticketTypes: TicketTypeModel[] = [];
  bookingProviders: BookingProviderResponseModel[] = [];
  
  selectedBooking?: BookingResponseModel;
  selectedEventDate?: EventDateResponseModel;
  selectedChannelDate?: ChannelDateResponseModel;
  selectedBookingProviderId?: number;

  isCreatingBooking = false;
  isUpdatingBooking = false;
  isSalesChannelUser = false;

  nzDrawerTitle!: string;
  nzDrawerzOkText!: string;
  isDrawerVisible = false;

  isLoadingChannelTicketTypes = false;
  isCreatingChannelTicketType = false;
  isChannelTicketTypeCreateModalVisible = false;

  listOfOptions: Array<{ id: number; controlInstance: string }> = [];

  get checkoutOptions(): UntypedFormArray {
    return this.bookingForm.get('checkoutOptions') as UntypedFormArray;
  }

  constructor(
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private message: NzMessageService,
    private eventDateService: EventDateService,
    private permissionService: PermissionService,
    private channelDateService: ChannelDateService,
    private bookingParserService: BookingParserService,
    private bookingProviderService: BookingProviderService,
    private channelTicketTypeService: ChannelTicketTypeService
  ) { }

  async ngOnInit(): Promise<void> {
    this.bookingForm = this.fb.group({
      status: [null, [Validators.required]],
      bookingProvider: [null, [Validators.required]],
      channelTicketType: [null],
      people: [null, [Validators.required]],
      singlePrice: [null],
      totalPaid: [null],
      customer: this.fb.group({
        name: [null, [Validators.required]],
        email: [null, [Validators.email]],
        phone: [null]
      }),
      purchaseReference: [null],
      purchaseDate: [null],
      purchaseTime: [null],
      notes: [null],
      checkoutOptions: this.fb.array([]),
      externalManageBookingUrl: [null]
    });

    this.channelTicketTypeForm = this.fb.group({
      ticketType: [null, [Validators.required]],
    });

    this.bookingProviders = await firstValueFrom(this.bookingProviderService.list().pipe(untilDestroyed(this)));

    this.isSalesChannelUser = await this.permissionService.hasPermission([UserRoleEnum.SalesChannel]);
    if (this.isSalesChannelUser) {
      const userContext = UserContextUtil.getUserContext();
      const salesChannelSlug = userContext!['https://app.booktutti.com/app_metadata']?.SalesChannelSlug;
      this.bookingProviders = this.bookingProviders.filter(item => item.slug === salesChannelSlug); // No need to show other Booking Providers.
    }
  }

  async openCreateDrawer(reference: EventDateResponseModel | ChannelDateResponseModel) {
    if (reference!._className = 'EventDateResponseModel') {
      this.selectedEventDate = reference as EventDateResponseModel;
    } else if (reference!._className = 'ChannelDateResponseModel') {
      this.selectedChannelDate = reference as ChannelDateResponseModel;
      this.selectedEventDate = await firstValueFrom(this.eventDateService.getById(this.selectedChannelDate.eventDateId));
    }

    this.formAction = FormActionEnum.CREATE;
    this.nzDrawerTitle = 'Create Booking';
    this.nzDrawerzOkText = 'Create';
    this.initCreateBookingForm();
    this.isDrawerVisible = true;
  }

  async openUpdateDrawer(booking: BookingResponseModel) {
    this.formAction = FormActionEnum.UPDATE;
    this.nzDrawerTitle = 'Update Booking';
    this.nzDrawerzOkText = 'Update';

    // Load SelectedEventDate & Event Date Channels
    if (booking.channelDate.eventDateId) {
      this.selectedEventDate = await firstValueFrom(this.eventDateService.getById(booking.channelDate.eventDateId));
      const channelDateRequestQueryModel = new ChannelDateRequestQueryModel(booking.channelDate.eventDateId);
      this.channels = await firstValueFrom(this.channelDateService.listWithoutPagination(channelDateRequestQueryModel).pipe(untilDestroyed(this)));
    }

    this.selectedBooking = booking;
    this.initUpdateBookingForm();
    this.isDrawerVisible = true;
  }

  close(): void {
    this.isDrawerVisible = false;
  }

  private async initCreateBookingForm() {
    // Reset Form
    this.bookingForm.reset();
    this.checkoutOptions.controls = [];

    const isSalesChannelUser = await this.permissionService.hasPermission([UserRoleEnum.SalesChannel]);
    const selectedBookingProvider = isSalesChannelUser ? this.bookingProviders?.[0] : this.bookingProviders?.find(item => item.slug === BookingProviderEnum.DirectReservation);
    const defaultFormValues = {
      status: BookingStatusEnum.CONFIRMED,
      bookingProvider: selectedBookingProvider?.id
    };
  
    this.bookingForm.patchValue(defaultFormValues);
  
    if (selectedBookingProvider?.id) {
      this.onBookingProviderChange(selectedBookingProvider?.id);
    }
  }

  private async initUpdateBookingForm() {
    // Reset Form
    this.bookingForm.reset();
    this.checkoutOptions.controls = [];

    if (!this.selectedBooking) {
      return;
    }

    if (this.selectedBooking.bookingOptions) {
      this.selectedBooking.bookingOptions.forEach(() => {
        this.checkoutOptions.push(this.createCheckoutOptionItem());
      });
    }

    if (this.selectedBooking._bookingProvider?.id) {
      this.selectedBookingProviderId = this.selectedBooking._bookingProvider.id;
      this.loadChannelTicketTypes(this.selectedBookingProviderId);
    }

    this.bookingForm.patchValue(this.selectedBooking);
    this.bookingForm.patchValue({
      checkoutOptions: this.selectedBooking.bookingOptions,
      bookingProvider: this.selectedBooking._bookingProvider?.id,
      channelTicketType: this.selectedBooking.channelDate?.channelTicketType?.id,
      purchaseDate: this.selectedBooking.purchaseDate,
      purchaseTime: this.selectedBooking.purchaseDate,
      externalManageBookingUrl: this.selectedBooking.externalManageBookingUrl
    });

    if (!this.selectedBooking.channelDate.eventDateId) {
      this.bookingForm.controls['bookingProvider'].disable();
    } else {
      this.bookingForm.controls['bookingProvider'].enable();
    }
  }

  handleOk() {
    this.selectedBooking ? this.updateBooking() : this.createBooking();
  }

  private async createBooking() {
    validateFormGroup(this.bookingForm);
    if (this.bookingForm.invalid) {
      this.isCreatingBooking = false;
      return;
    }

    this.isCreatingBooking = true;
    const bookingParserCreateRequestModel = await this.generateBookingParserCreateRequestModel();
    if (!bookingParserCreateRequestModel) {
      return;
    }

    this.bookingParserService.create(bookingParserCreateRequestModel).pipe(untilDestroyed(this))
      .subscribe(async (booking: BookingResponseModel) => {
        this.onBookingCreate.emit(booking);
        this.isCreatingBooking = false;
        this.message.create('success', `Booking has been successfully created.`);
        this.close();
      }, err => {
        this.isCreatingBooking = false;
        this.message.create('error', `An error occurred while creating booking.`);
        console.log('Error while creating Booking. Error : ', err);
      });
  }

  private generateBookingParserCreateRequestModel(): BookingParserCreateRequestModel {
    const user = this.userService.getActiveUser()!;
    const bookingForm = this.bookingForm.value;

    // Set Purchase DateTime
    const purchaseDate = bookingForm.purchaseDate ?? moment.tz((new Date()).getTime(), environment.timeZone).toDate();
    const purchaseTime = bookingForm.purchaseTime ?? moment.tz((new Date().setHours(0, 0, 0, 0)), environment.timeZone).toDate();
    const purchaseDateTime = DateTimeUtil.setTimeOption(moment(purchaseDate), purchaseTime).toDate();
    const purchaseDateTimeString = moment(purchaseDateTime).format("YYYY-MM-DD HH:mm:ss")
    
    // Find selected ticket type
    const selectedChannelTicketType = this.ticketTypes.find(item => item.id === bookingForm.channelTicketType);

    const bookingParserCreateRequestModel = new BookingParserCreateRequestModel();
    bookingParserCreateRequestModel.companyId = user.companyId;
    bookingParserCreateRequestModel.salesChannelId = bookingForm.bookingProvider;
    bookingParserCreateRequestModel.eventDateId = this.selectedEventDate!.id;
    bookingParserCreateRequestModel.ticketName = selectedChannelTicketType?.name ?? '';
    bookingParserCreateRequestModel.people = bookingForm.people;
    bookingParserCreateRequestModel.purchaseDate = purchaseDateTimeString;
    bookingParserCreateRequestModel.purchaseReference = bookingForm.purchaseReference ?? '';
    bookingParserCreateRequestModel.customerName = bookingForm.customer.name;
    bookingParserCreateRequestModel.customerEmail = bookingForm.customer.email ?? '';
    bookingParserCreateRequestModel.customerPhone = bookingForm.customer.phone ?? '';
    bookingParserCreateRequestModel.singlePrice = bookingForm.singlePrice;
    bookingParserCreateRequestModel.totalPaid = bookingForm.totalPaid;
    bookingParserCreateRequestModel.notes = bookingForm.notes ?? '';
    bookingParserCreateRequestModel.status = bookingForm.status;
    bookingParserCreateRequestModel.bookingOptions = bookingForm.checkoutOptions ?? [];
    
    if (this.selectedBooking) {
      const eventDate = moment(this.selectedBooking.channelDate.date).format("YYYY-MM-DD HH:mm:ss");
      bookingParserCreateRequestModel.eventDate = eventDate;
      bookingParserCreateRequestModel.eventName = this.selectedBooking.channelDate.channelTicketType.eventName;
      bookingParserCreateRequestModel.venueName = this.selectedBooking.channelDate.channelTicketType?.venueName;
      bookingParserCreateRequestModel.venueCity = this.selectedBooking.channelDate.channelTicketType?.venueCity;
      bookingParserCreateRequestModel.venueAddress = this.selectedBooking.channelDate.channelTicketType?.venueAddress;
    } else if (this.selectedChannelDate) {
      const eventDate = moment(this.selectedChannelDate.date).format("YYYY-MM-DD HH:mm:ss");
      bookingParserCreateRequestModel.eventDate = eventDate;
      bookingParserCreateRequestModel.eventName = this.selectedChannelDate.channelTicketType.eventName;
      bookingParserCreateRequestModel.venueName = this.selectedChannelDate.channelTicketType?.venueName;
      bookingParserCreateRequestModel.venueCity = this.selectedChannelDate.channelTicketType?.venueCity;
      bookingParserCreateRequestModel.venueAddress = this.selectedChannelDate.channelTicketType?.venueAddress;
    } else if (this.selectedEventDate) {
      const eventDate = moment(this.selectedEventDate.start).format("YYYY-MM-DD HH:mm:ss");
      bookingParserCreateRequestModel.eventDate = eventDate;
      bookingParserCreateRequestModel.eventName = this.selectedEventDate.event.name;
      bookingParserCreateRequestModel.venueName = this.selectedEventDate.event.venue?.name;
      bookingParserCreateRequestModel.venueCity = this.selectedEventDate.event.venue?.city;
      bookingParserCreateRequestModel.venueAddress = this.selectedEventDate.event.venue?.address;
    }

    return bookingParserCreateRequestModel
  }

  private async updateBooking() {
    validateFormGroup(this.bookingForm);
    if (this.bookingForm.invalid) {
      this.isUpdatingBooking = false;
      return;
    }

    this.isUpdatingBooking = true;
    const bookingParserUpdateRequestModel = await this.generateBookingParserUpdateRequestModel();
    if (!bookingParserUpdateRequestModel) {
      return;
    }

    this.bookingParserService.update(bookingParserUpdateRequestModel).pipe(untilDestroyed(this))
      .subscribe(async (booking: BookingResponseModel) => {
        this.onBookingUpdate.emit(booking);
        this.isUpdatingBooking = false;
        this.message.create('success', `The booking has been successfully updated.`);
        this.close();
      }, err => {
        this.isUpdatingBooking = false;
        this.message.create('error', `An error occurred while updating the booking.`);
        console.log('Error while updating Booking. Error : ', err);
      });
  }

  private generateBookingParserUpdateRequestModel(): BookingParserUpdateRequestModel {
    if (!this.selectedBooking) {
      throw new Error("No selected booking recognized!");
    }

    const user = this.userService.getActiveUser()!;
    const bookingForm = this.bookingForm.value;

    // Set Purchase DateTime
    const purchaseDate = bookingForm.purchaseDate ?? moment.tz((new Date()).getTime(), environment.timeZone).toDate();
    const purchaseTime = bookingForm.purchaseTime ?? moment.tz((new Date().setHours(0, 0, 0, 0)), environment.timeZone).toDate();
    const purchaseDateTime = DateTimeUtil.setTimeOption(moment(purchaseDate), purchaseTime).toDate();
    const purchaseDateTimeString = moment(purchaseDateTime).format("YYYY-MM-DD HH:mm:ss")

    // Find selected ticket type
    const selectedChannelTicketType = this.ticketTypes.find(item => item.id === bookingForm.channelTicketType);

    const bookingParserUpdateRequestModel = new BookingParserUpdateRequestModel();
    bookingParserUpdateRequestModel.find = {
      companyId : user.companyId,
      bookingId: this.selectedBooking!.id
    }

    // Set Event DateTime
    const eventDate = moment(this.selectedBooking.channelDate.date).format("YYYY-MM-DD HH:mm:ss");

    bookingParserUpdateRequestModel.changes = {
      eventDateId: this.selectedBooking.channelDate.eventDateId,
      eventName: this.selectedBooking.channelDate.channelTicketType.eventName,
      ticketName: selectedChannelTicketType?.name ?? '',
      venueName: this.selectedBooking.channelDate.channelTicketType?.venueName,
      venueCity: this.selectedBooking.channelDate.channelTicketType?.venueCity,
      venueAddress: this.selectedBooking.channelDate.channelTicketType?.venueAddress,
      eventDate: eventDate,
      people: bookingForm.people,
      purchaseDate: purchaseDateTimeString,
      purchaseReference: bookingForm.purchaseReference ?? '',
      customerName: bookingForm.customer.name,
      customerEmail: bookingForm.customer.email ?? '',
      customerPhone: bookingForm.customer.phone ?? '',
      singlePrice: bookingForm.singlePrice,
      totalPaid: bookingForm.totalPaid,
      notes: bookingForm.notes ?? '',
      status: bookingForm.status,
      bookingOptions: bookingForm.checkoutOptions ?? []
    }

    return bookingParserUpdateRequestModel;
  }

  openChannelTicketTypeCreateModal() {
    this.channelTicketTypeForm.reset();
    this.isCreatingChannelTicketType = false;
    this.isChannelTicketTypeCreateModalVisible = true;
  }

  handleChannelTicketTypeCreateModalOk() {
    this.isCreatingChannelTicketType = true;
    validateFormGroup(this.channelTicketTypeForm);
    if (this.channelTicketTypeForm.invalid) {
      this.isCreatingChannelTicketType = false;
      return;
    }

    const channelTicketTypeForm = this.channelTicketTypeForm.value;
    const newTicketType = new TicketTypeModel(this.generateNegativeSixDigitNumber(), channelTicketTypeForm.ticketType);
    this.ticketTypes = [...this.ticketTypes, newTicketType]; // Add and Trigger change detection
    this.bookingForm.controls['channelTicketType'].patchValue(newTicketType.id);
    this.isCreatingChannelTicketType = false;
    this.isChannelTicketTypeCreateModalVisible = false;
  }

  handleChannelTicketTypeCreateModalCancel() {
    this.isChannelTicketTypeCreateModalVisible = false;
  }

  addOptionItem(): void {
    this.checkoutOptions.push(this.createCheckoutOptionItem());
  }

  removeOptionItem(index: number): void {
    if (this.checkoutOptions.length > 0) {
      this.checkoutOptions.removeAt(index);
    }
  }

  private createCheckoutOptionItem(): UntypedFormGroup {
    return this.fb.group({
      option: [null ],
      value: [null, [ Validators.required ] ]
    });
  }

  async onBookingProviderChange(selectedBookingProviderId: number) {
    this.selectedBookingProviderId = selectedBookingProviderId;
    this.loadChannelTicketTypes(selectedBookingProviderId);
    this.bookingForm.controls['channelTicketType'].reset();
  }

  async loadChannelTicketTypes(bookingProviderId: number) {
    this.isLoadingChannelTicketTypes = true;
    const channelTicketTypeRequestQueryModel = this.generateChannelTicketTypeRequestQueryModel(bookingProviderId)
    const channelTicketTypes = await firstValueFrom(this.channelTicketTypeService.listWithoutPagination(channelTicketTypeRequestQueryModel).pipe(untilDestroyed(this)));
    this.ticketTypes = channelTicketTypes
        .filter(item => item.ticketName !== null && item.ticketName !== undefined && item.ticketName.trim() !== '')
        .map(item => new TicketTypeModel(item.id, item.ticketName));
    this.isLoadingChannelTicketTypes = false;
  }

  generateChannelTicketTypeRequestQueryModel(bookingProviderId: number): ChannelTicketTypeRequestQueryModel {
    const user = this.userService.getActiveUser()!;
    const channelTicketTypeRequestModel = new ChannelTicketTypeRequestQueryModel();
    channelTicketTypeRequestModel.companyId = user.companyId;
    channelTicketTypeRequestModel.salesChannelId = bookingProviderId;
    if (this.selectedEventDate) {
      channelTicketTypeRequestModel.eventId = this.selectedEventDate!.event.id;
    }
    return channelTicketTypeRequestModel;
  }

  generateOrderId = (): void => this.bookingForm.get('purchaseReference')!.setValue(uuidv4().split('-')[0]);

  generateNegativeSixDigitNumber(): number {
    const min = -999999;
    const max = -100000;
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  ngOnDestroy(): void {

  }
}
