import { Injectable } from '@angular/core';
import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { NgxPermissionsService } from 'ngx-permissions';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {

  constructor(
    private permissionsService: NgxPermissionsService,
  ) { }

  hasPermission(role: UserRoleEnum | UserRoleEnum[]): Promise<boolean> {
    return this.permissionsService.hasPermission(role);
  }

  setPermissions(permissions: UserRoleEnum[]) {
    this.resetPermissions();
    permissions.forEach(permission => {
      this.permissionsService.addPermission(permission);
    });
  }

  resetPermissions() {
    this.permissionsService.flushPermissions();
  }
}
