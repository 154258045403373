import { Pipe, PipeTransform } from '@angular/core';
import { environment } from '@env/environment';
import * as moment from 'moment-timezone';

/**
 * Apply the javascript string replace
 */
@Pipe({
  name: 'dateWithTimezone',
})
export class DateWithTimezonePipe implements PipeTransform {

  transform(value: Date | undefined, format: string = 'DD/MM/YYYY HH:mm', timezone: string = environment.timeZone): string {
    if (!value) {
      throw Error('Date not provided');
    }


    return moment.tz(value, timezone).format(format);
  }

}
