import { ErrorModel } from '@shared/models/common/core/error.model';
import { RecipientModel } from '@shared/models/common/recipient.model';

export class MailHelper {

    static validateEmails(recipients: RecipientModel[]): ErrorModel | undefined {

        const dinersWithMail = recipients.filter(item => item.email && item.email.trim() !== '');
        const dinersWithoutMail = recipients.filter(item => !item.email || item.email.trim() === '');

        if (dinersWithMail.length === 0) {
            return new ErrorModel(1000, `Customer's email does not exist.`);
        } else if (recipients.length && dinersWithoutMail.length === 1) {
            return new ErrorModel(1001, `${dinersWithoutMail.length} customer does not have an e-mail address.`);
        } else if (recipients.length > 1 && dinersWithoutMail.length > 1) {
            return new ErrorModel(1002, `${dinersWithoutMail.length} customers do not have e-mail addresses.`);
        }

        return;
    }
}