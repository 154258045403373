import { Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';
import { NzDrawerComponent } from 'ng-zorro-antd/drawer';
import * as moment from 'moment';

import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { EventDateService } from '@shared/services/event-date.service';
import { ChannelDateService } from '@shared/services/channel-date.service';

import { DateRangeModel } from '@shared/models/common/date/date-range.model';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';
import { EventDateRequestQueryModel } from '@shared/models/http/request/event-date/event-date-request-query.model';
import { ChannelDateResponseModel } from '@shared/models/http/response/channel/date/channel-date-response.model';
import { ChannelDateAssignToEventDateRequestModel } from '@shared/models/http/request/channel/date/channel-date-assign-to-event-date-request.model';

@UntilDestroy()
@Component({
  selector: 'app-channel-assign-to-event-date-drawer',
  templateUrl: './channel-assign-to-event-date-drawer.component.html',
})
export class ChannelAssignToEventDateDrawerComponent implements OnInit {

  @ViewChild('nzDrawerRef') nzDrawerRef!: NzDrawerComponent;
  @ViewChild('drawerContentRef') drawerContentRef!: ElementRef;
  
  @Output() onChannelsAssigned = new EventEmitter<ChannelDateResponseModel[]>();
  
  Math = Math;
  UserRoleEnum = UserRoleEnum;

  channels: ChannelDateResponseModel[] = [];

  eventDates: EventDateResponseModel[] = [];
  filteredEventDates: EventDateResponseModel[] = [];
  selectedEventDate?: EventDateResponseModel;

  searchTextEvent = '';
  isEventSearchVisible = false;

  isDrawerVisible = false;
  isLoadingEvents = false;

  eventTableScrollStyle = { y: '1000px' };

  constructor(
    private eventDateService: EventDateService,
    private channelDateService: ChannelDateService
  ) { }

  @HostListener('window:resize', [])
  onWindowResize() {
    this.setTableHeight();
  }

  ngOnInit(): void {
    this.listEvents();
  }

  ngAfterViewInit() {
    this.nzDrawerRef.afterOpen.subscribe(() => {
      this.setTableHeight();
    });
  }

  open(channels: ChannelDateResponseModel[]): void {
    this.channels = channels;
    this.selectedEventDate = undefined;
    this.isDrawerVisible = true;
  }

  close(): void {
    this.isDrawerVisible = false;
  }

  listEvents() {
    this.isLoadingEvents = true;
    // Default date range
    const dateRange = new DateRangeModel(moment().startOf('day').subtract(1, 'days').toDate(), moment().add(12, 'month').toDate());
    const eventDateRequestQueryModel = new EventDateRequestQueryModel(dateRange.startDate, dateRange.endDate);

    this.eventDateService.listWithoutPagination(eventDateRequestQueryModel).pipe(untilDestroyed(this))
      .subscribe((eventDates: EventDateResponseModel[]) => {
        this.eventDates = eventDates.sort((a,b) => new Date(a.start).getTime() - new Date(b.start).getTime());
        this.filteredEventDates = [...eventDates];
        this.isLoadingEvents = false;
      }, err => {
        console.log('Error while listing Events. Error : ', err);
      });
  }

  onEventSelected(eventDate: EventDateResponseModel) {
    this.selectedEventDate = eventDate;
  }

  onAddButtonClick() {
    this.assignChannelsToEvent();
  }

  async assignChannelsToEvent() {
    if (!this.selectedEventDate) {
      return;
    }

    // Set selected channels to channels mapping.
    const channelDateIds = [...this.channels.map(item => item.id)];
    // Assign Channels To Event Date
    const channelDateAssignToEventDateRequestModel = new ChannelDateAssignToEventDateRequestModel(this.selectedEventDate!.id);
    for (const channelDateId of channelDateIds) {
      await firstValueFrom(this.channelDateService.assignToEventDate(channelDateId, channelDateAssignToEventDateRequestModel).pipe(untilDestroyed(this)));
    }

    this.onChannelsAssigned.emit(this.channels);
    this.isDrawerVisible = false;
  }

  searchEventDate(): void {
    this.isEventSearchVisible = false;
    this.filteredEventDates = this.eventDates.filter((item: EventDateResponseModel) => item.event.name.indexOf(this.searchTextEvent) !== -1);
  }

  resetEventDateSearch(): void {
    this.searchTextEvent = '';
    this.searchEventDate();
  }

  // EventTable Sorting
  sortEventDateTableByEventNameFn = (a: EventDateResponseModel, b: EventDateResponseModel) => a.event.name.localeCompare(b.event.name);
  sortEventDateTableByEventDateFn = (a: EventDateResponseModel, b: EventDateResponseModel) => new Date(a.start).getTime() - new Date(b.start).getTime();
  sortEventDateTableByEventCityFn = (a: EventDateResponseModel, b: EventDateResponseModel) => {
    const x = a.event.venue?.city || '';
    const y = a.event.venue?.city || ''; 
    return x.localeCompare(y);
  }
  
  setTableHeight() {
    if (this.drawerContentRef && this.drawerContentRef.nativeElement) {
      const drawerHeight = this.drawerContentRef.nativeElement.offsetHeight;
      this.eventTableScrollStyle = { y: `${drawerHeight - 370}px` } ;
    }
  }
}
