export const environment = {
  production: true,
  appVersion: 'v726demo1',
  url: 'https://booktutti.web.app',
  apiUrl: 'https://bookings-api-ot24gjoqjq-nw.a.run.app',
  apiUrlEmail: 'https://email-api-ot24gjoqjq-nw.a.run.app', // we will merge apiUrl and apiUrlEmail soon
  apiVersion: 'v1',
  auth0: {
    domain: "booktutti.eu.auth0.com",
    clientId: "7KpYCxuOcDsihaek89coh1j1dDF1WDa9",
    audience: "https://api.booktutti.com"
  },
  firebase: {
    projectId: 'tutti-crm-london',
    appId: '1:413515739483:web:2b7ec27b3a179dc1acaa7e',
    storageBucket: 'tutti-crm-london.appspot.com',
    apiKey: 'AIzaSyA-NQKu4lu35nM5sF8x84-ZushSXNDUMfE',
    authDomain: 'tutti-crm-london.firebaseapp.com',
    messagingSenderId: '413515739483'
  },
  sendGrid: {
    url: 'https://api.sendgrid.com/v3',
    key: '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',
  },
  mixpanel: {
    token: '17bc8aa0b581627cc3ef9accead2dd1d',
    config: {
      debug: false
    }
  },
  sentry: {
    dsn: 'https://0423755cb252ddf2f60833f70295d0ea@o1333678.ingest.us.sentry.io/4507526425280512',
    replaysSessionSampleRate: 1.0,
  },
  timeZone: 'Europe/London'
};
