import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';
import { NzModalService } from 'ng-zorro-antd/modal';

import { validateFormGroup } from '@shared/utils/form.util';

import { UserService } from '@core/services/user.service';
import { EventService } from '@shared/services/event.service';
import { VenueService } from '@shared/services/venue.service';

import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { EventResponseModel } from '@shared/models/http/response/event/event-response.model';
import { EventUpdateRequestModel } from '@shared/models/http/request/event/event-update-request.model';
import { VenueResponseModel } from '@shared/models/http/response/venue/venue-response.model';
import { VenueCreateRequestModel } from '@shared/models/http/request/venue/venue-create-request.model';

@UntilDestroy()
@Component({
  selector: 'app-event-edit-drawer',
  templateUrl: './event-edit-drawer.component.html',
})
export class EventEditDrawerComponent implements OnDestroy, OnInit {

  @Output() public onEventUpdate = new EventEmitter<EventResponseModel>();

  eventForm!: UntypedFormGroup;
  UserRoleEnum = UserRoleEnum;

  event!: EventResponseModel;
  isParentEvent!: boolean;

  isUpdatingEvent = false;
  isDrawerVisible = false;

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private eventService: EventService,
    private venueService: VenueService,
    private modalService: NzModalService
  ) { }

  ngOnInit(): void {
    this.eventForm = this.fb.group({
      correlationId: [null], 
      name: [null, [Validators.required]],
      venue: [null],
      city: [null],
      capacity: [null]
    });
  }

  open(event: EventResponseModel) {
    this.event = event;
    
    // Set Form
    this.eventForm.reset();
    this.patchToEventForm(event);
    this.isDrawerVisible = true;
  }

  close(): void {
    this.isDrawerVisible = false;
  }

  patchToEventForm(event: EventResponseModel) {
    const capacity = !event.capacity || event.capacity === -1 ? undefined : event.capacity;
    const eventForm: any = {
      name: event.name,
      venue: event.venue?.name,
      city: event.venue?.city,
      capacity: capacity
    };

    this.eventForm.patchValue(eventForm);
  }

  onUpdateClick() {
    this.updateEvent();
  }

  async updateEvent() {
    validateFormGroup(this.eventForm);
    if (this.eventForm.invalid) {
      return;
    }

    this.isUpdatingEvent = true;
    const eventUpdateRequestModel = await this.generateEventUpdateRequestModel();
    this.eventService.update(this.event.id, eventUpdateRequestModel).pipe(untilDestroyed(this))
      .subscribe(async (event) => {
        // We are fetching the updated event details here because we can't directly set the event from the update API response.
        // The API has a bug where the response object doesn’t return the updated venue information.
        // TODO: Once the API bug is fixed, set the event response object directly to this.event.
        this.event = await firstValueFrom(this.eventService.getById(this.event.id).pipe(untilDestroyed(this)));
        this.onEventUpdate.emit(this.event);
        this.isUpdatingEvent = false;
        this.isDrawerVisible = false;
      }, err => {
        this.isUpdatingEvent = false;
        console.log('Error while updating Event. Error : ', err);
      });
  }

  async generateEventUpdateRequestModel(): Promise<EventUpdateRequestModel> {
    const user = this.userService.getActiveUser()!;
    const eventForm = this.eventForm.value;

    let venue: VenueResponseModel = new VenueResponseModel();
    if ((eventForm.venue && eventForm.venue.trim() !== '') || (eventForm.city && eventForm.venue.trim() !== '')) {
      const venueCreateRequestModel = new VenueCreateRequestModel();
      venueCreateRequestModel.companyId = user.companyId;
      venueCreateRequestModel.name = eventForm.venue;
      venueCreateRequestModel.city = eventForm.city;
      venueCreateRequestModel.address = '';
      venueCreateRequestModel.description = '';
      venue = await firstValueFrom(this.venueService.create(venueCreateRequestModel).pipe(untilDestroyed(this)));
    }
    
    const capacity = !eventForm.capacity || String(eventForm.capacity).trim() === '' ? -1 : eventForm.capacity;
    const eventRequestModel: EventUpdateRequestModel = {
      name: eventForm.name,
      summary: eventForm.summary ?? '',
      description: eventForm.description ?? '',
      capacity: capacity,
      companyId: this.event.companyId,
      venueId: venue.id ?? undefined,
    };

    return eventRequestModel;
  }

  onDeleteButtonClick() {
    const modal = this.modalService.confirm({
      nzTitle: 'Important warning',
      nzContent: `
      <p>Are you sure you want to delete the Event?<p/>
      <p>If deleted, all bookings will be added to the bookings channel page and can be re-allocated to an event.</p>`,
      nzOkText: 'Yes, Delete it.',
      nzOkDanger: true,
      nzOnOk: () => new Promise(async resolve => {
        await this.deleteEvent(this.event).then(() => {
          this.router.navigate(['/events/event-cards']);
          resolve();
        }).catch((err: any) => {
          return;
        });
        modal.updateConfig({
          nzOkLoading: false
        });
      })
    });
  }

  deleteEvent(event: EventResponseModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.eventService.delete(event.id).pipe(untilDestroyed(this))
        .subscribe(() => {
          resolve();
        }, err => {
          console.log('Error while deleting Event. Error : ', err);
          reject();
        });
    });
  }

  ngOnDestroy(): void {

  }
}
