import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ellipsis-popover',
  templateUrl: './ellipsis-popover.component.html'
})
export class EllipsisPopoverComponent implements OnInit {

  @Input() content?: string;
  @Input() maxLine = 1;
  
  constructor(

  ) { }

  ngOnInit(): void {

  }
}
