<nz-layout class="layout-default">
    <nz-header class="layout-default__header">
        <app-header (collapsed)="onCollapsed($event)"></app-header>
    </nz-header>
    <nz-layout>
        <nz-sider nzCollapsible [(nzCollapsed)]="isCollapsed" [nzTrigger]="null" 
            nzWidth="240px" nzTheme="dark">
            <app-sidebar [isCollapsed]="isCollapsed"></app-sidebar>
        </nz-sider>
        <nz-layout class="layout-default__inner">
            <nz-content class="layout-default__content">
                <router-outlet></router-outlet>
            </nz-content>
        </nz-layout>
    </nz-layout>
</nz-layout>

<nz-back-top nzVisibilityHeight="200"></nz-back-top>