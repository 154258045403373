import { HttpParams } from '@angular/common/http';
import { UserContextUtil } from './user-context-util';
import { PaginationRequestModel } from '@shared/models/http/request/common/pagination-request.model';

export class PaginationUtil {

  static readonly defaultPageSize = 250;

  static setPaginationParams(paginationRequestModel: PaginationRequestModel | undefined): HttpParams {
    if (!paginationRequestModel) {
      return new HttpParams();
    }

    let params = new HttpParams();

    const user = UserContextUtil.getUserContext();
    if (user) {
      params = params.append('CompanyId', user.companyId);
    }

    if (paginationRequestModel.page !== undefined && paginationRequestModel.page > -1) {
      params = params.append('PageNumber', `${paginationRequestModel.page}`);
    }

    if (paginationRequestModel.size !== undefined && paginationRequestModel.size > -1) {
      params = params.append('PageSize', `${paginationRequestModel.size}`);
    }

    if (paginationRequestModel.sortBy) {
      params = params.append('SortBy', `${paginationRequestModel.sortBy}`);
    }

    if (paginationRequestModel.order) {
      params = params.append('Order', `${paginationRequestModel.order}`);
    }

    if (paginationRequestModel.filters) {
      params = params.append('filters', `${paginationRequestModel.filters}`);
    }

    return params;
  }
}
