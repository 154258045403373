import { Injectable } from '@angular/core';
import { UserResponseModel } from '@shared/models/http/response/user/user-response.model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  activeUser?: UserResponseModel;

  constructor(

  ) { }

  getActiveUser() {
    return this.activeUser;
  }

  setActiveUser(user: UserResponseModel) {
    this.activeUser = user;
  }
}
