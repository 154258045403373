<nz-drawer #nzDrawerRef [nzClosable]="false" [nzVisible]="isDrawerVisible" [nzWidth]="'70%'" nzPlacement="right" nzTitle="Add channels to event"
    [nzFooter]="footerTpl" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div #drawerContentRef class="tnt-widget-drawer__content">
            <div class="tnt-drawer-channel-to-event">
                <div class="tnt-widget-table tnt-drawer-channel-to-event__channel-table">
                    <div class="tnt-widget-table__content">
                        <nz-table #selectedChannelsTable [nzData]="channels" [nzShowPagination]="false"
                            [nzFrontPagination]="false" [nzScroll]="{ y: '190px' }">
                            <thead>
                                <tr>
                                    <th>Event name</th>
                                    <th nzWidth="150px">Event date</th>
                                    <th nzWidth="160px">Booking provider</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let channel of selectedChannelsTable.data" class="tnt-widget-table__content__href">
                                    <td>
                                        <div class="tnt-event-card--sm">
                                            <div class="tnt-event-card--sm__title">
                                                {{ channel.channelTicketType.eventName | titlecase }}
                                            </div>
                                            <div class="tnt-event-card--sm__subtitle">
                                                <span *ngIf="channel?.channelTicketType?.venueName">{{ channel?.channelTicketType?.venueName | titlecase }} <br/></span>
                                                <span>{{ channel.date | dateWithTimezone: 'dddd, MMM D, YYYY [at] HH:mm zz' }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ channel.date | dateWithTimezone }}</td>
                                    <td>
                                        <nz-tag [nzColor]="channel._bookingProvider?._style?.background" [style.color]="channel._bookingProvider?._style?.color">
                                            {{ channel._bookingProvider?.name }}
                                        </nz-tag>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
        
                <nz-divider></nz-divider>
        
                <div class="tnt-widget-table">
                    <div class="tnt-widget-table__content tnt-drawer-channel-to-event__event-table">
                        <h4><b>Select an Event</b></h4>
                        <nz-table #eventDateTable [nzData]="filteredEventDates" [nzLoading]="isLoadingEvents"
                            [nzShowPagination]="false" [nzFrontPagination]="false" [nzScroll]="eventTableScrollStyle">
                            <thead>
                                <tr>
                                    <th nzCustomFilter [nzSortFn]="sortEventDateTableByEventNameFn">
                                        Event
                                        <nz-filter-trigger [(nzVisible)]="isEventSearchVisible" [nzActive]="searchTextEvent.length > 0" [nzDropdownMenu]="searchEventMenu">
                                            <i nz-icon nzType="search"></i>
                                        </nz-filter-trigger>
                                    </th>
                                    <th>
                                        <span *ngxPermissionsOnly="[UserRoleEnum.ReservationManager]; else elseVenueTitle">Restaurant</span>
                                        <ng-template #elseVenueTitle>
                                            <span>Venue</span>
                                        </ng-template>
                                    </th>
                                    <th [nzSortFn]="sortEventDateTableByEventCityFn">
                                        City
                                    </th>
                                    <th [nzSortFn]="sortEventDateTableByEventDateFn" nzWidth="180px">Event date</th>
                                    <th nzAlign="center" nzWidth="50px"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let eventDate of eventDateTable.data" (click)="onEventSelected(eventDate)" class="tnt-widget-table__content__href"
                                    [ngClass]="{'tnt-drawer-channel-to-event__event-table__selected': eventDate.id === selectedEventDate?.id}">
                                    <td>
                                        <div class="tnt-event-card--sm">
                                            <div class="tnt-event-card--sm__title">
                                                {{ eventDate.event.name | titlecase }}
                                            </div>
                                            <div class="tnt-event-card--sm__subtitle">
                                                <span *ngIf="eventDate?.event?.venue?.name ">{{ eventDate?.event?.venue?.name  | titlecase }} <br/></span>
                                                <span>{{ eventDate.start | dateWithTimezone: 'dddd, MMM D, YYYY [at] HH:mm zz' }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ eventDate?.event?.venue?.name }}</td>
                                    <td>{{ eventDate?.event?.venue?.city }}</td>
                                    <td>{{ eventDate?.start | dateWithTimezone }}</td>
                                    <td nzAlign="center" (click)="$event.stopPropagation(); $event.preventDefault();">
                                        <ng-container *ngIf="eventDate?.isAlertOn else alertOffRef">
                                            <i class="fa-solid fa-bell tnt-alert--on"></i>
                                        </ng-container>
                                        <ng-template #alertOffRef>
                                            <i class="fa-solid fa-bell tnt-alert--off"></i>
                                        </ng-template>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                        <nz-dropdown-menu #searchEventMenu="nzDropdownMenu">
                            <div class="ant-table-filter-dropdown">
                                <div class="tnt-widget-table__search-box">
                                    <input type="text" nz-input placeholder="Search event" [(ngModel)]="searchTextEvent" />
                                    <button nz-button nzSize="small" nzType="primary" (click)="searchEventDate()"
                                        class="tnt-widget-table__search-box__search-button">Search</button>
                                    <button nz-button nzSize="small" (click)="resetEventDateSearch()">Reset</button>
                                </div>
                            </div>
                        </nz-dropdown-menu>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #footerTpl>
        <div class="tnt-widget-drawer__footer">
          <button nz-button (click)="close()">Cancel</button>
          <button nz-button nzType="primary" (click)="onAddButtonClick()" [disabled]="!selectedEventDate">Add to Event</button>
        </div>
      </ng-template>
</nz-drawer>
