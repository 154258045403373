import { ObjectUtil } from './object-util';
import { BookingStatusEnum } from '@shared/enums/booking-status.enum';
import { BookingResponseModel } from '@shared/models/http/response/booking/booking-response.model';

export class BookingMergeUtil {

    static generateMergedBookings(bookings: BookingResponseModel[]): BookingResponseModel[] {
        bookings = ObjectUtil.deepCopy(bookings);
        bookings.forEach((booking: BookingResponseModel) => {
            const groupBookings = bookings.filter(item => item.merge?.mergeId && booking.merge?.mergeId && item.merge.mergeId === booking.merge.mergeId);
            // If it doesn't have more then 1 booking, It means it is not a group.
            if (groupBookings.length <= 1) {
                if (booking.merge) {
                    booking.merge.isPrincipal = false;
                }
            } else {
                if (booking.merge?.isPrincipal) {
                    const childeren = bookings.filter(item => item.merge?.mergeId && booking.merge?.mergeId && item.merge.mergeId === booking.merge.mergeId && !item.merge.isPrincipal);
                    if (childeren.length > 0) {
                        bookings = bookings.filter(item => !childeren.find(i => i.id === item.id));

                        // Set main booking as group booking.
                        let clonedReservation = ObjectUtil.deepCopy(booking);
                        booking._isGroupBookingLabel = true;
                        booking._childeren = [clonedReservation];
                        childeren.forEach(element => {
                            booking._childeren = [...booking._childeren!, ObjectUtil.deepCopy(element)];
                        });

                        // Set group booking status.
                        if (booking._childeren.find(item => item.status === BookingStatusEnum.CONFIRMED)) {
                            booking.status = BookingStatusEnum.CONFIRMED;
                        } else if (booking._childeren.find(item => item.status === BookingStatusEnum.PENDING)) {
                            booking.status = BookingStatusEnum.PENDING;
                        } else if (booking._childeren.find(item => item.status === BookingStatusEnum.CANCELLED)) {
                            booking.status = BookingStatusEnum.CANCELLED;
                        }

                        // Calculate total people of group booking.
                        booking.people = 0;
                        booking._childeren.forEach(element => {
                            if (element.status === BookingStatusEnum.CONFIRMED && element.people) {
                                booking.people = booking.people + element.people;
                            }
                        });
                        
                        // Calculate total paid of group booking.
                        booking.totalPaid = 0;
                        booking._childeren.forEach(element => {
                            if (element.status === BookingStatusEnum.CONFIRMED && element.totalPaid) {
                                booking.totalPaid = booking.totalPaid! + element.totalPaid;
                            }
                        });

                        // Sum additional info of group booking.
                        booking.notes = '';
                        booking._childeren.forEach(element => {
                            if (element.status === BookingStatusEnum.CONFIRMED && element.notes) {
                                if (booking.notes) {
                                    booking.notes = booking.notes + '; ' + element.notes.trim();
                                } else {
                                    booking.notes = element.notes.trim();
                                }
                            }
                        });

                        // Sum checkoutOptions info of group booking.
                        booking.bookingOptions = [];
                        booking._childeren.forEach(element => {
                            if (element.status === BookingStatusEnum.CONFIRMED && element.bookingOptions && element.bookingOptions.length) {
                                booking.bookingOptions = [...booking.bookingOptions, ...element.bookingOptions];
                            }
                        });
                    }
                }
            }
        });
        return bookings;
    }
}