export class NavigationHistoryModel {

  constructor(url: string, title?: string) {
    this.url = url;
    this.title = title;
  }

  url!: string;
  title?: string;;
}
