<nz-modal [(nzVisible)]="isEventDateNoteModalVisible" [nzTitle]="eventDateNoteModalRef" [nzOkLoading]="isEventDateUpdating"
    nzOkText="Update" (nzOnCancel)="handleEventDateNoteModalCancel()" (nzOnOk)="handleEventDateNoteModalOk()">
    <ng-container *nzModalContent>
        <div>
            <form nz-form [formGroup]="eventDateNoteForm" nzLayout="vertical">
                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-control>
                                <textarea rows="8" nz-input formControlName="note"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>
</nz-modal>

<ng-template #eventDateNoteModalRef>
    <div nz-row class="tnt-widget-table__header">
        <div nz-col nzSpan="22">
            <i class="fa-solid fa-notes-medical fa-fw"></i> Note
        </div>
        <div nz-col nzSpan="2" class="tnt-widget-table__header__cross">
            <span (click)="isEventDateNoteModalVisible = false">x</span>
        </div>
    </div>
</ng-template>