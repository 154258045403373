import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, Observable } from 'rxjs';
import { environment } from '@env/environment';

import { MixpanelService } from './mixpanel.service';
import { BookingProviderService } from './booking-provider.service';

import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { BookingResponseModel } from '@shared/models/http/response/booking/booking-response.model';
import { BookingParserCreateRequestModel } from '@shared/models/http/request/booking-parser/booking-parser-create-request.model';
import { BookingParserUpdateRequestModel } from '@shared/models/http/request/booking-parser/booking-parser-update-request.model';

@Injectable({
  providedIn: 'root'
})
export class BookingParserService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  constructor(
    private http: HttpClient,
    private mixpanelService: MixpanelService,
    private bookingProviderService: BookingProviderService
  ) { }

  create(bookingParserCreateRequestModel: BookingParserCreateRequestModel): Observable<BookingResponseModel> {
    return this.http.post<ApiDataResponseModel<BookingResponseModel>>(`${this.apiUrl}/parsing/bookings`, bookingParserCreateRequestModel).pipe(
      map((response: ApiDataResponseModel<BookingResponseModel>) => {
        this.bookingProviderService.setBookingsSalesChannels([response.data]);
        return response.data;
      }),
      tap((booking: BookingResponseModel) => {
        this.mixpanelService.track('User created a booking', booking);
      })
    );
  }

  update(bookingParserUpdateRequestModel: BookingParserUpdateRequestModel): Observable<BookingResponseModel> {
    return this.http.patch<ApiDataResponseModel<BookingResponseModel>>(`${this.apiUrl}/parsing/bookings`, bookingParserUpdateRequestModel).pipe(
      map((response: ApiDataResponseModel<BookingResponseModel>) => {
        this.bookingProviderService.setBookingsSalesChannels([response.data]);
        return response.data;
      }),
      tap((booking: BookingResponseModel) => {
        this.mixpanelService.track('User updated a booking', booking);
      })
    );
  }
}
