import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, tap, first } from 'rxjs/operators';
import { environment } from '@env/environment';

import { BookingProviderUtil } from '@shared/utils/booking-provider-util';
import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { BookingResponseModel } from '@shared/models/http/response/booking/booking-response.model';
import { BookingProviderResponseModel } from '@shared/models/http/response/booking-provider/booking-provider-response.model';
import { ChannelDateResponseModel } from '@shared/models/http/response/channel/date/channel-date-response.model';

@Injectable({
  providedIn: 'root'
})
export class BookingProviderService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  private bookingProviders: BookingProviderResponseModel[] = [];

  constructor(
    private http: HttpClient
  ) { }

  list(): Observable<BookingProviderResponseModel[]> {
    if (this.bookingProviders.length) {
      return of(this.bookingProviders);
    }
    return this.http.get<ApiDataResponseModel<BookingProviderResponseModel[]>>(`${this.apiUrl}/sales-channels`).pipe(
      tap((bookingProviders: ApiDataResponseModel<BookingProviderResponseModel[]>) => {
        this.bookingProviders = bookingProviders.data.sort((a, b) => a.name.localeCompare(b.name));
      }),
      map((response: ApiDataResponseModel<BookingProviderResponseModel[]>) => {
        return response.data.sort((a, b) => a.name.localeCompare(b.name));;
      })
    );
  }

  public setBookingsSalesChannels(bookings: BookingResponseModel[]) {
    this.list().pipe(first())
      .subscribe((bookingProviders: BookingProviderResponseModel[]) => {
        bookings.forEach(booking => {
          // Set Booking Providers.
          const bookingProvider = bookingProviders.find(item => item.id === booking!.channelDate!.channelTicketType!.salesChannelId!);
          if (bookingProvider) {
            booking._bookingProvider = bookingProvider;
            // Set Booking Providers Brand colors.
            booking._bookingProvider._style = BookingProviderUtil.getStyle(bookingProvider);
          }
        });
      }, err => {
        console.log('Error while listing BookingProviders. Error : ', err);
      });
  };

  public setChannelsSalesChannels(channels: ChannelDateResponseModel[]) {
    this.list().pipe(first())
      .subscribe((bookingProviders: BookingProviderResponseModel[]) => {
        channels.forEach(channel => {
          // Set Booking Providers.
          const bookingProvider = bookingProviders.find(item => item.id === channel!.channelTicketType!.salesChannelId!);
          if (bookingProvider) {
            channel._bookingProvider = bookingProvider;
            // Set Booking Providers Brand colors.
            channel._bookingProvider._style = BookingProviderUtil.getStyle(bookingProvider);
          }
        });
      }, err => {
        console.log('Error while listing BookingProviders. Error : ', err);
      });
  };
}
