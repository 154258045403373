export enum BookingSortEnum {
  ID = 'Id',
  EVENT_DATE = 'EventDate',
  ORDER_DATE = 'OrderDate'
}

export class BookingRequestQueryModel {

    constructor(eventDateId?: number) {
        this.eventDateId = eventDateId;
    }

    channelDateId?: number;
    eventDateId?: number;
    bookingMergeId?: number;
    bookingStatus?: string;
    purchaseReference?: string;
    purchaseDate?: Date
    customerName?: string;
    customerEmail?: string;

    from?: Date
    to?: Date
}
