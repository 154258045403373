export class EventDateUpdateMultipleRequestModel {

    constructor(eventId: number, dates: DateModel[] = []) {
        this.eventId = eventId;
        this.dates = dates;
    }

    eventId!: number;
    dates!: DateModel[];
}

export class DateModel {
    id!: number;
    start?: Date;
    startTime?: Date;
    end?: Date;
    venueName?: string;
    venueCity?: string;
    capacity?: number;
}
