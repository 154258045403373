export class BookingParserCreateRequestModel {

    companyId!: number;
    salesChannelId!: number;
    eventDateId!: number;
    eventName!: string;
    ticketName?: string;
    venueName?: string;
    venueAddress?: string;
    venueCity?: string;
    eventDate?: string; // Sting as "YYYY-MM-DD HH:mm:ss" format
    people!: number;
    purchaseDate!: string; // Sting as "YYYY-MM-DD HH:mm:ss" format
    purchaseReference!: string; // Required field, but the value can be an empty string
    customerName!: string;
    customerEmail!: string; // Required field, but the value can be an empty string
    customerPhone!: string; // Required field, but the value can be an empty string
    singlePrice?: string;
    totalPaid?: string;
    notes!: string; // Required field, but the value can be an empty string
    source?: string;
    status!: string;
    externalManageBookingUrl?: string;
    bookingOptions: BookingOption[] = [];
}

class BookingOption {
    option!: string;
    value!: string;
}
