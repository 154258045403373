import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import * as mixpanel from 'mixpanel-browser';

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {

  constructor() {

  }

  init(): void {
    mixpanel.init(environment.mixpanel.token, environment.mixpanel.config);
  }

  track(id: string, action: any = {}): void {
    mixpanel.track(id, action);
  }
}