export class BookingMergeAddRequestModel {

    constructor(bookingMergeId: number, bookingId: number) {
        this.bookingMergeId = bookingMergeId;
        this.bookingId = bookingId;
    }

    bookingMergeId!: number;
    bookingId!: number;
}
