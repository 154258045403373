import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export const validateFormGroup = (formGroup: UntypedFormGroup | UntypedFormArray): void => {
    Object.keys(formGroup.controls).forEach(field => {
        const control = formGroup.get(field);

        if (control instanceof UntypedFormControl) {
            control.markAsDirty();
            control.updateValueAndValidity();
            control.markAsTouched({ onlySelf: true });
        } else if (control instanceof UntypedFormGroup) {
            validateFormGroup(control);
        } else if (control instanceof UntypedFormArray) {
            validateFormGroup(control);
        }
    });
};

export const validateFormControl = (formControl: AbstractControl): void => {
    if (formControl instanceof UntypedFormControl) {
        formControl.markAsDirty();
        formControl.updateValueAndValidity();
    }
};
