import { Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { firstValueFrom } from 'rxjs';
import { NzDrawerComponent } from 'ng-zorro-antd/drawer';
import { NzModalService } from 'ng-zorro-antd/modal';

import { ObjectUtil } from '@shared/utils/object-util';
import { UserService } from '@core/services/user.service';
import { BookingMergeService } from '@shared/services/booking-merge.service';

import { BookingStatusEnum } from '@shared/enums/booking-status.enum';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';
import { BookingResponseModel, MergeResponseModel } from '@shared/models/http/response/booking/booking-response.model';
import { BookingMergeCreateRequestModel } from '@shared/models/http/request/booking-merge/booking-merge-create-request.model';
import { BookingMergeAddRequestModel } from '@shared/models/http/request/booking-merge/booking-merge-add-request.model';

export enum FormActionEnum {
  CREATE,
  UPDATE,
}

@UntilDestroy()
@Component({
  selector: 'app-booking-merge-drawer',
  templateUrl: './booking-merge-drawer.component.html',
})
export class BookingMergeDrawerComponent implements OnDestroy, OnInit {

  @ViewChild('nzDrawerRef') nzDrawerRef!: NzDrawerComponent;
  @ViewChild('drawerContentRef') drawerContentRef!: ElementRef;

  @Output() onMergeDone = new EventEmitter<void>();
  
  formAction!: FormActionEnum;
  BookingStatusEnum = BookingStatusEnum;

  eventDate!: EventDateResponseModel
  bookings: BookingResponseModel[] = [];
  mainBooking!: BookingResponseModel;

  isDrawerVisible = false;
  isLoadingBookings = false;
  isMergingBookings = false;

  bookingTableScrollStyle = { x: '1400px', y: '1000px' };

  constructor(
    private userService: UserService,
    private modalService: NzModalService,
    private bookingMergeService: BookingMergeService
  ) { }

  @HostListener('window:resize', [])
  onWindowResize() {
    this.setTableHeight();
  }

  ngOnInit(): void {
    
  }

  ngAfterViewInit() {
    this.nzDrawerRef.afterOpen.subscribe(() => {
      this.setTableHeight();
    });
  }

  open(eventDate: EventDateResponseModel, bookings: BookingResponseModel[]): void {
    this.eventDate = eventDate;
    this.bookings = bookings;

    const mergedBookingIds = this.getExistingMergeIds();

    if (mergedBookingIds.length === 0) {
      this.formAction = FormActionEnum.CREATE;
      this.initCreate()
    } else if (mergedBookingIds.length === 1) {
      this.formAction = FormActionEnum.UPDATE;
      this.initUpdate()
    } else {
      this.modalService.error({
        nzTitle: 'Important warning',
        nzContent: `
        <p>Some reservations are already merged! You can't select more than one merged booking.</p>
        <p>Please remove them from the merged booking list first.</p>`
      });
      return;
    }

    // Open Drawer
    this.isDrawerVisible = true;
  }

  getExistingMergeIds(): number[] {
    const mergedBookings = this.bookings.filter(item => item.merge && item.merge.mergeId > 0);
    // Set Merged Bookings Merge Ids mapping.
    return [...new Set(mergedBookings.map(item => item.merge!.mergeId))];
  }

  close(): void {
    this.isDrawerVisible = false;
  }

  initUpdate() {
    // There must be at least one merged booking to UPDATE merged booking list.
    const mergedBooking = this.bookings.find(item => item.merge && item.merge.mergeId > 0);
    if (!mergedBooking) {
      this.modalService.error({
        nzTitle: 'Important warning',
        nzContent: `There must be at least one merged booking to update it.`
      });
      return;
    }

    const mainBooking = this.bookings.find(item => item.merge?.isPrincipal);
    if (mainBooking) {
      this.mainBooking = ObjectUtil.deepCopy(mainBooking);
      this.calculateTotalPeople();
    }
  }

  initCreate() {
    // Set Main Booking
    if (!this.bookings[0].merge) {
      this.bookings[0].merge = new MergeResponseModel(-1, true);
    } else {
      this.bookings[0].merge.isPrincipal = true;
    }

    // Copy for Label
    this.mainBooking = ObjectUtil.deepCopy(this.bookings[0]);
    this.calculateTotalPeople();
  }

  handleOk() {
    this.mergeBooking();
  }

  mergeBooking() {
    if (this.bookings.length < 2) {
      this.modalService.error({
        nzTitle: 'Important warning',
        nzContent: `There must be more than one booking to be able to merge.`
      });
      return;
    }

    if (this.formAction === FormActionEnum.CREATE) {
      this.createMergedBookings();
    } else if (this.formAction === FormActionEnum.UPDATE) {
      this.updateMergedBookings();
    }
  }

  async updateMergedBookings() {
    this.isMergingBookings = true;

    // Set same MergedId to all Bookings. (There can be unmerged bookings which have not MergedId.) 
    const mergedBooking = this.bookings.find(item => item.merge && item.merge.mergeId > 0);
    if (!mergedBooking) {
      throw new Error('There must be at least one merged booking to update it.');
    }

    const mergedId = mergedBooking.merge!.mergeId!;
    for (const booking of this.bookings) {
      if (!booking.merge || (booking.merge.mergeId < 1)) {
        const bookingMergeAddRequestModel = new BookingMergeAddRequestModel(mergedId, booking.id);
        await firstValueFrom(this.bookingMergeService.add(bookingMergeAddRequestModel).pipe(untilDestroyed(this)));
      }
    }

    this.onMergeDone.emit();
    this.isDrawerVisible = false;
    this.isMergingBookings = false;
  }

  createMergedBookings() {
    this.isMergingBookings = true;
    const user = this.userService.getActiveUser()!;

    // Set Booking Ids mapping.
    const bookingIds = [...this.bookings.map(item => item.id)];

    // Find principle booking.
    const principalBooking = this.bookings.find(item => item.merge?.isPrincipal);
    if (!principalBooking) {
      return;
    }

    const bookingMergeCreateRequestModel = new BookingMergeCreateRequestModel(user.companyId, bookingIds, principalBooking.id);
    this.bookingMergeService.create(bookingMergeCreateRequestModel).pipe(untilDestroyed(this))
      .subscribe(() => {
        this.onMergeDone.emit();
        this.isDrawerVisible = false;
        this.isMergingBookings = false;
      }, err => {
        console.log('Error while merging Bookings. Error : ', err);
        this.isMergingBookings = false;
      });
  }

  async prepareMergeRequest() {
    // Remove bookings already merged!
    // TODO: This logic should be on backed side for transaction rollback.
    const bookingsAlreadyMerged = this.bookings.filter(item => item.merge && item.merge.mergeId > 0);
    for (const booking of bookingsAlreadyMerged) {
      await firstValueFrom(this.bookingMergeService.remove(booking.merge!.mergeId!, booking.id).pipe(untilDestroyed(this)))
    }
  }

  async setAsMain(booking: BookingResponseModel) {

    if (booking.merge) {
      await firstValueFrom(this.bookingMergeService.setPrinciple(booking.merge.mergeId!, booking.id).pipe(untilDestroyed(this)));
      booking.merge.isPrincipal = true;
    } else {
      booking.merge = new MergeResponseModel(-1, true);
    }

    this.bookings.forEach(element => {
      if (booking.id === element.id) {
        return;
      }
      element.merge = element.merge ? {...element.merge, isPrincipal: false } : new MergeResponseModel(-1, false);
    });

    this.mainBooking = ObjectUtil.deepCopy(booking);
    this.calculateTotalPeople();
  }

  calculateTotalPeople() {
    this.mainBooking.people = 0;
    this.bookings.forEach(element => {
      this.mainBooking.people = this.mainBooking.people + element.people;
    });
  }

  onRemoveClick(booking: BookingResponseModel) {
    const modal = this.modalService.confirm({
      nzTitle: 'Important warning',
      nzContent: 'Are you sure you want to remove this booking from the merged booking?',
      nzOkText: 'Yes, Remove it.',
      nzOkDanger: true,
      nzOnOk: () => new Promise(async resolve => {
        if (!booking.merge || !booking.merge.mergeId || booking.merge.mergeId === -1) {
          // It is not really assigned to any merged booking. So remove it without API call.
          this.bookings = this.bookings.filter(item => item.id !== booking.id);
          // If merged booking list has only one booking, delete the merge operation.
          if (this.bookings.length === 1) {
            await this.delete(this.bookings[0].merge!.mergeId!);
          }
          this.calculateTotalPeople();
          this.onMergeDone.emit();
          resolve();
          return;
        }
        await this.remove(booking).then(async () => {
          // If merged booking list has only one booking, delete the merge operation.
          if (this.bookings.length === 1) {
            await this.delete(this.bookings[0].merge!.mergeId!);
          }
          this.calculateTotalPeople();
          this.onMergeDone.emit();
          resolve();
        }).catch((err: any) => {
          return;
        });
        modal.updateConfig({
          nzOkLoading: false
        });
      })
    });
  }

  remove(booking: BookingResponseModel): Promise<void>  {
    return new Promise<void>((resolve, reject) => {
      this.bookingMergeService.remove(booking.merge!.mergeId!, booking.id).pipe(untilDestroyed(this))
        .subscribe(async () => {
          this.bookings = this.bookings.filter(item => item.id !== booking.id);
          resolve();
        }, err => {
          console.log('Error while removing booking. Error : ', err);
          reject();
        });
    });
  }

  async delete(mergeId: number) {
    await firstValueFrom(this.bookingMergeService.delete(mergeId).pipe(untilDestroyed(this)));
  }

  setTableHeight() {
    if (this.drawerContentRef && this.drawerContentRef.nativeElement) {
      const drawerHeight = this.drawerContentRef.nativeElement.offsetHeight;
      this.bookingTableScrollStyle = { x: '1400px', y: `${drawerHeight - 250}px` };
    }
  }

  ngOnDestroy(): void {

  }
}
