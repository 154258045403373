import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnInit, Output, ViewChild } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzDrawerComponent } from 'ng-zorro-antd/drawer';
import { firstValueFrom } from 'rxjs';
import * as moment from 'moment-timezone';

import { UserService } from '@core/services/user.service';
import { BookingService } from '@shared/services/booking.service';
import { EventDateService } from '@shared/services/event-date.service';
import { ChannelDateService } from '@shared/services/channel-date.service';
import { ChannelTicketTypeService } from '@shared/services/channel-ticket-type.service';

import { DateRangeModel } from '@shared/models/common/date/date-range.model';
import { BookingStatusEnum } from '@shared/enums/booking-status.enum';

import { BookingResponseModel } from '@shared/models/http/response/booking/booking-response.model';
import { BookingMoveRequestModel } from '@shared/models/http/request/booking/booking-move-request.model';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';
import { ChannelDateResponseModel } from '@shared/models/http/response/channel/date/channel-date-response.model';
import { ChannelDateRequestQueryModel } from '@shared/models/http/request/channel/date/channel-date-request-query.model';
import { EventDateRequestQueryModel } from '@shared/models/http/request/event-date/event-date-request-query.model';
import { ChannelTicketTypeCreateRequestModel } from '@shared/models/http/request/channel/ticket-type/channel-ticket-type-create-request.model';
import { ChannelDateCreateRequestModel } from '@shared/models/http/request/channel/date/channel-date-create-request.model';
import { ChannelDateAssignToEventDateRequestModel } from '@shared/models/http/request/channel/date/channel-date-assign-to-event-date-request.model';

@UntilDestroy()
@Component({
  selector: 'app-booking-move-drawer',
  templateUrl: './booking-move-drawer.component.html',
})
export class BookingMoveDrawerComponent implements AfterViewInit, OnInit {

  @ViewChild('nzDrawerRef') nzDrawerRef!: NzDrawerComponent;
  @ViewChild('drawerContentRef') drawerContentRef!: ElementRef;

  @Output() onMoveDone = new EventEmitter<void>();

  Math = Math;
  BookingStatusEnum = BookingStatusEnum;

  eventDates: EventDateResponseModel[] = [];
  filteredEventDates: EventDateResponseModel[] = [];

  channelDates: ChannelDateResponseModel[] = [];
  bookings: BookingResponseModel[] = [];

  selectedEventDate?: EventDateResponseModel;
  moveBookingMap = new Map<number, BookingResponseModel[]>();

  isDrawerVisible = false;
  isLoadingEvents = false;
  isMovingBookings = false;

  searchTextEvent = '';
  isEventSearchVisible = false;

  currentStep = 0;

  eventTableScrollStyle = { y: '1000px' };
  bookingTableScrollStyle = { x: '1400px', y: '1000px'};

  constructor(
    private userService: UserService,
    private bookingService: BookingService,
    private eventDateService: EventDateService,
    private channelDateService: ChannelDateService,
    private channelTicketTypeService: ChannelTicketTypeService
  ) { }

  @HostListener('window:resize', [])
  onWindowResize() {
    this.setTableHeight();
  }

  ngOnInit(): void {
    this.listEventDates();
  }

  ngAfterViewInit() {
    this.nzDrawerRef.afterOpen.subscribe(() => {
      this.setTableHeight();
    });
  }

  open(bookings: BookingResponseModel[]): void {
    this.currentStep = 0;
    this.bookings = bookings;
    this.selectedEventDate = undefined;
    this.moveBookingMap.clear();
    
    // Open Drawer
    this.isDrawerVisible = true;
  }

  close(): void {
    this.isDrawerVisible = false;
  }

  next(): void {
    this.currentStep += 1;
  }

  prev(): void {
    this.currentStep -= 1;
  }

  onMoveButtonClick() {
    this.moveBookings();
  }

  async moveBookings() {
    this.isMovingBookings = true;  

    // Set Move Booking Map <ChannelDateId, Booking>
    this.moveBookingMap.clear();

    // Group bookings by the combination of salesChannelId and ticketName
    const uniqueSalesChannelTicketCombinations = [...new Set(this.bookings.map(item => {
      const salesChannelId = item.channelDate?.channelTicketType?.salesChannelId;
      const channelTicketTypeId = item.channelDate?.channelTicketType?.id;
      return `${salesChannelId}_${channelTicketTypeId}`;
    }))];

    for (const combination of uniqueSalesChannelTicketCombinations) {
      const [salesChannelIdString, channelTicketTypeIdString] = combination.split('_');
      // Convert salesChannelId and channelTicketTypeId back to numbers
      const salesChannelId = Number(salesChannelIdString);
      const channelTicketTypeId = Number(channelTicketTypeIdString);

      // Find or create channel related with selected EventDate using same Sales Channel (BookingProvider).
      const channelDateId = await this.findOrCreateChannelDate(salesChannelId, channelTicketTypeId);
      const bookings = this.bookings.filter(item => item.channelDate.channelTicketType.salesChannelId === salesChannelId);
      this.moveBookingMap.set(channelDateId, bookings);
    }

    // Move Bookings
    for (const [key, value] of this.moveBookingMap) {
      const bookingIds = [...value.map(item => item.id)];
      // Generate request models
      const bookingMoveRequestModel = new BookingMoveRequestModel(key, bookingIds);
      await firstValueFrom(this.bookingService.moveToChannelDate(bookingMoveRequestModel).pipe(untilDestroyed(this)))
    }
    this.isMovingBookings = false;
    this.onMoveDone.emit();
    this.close();
  }

  listEventDates() {
    this.isLoadingEvents = true;
    // Default date range
    const dateRange = new DateRangeModel(moment().startOf('day').subtract(1, 'days').toDate(), moment().add(12, 'month').toDate());
    const eventDateRequestQueryModel = new EventDateRequestQueryModel(dateRange.startDate, dateRange.endDate);

    this.eventDateService.listWithoutPagination(eventDateRequestQueryModel).pipe(untilDestroyed(this))
      .subscribe((eventDates: EventDateResponseModel[]) => {
        this.eventDates = eventDates.sort((a,b) => new Date(a.start).getTime() - new Date(b.start).getTime());
        this.filteredEventDates = [...eventDates];
        this.isLoadingEvents = false;
      }, err => {
        console.log('Error while listing Event Dates. Error : ', err);
      });
  }

  async onEventDateSelected(eventDate: EventDateResponseModel) {
    this.selectedEventDate = eventDate;
  }

  async findOrCreateChannelDate(bookingProviderId: number, channelTicketTypeId: number): Promise<number> {
    const channelDateRequestQueryModel = new ChannelDateRequestQueryModel(this.selectedEventDate!.id);
    const channelDates = await firstValueFrom(this.channelDateService.listWithoutPagination(channelDateRequestQueryModel).pipe(untilDestroyed(this)));

    let channelDate = channelDates.find(item => item.channelTicketType.salesChannelId === bookingProviderId && item.channelTicketType.id === channelTicketTypeId);
    if (channelDate) {
      return channelDate.id;
    }

    const channelTicketTypeCreateRequestModel = this.generateChannelTicketTypeCreateRequestModel(bookingProviderId);
    channelDate = await firstValueFrom(this.channelDateService.create(new ChannelDateCreateRequestModel(channelTicketTypeId, channelTicketTypeCreateRequestModel.date)).pipe(untilDestroyed(this)));
    console.log(`::: Channel Date Created ::: ${ channelDate.id }`);

    // If Event Date exist, assign Channel to the Event Date.
    if (this.selectedEventDate) {
      const eventDateId = this.selectedEventDate.id;
      // Assign Channel to the Event Date
      await firstValueFrom(this.channelDateService.assignToEventDate(channelDate.id, new ChannelDateAssignToEventDateRequestModel(eventDateId)).pipe(untilDestroyed(this)));
      channelDate.eventDateId = eventDateId;
      console.log(`::: Channel Date ${ channelDate.id } assigned to ${ eventDateId } ::: `);
    }

    return channelDate.id;
  }

  generateChannelTicketTypeCreateRequestModel(bookingProviderId: number): ChannelTicketTypeCreateRequestModel {
    const user = this.userService.getActiveUser()!;
    if (!this.selectedEventDate) {
      throw new Error('There are no selected event!');
    }

    const channelTicketTypeCreateRequestModel = new ChannelTicketTypeCreateRequestModel();
    channelTicketTypeCreateRequestModel.companyId = user.companyId;
    channelTicketTypeCreateRequestModel.salesChannelId = bookingProviderId;
    channelTicketTypeCreateRequestModel.eventName = this.selectedEventDate.event.name;
    channelTicketTypeCreateRequestModel.date = this.selectedEventDate.start;
    channelTicketTypeCreateRequestModel.venueName = this.selectedEventDate.event.venue?.name;
    channelTicketTypeCreateRequestModel.venueCity = this.selectedEventDate.event.venue?.city;
    channelTicketTypeCreateRequestModel.venueAddress = this.selectedEventDate.event.venue?.address;
    channelTicketTypeCreateRequestModel.price = 0;
    channelTicketTypeCreateRequestModel.ticketName = '';
    channelTicketTypeCreateRequestModel.description = '';

    return channelTicketTypeCreateRequestModel;
  }
  
  searchEvent(): void {
    this.isEventSearchVisible = false;
    this.filteredEventDates = this.eventDates.filter((item: EventDateResponseModel) => item.event.name.indexOf(this.searchTextEvent) !== -1);
  }

  resetEventSearch(): void {
    this.searchTextEvent = '';
    this.searchEvent();
  }

  // EventTable Sorting
  sortEventDateTableByEventNameFn = (a: EventDateResponseModel, b: EventDateResponseModel) => a.event.name.localeCompare(b.event.name);
  sortEventDateTableByEventDateFn = (a: EventDateResponseModel, b: EventDateResponseModel) => new Date(a.start).getTime() - new Date(b.start).getTime();

  setTableHeight() {
    if (this.drawerContentRef && this.drawerContentRef.nativeElement) {
      const drawerHeight = this.drawerContentRef.nativeElement.offsetHeight;
      this.eventTableScrollStyle = { y: `${drawerHeight - 250}px` } ;
      this.bookingTableScrollStyle = { x: '1400px', y: `${drawerHeight - 150}px` };
    }
  }
}
