import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from '@env/environment';
import { MailRequestModel } from '@shared/models/http/request/mail/mail-request.model';

@Injectable({
  providedIn: 'root'
})
export class MailService {

  private apiUrl = `${environment.apiUrlEmail}/v1`;

  constructor(
    private http: HttpClient
  ) { }

  send(email: MailRequestModel): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/mail/send`, email);
  }
}
