export class ChannelTicketTypeCreateRequestModel {

    companyId!: number;
    salesChannelId!: number;
    eventName!: string;
    ticketName!: string;
    description!: string;
    venueName?: string;
    venueAddress?: string;
    venueCity?: string;
    price!: number;
    date!: Date;
}
