import * as moment from 'moment';
import { environment } from '@env/environment';
import { Column } from '@shared/models/common/csv/column';
import { BookingResponseModel } from '@shared/models/http/response/booking/booking-response.model';

export const columns: Column[] = [
  { id: 'VENUE_ID', name: 'Venue ID', mapper: 'channelDate.channelTicketType.venueName' },
  { id: 'VENUE', name: 'Venue', mapper: 'channelDate.channelTicketType.venueName' },
  { id: 'STATUS', name: 'Status', mapper: (row: BookingResponseModel) => row.status?.toString().replace(/_/i, ' ') },
  { id: 'EVENT_DATE', name: 'Event date', mapper: (row: BookingResponseModel) => row?.channelDate?.date ? moment(new Date(row.channelDate.date), environment.timeZone).format('DD/MM/YYYY HH:mm') : '' },
  { id: 'PEOPLE', name: 'People', mapper: 'people' },
  { id: 'PRICE_PER_PERSON', name: 'Price per person', mapper: 'pricePerPerson' },
  { id: 'PRODUCT', name: 'Product', mapper: (row: BookingResponseModel) => row.channelDate?.channelTicketType?.eventName.replace(/&amp;/g, '&') },
  { id: 'TICKET_TYPE', name: 'Ticket Type', mapper: (row: BookingResponseModel) => row.channelDate?.channelTicketType?.ticketName?.replace(/&amp;/g, '&') },
  { id: 'ORDER_ID', name: 'Order id', mapper: 'purchaseReference' },
  { id: 'ORDER_DATE', name: 'Order date', mapper: (row: BookingResponseModel) => row.purchaseDate ? moment(new Date(row.purchaseDate), environment.timeZone).format('DD/MM/YYYY HH:mm') : '' },
  { id: 'TORAL_PAID', name: 'Total paid', mapper: (row: BookingResponseModel) => row.totalPaid ? parseFloat((row.totalPaid).toString()).toFixed(2) : '' },
  { id: 'CUSTOMER_NAME', name: 'Customer name', mapper: 'customer.name' },
  { id: 'CUSTOMER_EMAIL', name: 'Customer email', mapper: 'customer.email' },
  { id: 'CUSTOMER_PHONE', name: 'Customer phone', mapper: 'customer.phone' },
  { id: 'BOOKING_PROVIDER', name: 'Booking Provider', mapper: '_bookingProvider.name' },
  { id: 'ADDITIONAL_INFO', name: 'Additional info', mapper: 'notes' },
  { id: 'CHECKOUT_OPTION', name: 'Checkout options', mapper: (row: BookingResponseModel) => row.bookingOptions ? row.bookingOptions
      .map(item => `${item.option}: ${item.value}`)
      .join('\n') : ''},
];
