<nz-drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" [nzWidth]="720" nzPlacement="right" [nzTitle]="titleTempRef"
    [nzFooter]="footerTpl" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div class="tnt-drawer-event-edit">
            <form nz-form [formGroup]="eventForm" nzLayout="vertical">

                <div class="tnt-drawer-event-edit__title">Event Name</div>
                <nz-divider></nz-divider>

                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <div class="tnt-drawer-event-edit__subtitle">
                            The name of your event.
                        </div>
        
                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input formControlName="name" placeholder="Event Name" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>

                        <div class="tnt-drawer-event-edit__subtitle">
                            Which venue will your event be held at? If you aren’t sure, leave blank.
                        </div>
        
                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input formControlName="venue" placeholder="Venue" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>

                        <div class="tnt-drawer-event-edit__subtitle">
                            Which city will your event be held at? If you aren’t sure, leave blank.
                        </div>     

                        <nz-form-item>
                            <nz-form-control>
                                <input nz-input formControlName="city" placeholder="City" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div class="tnt-drawer-event-edit__title">Capacity & Sold Out Alert</div>
                <nz-divider></nz-divider>

                <div class="tnt-drawer-event-edit__subtitle">
                    What’s your events capacity and set up alerting if want to know when you have nearly sold out.
                </div>

                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-control>
                                <nz-input-number nzSize="large" formControlName="capacity" [nzStep]="1" nzPlaceHolder="Number of tickets"></nz-input-number>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </div>
        
    </ng-container>

    <ng-template #footerTpl>
        <div class="tnt-widget-drawer__footer">
          <button nz-button (click)="close()">Cancel</button>
          <!--
          <ng-container *ngxPermissionsOnly="[UserRoleEnum.Administrator, UserRoleEnum.EventsManager]">
            <button nz-button nzType="primary" (click)="onDeleteButtonClick()" nzDanger>Delete</button>
          </ng-container>
          -->
          <button nz-button nzType="primary" [nzLoading]="isUpdatingEvent" (click)="onUpdateClick()">Update</button>
        </div>
      </ng-template>
</nz-drawer>

<ng-template #titleTempRef>
    <div nz-row class="tnt-widget-table__header">
        <div nz-col nzSpan="22">
            <i class="fas fa-edit"></i> Edit Event
        </div>
        <div nz-col nzSpan="2" class="tnt-widget-table__header__cross">
            <span (click)="close()">x</span>
        </div>
    </div>
</ng-template>


