export class ChannelDateCreateRequestModel {

    constructor(channelTicketTypeId: number, date: Date) {
        this.channelTicketTypeId = channelTicketTypeId;
        this.date = date;
    }

    channelTicketTypeId!: number;
    date!: Date;
}
