import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LayoutDefaultComponent } from './layout-default/layout-default.component';
import { LayoutPassportComponent } from './layout-passport/layout-passport.component';

import { SharedModule } from '@shared/shared.module';
import { HeaderComponent } from './layout-default/header/header.component';
import { SidebarComponent } from './layout-default/sidebar/sidebar.component';

@NgModule({
  declarations: [
    LayoutDefaultComponent,
    LayoutPassportComponent,
    HeaderComponent,
    SidebarComponent
  ],
  imports: [
    CommonModule,
    SharedModule
  ]
})
export class LayoutModule { }
