<div class="layout-default__header">
    <div class="layout-default__header__menu__left">
        <i class="layout-default__header__menu__left__trigger" nz-icon [nzType]="isSidebarCollapsed ? 'menu-unfold' : 'menu-fold'"
            (click)="onSidebarCollapsed()"></i>
    </div>
    
    <div class="layout-default__header__logo" routerLink="/">
        <img src="assets/images/logos/logo-light.png" alt="BookTutti" />
    </div>

    <!--
    <div class="layout-default__header__menu__left">
        <ul nz-menu nzTheme="dark" nzMode="horizontal">
            <ng-container *ngxPermissionsOnly="[UserRoleEnum.Administrator, UserRoleEnum.EventsManager]">
                <li nz-menu-item [routerLink]="['/events']" nzMatchRouter>Events</li>
            </ng-container>
            <ng-container *ngxPermissionsOnly="[UserRoleEnum.ReservationManager]">
                <li nz-menu-item [routerLink]="['/bookings']" nzMatchRouter nzMatchRouterExact>Orders</li>
            </ng-container>
            <ng-container *ngxPermissionsOnly="[UserRoleEnum.Administrator]">
                <li nz-menu-item [routerLink]="['/marketing']" nzMatchRouter nzMatchRouterExact>Marketing</li>
            </ng-container>
        </ul>
    </div>
    -->

    <div class="layout-default__header__menu__right">
        <div nz-row>
            <div nz-col [nzXs]="24" [nzMd]="0">
                <span (click)="openAccountDrawer()">
                    <i class="fa-solid fa-bars"></i>
                </span>
            </div>
            <div nz-col [nzXs]="0" [nzMd]="24">
                <ng-container *ngxPermissionsOnly="[UserRoleEnum.Administrator, UserRoleEnum.SalesChannel]">
                    <nz-select nzShowSearch [(ngModel)]="selectedCompany"
                        (ngModelChange)="onCompanyChange($event)" nzPlaceHolder="Select a company" >
                        <nz-option *ngFor="let company of companies" [nzLabel]="company.name" [nzValue]="company"></nz-option>
                    </nz-select>
        
                    <nz-divider class="layout-default__header__divider" nzType="vertical"></nz-divider>
                </ng-container>
        
                <span class="layout-default__header__icon cursor-pointer" [routerLink]="['/bookings/search']">
                    <i nz-icon nzType="search" nzTheme="outline"></i>
                </span>
        
                <nz-divider class="layout-default__header__divider" nzType="vertical"></nz-divider>
        
                <span class="layout-default__header__icon cursor-pointer" [routerLink]="['/notifications']">
                    <i nz-icon nzType="bell" nzTheme="outline"></i>
                </span>
        
                <nz-divider class="layout-default__header__divider" nzType="vertical"></nz-divider>
        
                <span class="layout-default__header__icon">
                    <i nz-icon nzType="user" nzTheme="outline"></i>
                </span>
        
                <span class="layout-default__header__user" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menu">
                    {{ activeUser?.email }}
                    <i nz-icon nzType="down"></i>
                </span>
                <nz-dropdown-menu #menu="nzDropdownMenu">
                <ul nz-menu>
                    <li nz-menu-item [routerLink]="['/settings']">Settings</li>
                    <li nz-menu-item [routerLink]="['/notifications']">Notifications</li>
                    <li nz-menu-divider></li>
                    <li nz-menu-item (click)="logout()">Logout</li>
                </ul>
                </nz-dropdown-menu>
            </div>
        </div>
    </div>
</div>

<nz-drawer [nzClosable]="false" [nzVisible]="isAccountDrawerVisible" nzPlacement="right" nzTitle="Menu"
    (nzOnClose)="closeAccountDrawer()" [nzWidth]="300">
    <ng-container *nzDrawerContent>
        <ul nz-menu>
            <li nz-menu-item [routerLink]="['/settings']">
                <i nz-icon nzType="mail"></i>
                <span>Settings</span>
            </li>
            <li nz-menu-item [routerLink]="['/notifications']">
                <i nz-icon nzType="mail"></i>
                <span>Notifications</span>
            </li>
            <li nz-menu-divider></li>
            <li nz-menu-item (click)="logout()">
                <i nz-icon nzType="mail"></i>
                <span>Logout</span>
            </li>
        </ul>
    </ng-container>
</nz-drawer>
