import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

import { AuthService } from '@auth0/auth0-angular';
import { StorageService } from './local-storage.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private auth0Service: AuthService,
    private storageService: StorageService,
  ) {
  
  };

  login() {
    this.auth0Service.loginWithRedirect();
  }

  logout() {
    this.storageService.clear();
    this.auth0Service.logout({
      returnTo: environment.url + '/auth/login'
    });
  }
}
