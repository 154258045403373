<nz-layout class="layout-passport">
    <nz-content>
        <div nz-row nzJustify="space-around" nzAlign="middle" class="layout-passport__content">
            <div nz-col nzXs="24" nzMd="12" class="layout-passport__content__left">
              <div class="layout-passport__content__left__header">
                <img src="assets/images/logos/logo-light.png" />
              </div>
          
              <div class="layout-passport__content__left__sub-header">
                Welcome to Tutti <br />Reservation Manager
              </div>
            </div>

            <div nz-col nzXs="20" nzMd="12" class="layout-passport__content__right">
              <router-outlet></router-outlet>
            </div>
        </div>
    </nz-content>
</nz-layout>