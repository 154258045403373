export class BookingMergeCreateRequestModel {

    constructor(companyId: number, bookingIds: number[], principalBookingId: number) {
        this.companyId = companyId;
        this.bookingIds = bookingIds;
        this.principalBookingId = principalBookingId;
    }

    companyId!: number;
    bookingIds!: number[];
    principalBookingId: number;
}
