<nz-drawer #nzDrawerRef [nzClosable]="false" [nzVisible]="isDrawerVisible" [nzWidth]="'70%'" nzPlacement="right" nzTitle="Move Bookings"
    [nzFooter]="footerTpl" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div #drawerContentRef class="tnt-widget-drawer__content">
            <div class="tnt-drawer-move-booking">
                <nz-steps [nzCurrent]="currentStep" nzProgressDot>
                    <nz-step nzTitle="Booking" nzDescription="Booking overview."></nz-step>
                    <nz-step nzTitle="Event" nzDescription="The event you want to move the booking to."></nz-step>
                    <nz-step nzTitle="Summary" nzDescription="Moving summary."></nz-step>
                    <nz-step nzTitle="Result" nzDescription="Moving result."></nz-step>
                </nz-steps>
    
                <nz-divider></nz-divider>
    
                <ng-container *ngIf="currentStep === 0">
                    <div class="tnt-widget-table">
                        <div class="tnt-widget-table__content">
                            <nz-table #bookingTable [nzData]="bookings" [nzShowPagination]="false"
                                [nzShowPagination]="false" [nzFrontPagination]="false" [nzScroll]="bookingTableScrollStyle">
                                <thead>
                                    <tr>
                                        <th nzWidth="130px">Status</th>
                                        <th nzAlign="center" nzWidth="78px">People</th>
                                        <th nzCustomFilter>Customer</th>
                                        <th>Event</th>
                                        <th nzWidth="150px">Event date</th>
                                        <th nzWidth="150px">Booking provider</th>
                                        <th nzWidth="150px">Order ID</th>
                                        <th nzWidth="150px">Order date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let booking of bookingTable.data">
                                        <td>
                                            <container-element [ngSwitch]="booking?.status">
                                                <nz-tag *ngSwitchCase="BookingStatusEnum.PENDING" nzColor="warning">
                                                    {{ booking.status | titlecase }}
                                                </nz-tag>
        
                                                <nz-tag *ngSwitchCase="BookingStatusEnum.CONFIRMED" nzColor="processing">
                                                    {{ booking.status | titlecase }}
                                                </nz-tag>
        
                                                <nz-tag *ngSwitchCase="BookingStatusEnum.CANCELLED" nzColor="error">
                                                    {{ booking.status | titlecase }}
                                                </nz-tag>
        
                                                <nz-tag *ngSwitchCase="BookingStatusEnum.CANCELLED_BY_DINER" nzColor="error">
                                                    {{ booking.status.replace('_', ' ') | titlecase }}
                                                </nz-tag>
                                            </container-element>
                                        </td>
                                        <td nzAlign="center">{{ booking.people }}</td>
                                        <td>
                                            <div class="tnt-event-card--sm">
                                                <div *ngIf="booking.customer?.name" class="tnt-event-card--sm__title">
                                                    {{ booking.customer.name | titlecase }}
                                                </div>
                                                <div class="tnt-event-card--sm__subtitle">
                                                    <span *ngIf="booking.customer?.email">{{ booking.customer.email }} <br/></span>
                                                    <span>{{ booking.customer.phone }}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="tnt-event-card--sm">
                                                <div class="tnt-event-card--sm__title">
                                                    {{ booking.channelDate.channelTicketType.eventName }}
                                                </div>
                                                <div class="tnt-event-card--sm__subtitle">
                                                    <span *ngIf="booking.channelDate.channelTicketType?.venueName">{{  booking.channelDate.channelTicketType.venueName | titlecase }} <br/></span>
                                                    <span>{{ booking?.channelDate?.date | dateWithTimezone: 'dddd, MMM D, YYYY [at] HH:mm zz' }}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{{ booking?.channelDate?.date | dateWithTimezone }}</td>
                                        <td>
                                            <nz-tag [nzColor]="booking._bookingProvider?._style?.background" [style.color]="booking._bookingProvider?._style?.color">
                                                {{ booking._bookingProvider?.name }}
                                            </nz-tag>
                                        </td>
                                        <td>{{ booking.purchaseReference }}</td>
                                        <td>{{ booking.purchaseDate | dateWithTimezone }}</td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </div>
                </ng-container>
      
                <ng-container *ngIf="currentStep === 1">
                    <!-- begin::Event Table-->
                    <div class="tnt-widget-table">
                        <div class="tnt-widget-table__content tnt-drawer-move-booking__event-table">
                            <h4><b>Select an Event</b></h4>
                            <nz-table #eventDateTable [nzData]="filteredEventDates" [nzLoading]="isLoadingEvents"
                                [nzShowPagination]="false" [nzFrontPagination]="false" [nzScroll]="eventTableScrollStyle">
                                <thead>
                                    <tr>
                                        <th nzCustomFilter [nzSortFn]="sortEventDateTableByEventNameFn">
                                            Event
                                            <nz-filter-trigger [(nzVisible)]="isEventSearchVisible" [nzActive]="searchTextEvent.length > 0" [nzDropdownMenu]="searchEventMenu">
                                                <i nz-icon nzType="search"></i>
                                            </nz-filter-trigger>
                                        </th>
                                        <th [nzSortFn]="sortEventDateTableByEventDateFn" nzWidth="180px">Event date</th>
                                        <th nzAlign="center" nzWidth="50px"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let eventDate of eventDateTable.data" (click)="onEventDateSelected(eventDate)" class="tnt-widget-table__content__href"
                                        [ngClass]="{'tnt-drawer-move-booking__event-table__selected': eventDate.id === selectedEventDate?.id}">
                                        <td>
                                            <div class="tnt-event-card--sm">
                                                <div class="tnt-event-card--sm__title">
                                                    {{ eventDate?.event?.name | titlecase }}
                                                </div>
                                                <div class="tnt-event-card--sm__subtitle">
                                                    <span *ngIf="eventDate?.event?.venue?.name">{{ eventDate?.event?.venue?.name | titlecase }} <br/></span>
                                                    <span>{{ eventDate.start | dateWithTimezone: 'dddd, MMM D, YYYY [at] HH:mm zz' }}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>{{ eventDate?.start | dateWithTimezone }}</td>
                                        <td nzAlign="center" (click)="$event.stopPropagation(); $event.preventDefault();">
                                            <ng-container *ngIf="eventDate?.isAlertOn; else alertOffRef">
                                                <i class="fa-solid fa-bell tnt-alert--on"></i>
                                            </ng-container>
                                            <ng-template #alertOffRef>
                                                <i class="fa-solid fa-bell tnt-alert--off"></i>
                                            </ng-template>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                            <nz-dropdown-menu #searchEventMenu="nzDropdownMenu">
                                <div class="ant-table-filter-dropdown">
                                    <div class="tnt-widget-table__search-box">
                                        <input type="text" nz-input placeholder="Search event" [(ngModel)]="searchTextEvent" />
                                        <button nz-button nzSize="small" nzType="primary" (click)="searchEvent()"
                                            class="tnt-widget-table__search-box__search-button">Search</button>
                                        <button nz-button nzSize="small" (click)="resetEventSearch()">Reset</button>
                                    </div>
                                </div>
                            </nz-dropdown-menu>
                        </div>
                    </div>
                     <!-- end::Event Table-->
                </ng-container>
    
                <ng-container *ngIf="currentStep === 2">
    
                    <div class="tnt-drawer-move-booking__summary">
    
                        <div nz-row>
                            <div nz-col nzSpan="10">
                                <div class="tnt-drawer-move-booking__summary__from">
                                    << Bookings in the table will be moved >>
                                </div>
                            </div>
                            <div nz-col nzSpan="4">
                                <div class="tnt-drawer-move-booking__summary__icon">
                                    <i class="fa-solid fa-circle-chevron-right"></i>
                                </div>
                            </div>
                            <div nz-col nzSpan="10">
                                <div class="tnt-pg_event-date-detail__overview">
                                    <div class="tnt-pg_event-date-detail__overview__title">
                                        {{ selectedEventDate?.event?.name | titlecase }}
                                    </div>
                                    <div class="tnt-pg_event-date-detail__overview__subtitle">
                                        <ng-container *ngIf="selectedEventDate?.event?.venue?.name">
                                            <i class="fa-solid fa-location-dot fa-fw"></i> {{ selectedEventDate?.event?.venue?.name | titlecase }} <br/>
                                        </ng-container >
                                        <i class="fa-solid fa-calendar-days fa-fw"></i> {{ selectedEventDate?.start | dateWithTimezone: 'dddd, MMM D, YYYY [at] HH:mm zz' }}
                                    </div>
                                    <div class="tnt-pg_event-date-detail__overview__subtitle">
                                        <ng-container *ngIf="selectedEventDate!.event.capacity && selectedEventDate!.event.capacity > 0; else noCapacityTemplateRef">
                                            <div class="tnt-pg_event-date-detailt__sold-ticket">
                                                <i class="fa-solid fa-clipboard-check fa-fw"></i>
                                                <div class="tnt-pg_event-date-detail__sold-ticket__label">{{ selectedEventDate && selectedEventDate.confirmedTickets && selectedEventDate.confirmedTickets > 0 ? ' ' + selectedEventDate!.confirmedTickets: ' 0' }}/{{ selectedEventDate!.event.capacity }}</div>
                                                <div class="tnt-pg_event-date-detail__sold-ticket__progress">
                                                    <nz-progress [nzPercent]="Math.floor((selectedEventDate!.confirmedTickets * 100) / selectedEventDate!.event.capacity!)" [nzShowInfo]="false"></nz-progress>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-template #noCapacityTemplateRef>
                                            <i class="fa-solid fa-clipboard-check fa-fw"></i> 
                                            {{ selectedEventDate!.confirmedTickets > 0 ? selectedEventDate!.confirmedTickets: '0' }} ticket(s) sold.
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="tnt-widget-table tnt-drawer-move-booking__summary__booking-table">
                            <div class="tnt-widget-table__content">
                                <nz-table #bookingTable [nzData]="bookings" [nzShowPagination]="false"
                                    [nzFrontPagination]="false" [nzScroll]="{ y: '200px' }">
                                    <thead>
                                        <tr>
                                            <th nzWidth="130px">Status</th>
                                            <th nzAlign="center" nzWidth="78px">People</th>
                                            <th>Customer</th>
                                            <th nzWidth="150px">Booking provider</th>
                                            <th nzWidth="250px">Ticket Type</th>
                                            <th nzWidth="150px">Order ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let booking of bookingTable.data">
                                            <td>
                                                <container-element [ngSwitch]="booking?.status">
                                                    <nz-tag *ngSwitchCase="BookingStatusEnum.PENDING" nzColor="warning">
                                                        {{ booking?.status | titlecase }}
                                                    </nz-tag>
            
                                                    <nz-tag *ngSwitchCase="BookingStatusEnum.CONFIRMED" nzColor="processing">
                                                        {{ booking?.status | titlecase }}
                                                    </nz-tag>
            
                                                    <nz-tag *ngSwitchCase="BookingStatusEnum.CANCELLED" nzColor="error">
                                                        {{ booking?.status | titlecase }}
                                                    </nz-tag>
            
                                                    <nz-tag *ngSwitchCase="BookingStatusEnum.CANCELLED_BY_DINER" nzColor="error">
                                                        {{ booking?.status?.replace('_', ' ') | titlecase }}
                                                    </nz-tag>
                                                </container-element>
                                            </td>
                                            <td nzAlign="center">{{ booking.people }}</td>
                                            <td>
                                                <div class="tnt-event-card--sm">
                                                    <div *ngIf="booking?.customer?.name" class="tnt-event-card--sm__title">
                                                        {{ booking?.customer?.name | titlecase }}
                                                    </div>
                                                    <div class="tnt-event-card--sm__subtitle">
                                                        <span *ngIf="booking?.customer?.email">{{ booking?.customer?.email }} <br/></span>
                                                        <span>{{ booking?.customer?.phone }}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <nz-tag [nzColor]="booking._bookingProvider?._style?.background" [style.color]="booking._bookingProvider?._style?.color">
                                                    {{ booking._bookingProvider?.name }}
                                                </nz-tag>
                                            </td>
                                            <td>{{ booking.channelDate.channelTicketType.ticketName }}</td>
                                            <td>{{ booking.purchaseReference }}</td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </div>
                        </div>
                    </div>
                    
                </ng-container>
    
                <ng-container *ngIf="currentStep === 3">
    
                    <div class="tnt-drawer-move-booking__result">
                        <div class="tnt-widget-card__body">
                            <div nz-row nzJustify="space-around" nzAlign="middle">
                                <div nz-col nzFlex="130px">
                                    <div class="tnt-widget-date">
                                        <div class="tnt-widget-date__card">
                                            <i class="fa-solid fa-calendar"></i>
                                            <div class="tnt-widget-date__card__body">
                                                <div class="tnt-widget-date__card__body__day">{{ selectedEventDate?.start | dateWithTimezone: 'D' }}</div>
                                                <div class="tnt-widget-date__card__body__month">{{ selectedEventDate?.start | dateWithTimezone: 'MMM' | uppercase }}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    
                                <div nz-col nzFlex="auto">
                                    <div class="tnt-event-card--lg">
                                        <div class="tnt-event-card--lg__title">
                                            {{ selectedEventDate?.event?.name | titlecase }}
                                        </div>
                                        <div class="tnt-event-card--lg__subtitle">
                                            <ng-container *ngIf="selectedEventDate?.event?.venue?.name">
                                                <i class="fa-solid fa-location-dot fa-fw"></i> {{ selectedEventDate?.event?.venue?.name | titlecase }} <br/>
                                            </ng-container >
                                            <i class="fa-solid fa-calendar-days fa-fw"></i> {{ selectedEventDate?.start | dateWithTimezone: 'dddd, MMM D, YYYY [at] HH:mm zz' }}
                                        </div>
                                        <div class="tnt-event-card--lg__subtitle">
                                            <ng-container *ngIf="selectedEventDate!.event.capacity && selectedEventDate!.event.capacity > 0; else noCapacityTemplateRef">
                                                <div class="tnt-event-card--lg__sold-ticket">
                                                    <i class="fa-solid fa-clipboard-check fa-fw"></i>
                                                    <div class="tnt-event-card--lg__sold-ticket__label">{{ selectedEventDate && selectedEventDate.confirmedTickets && selectedEventDate.confirmedTickets > 0 ? ' ' + selectedEventDate!.confirmedTickets: ' 0' }}/{{ selectedEventDate!.event.capacity }}</div>
                                                    <div class="tnt-event-card--lg__sold-ticket__progress">
                                                        <nz-progress [nzPercent]="Math.floor((selectedEventDate!.confirmedTickets * 100) / selectedEventDate!.event.capacity!)" [nzShowInfo]="false"></nz-progress>
                                                    </div>
                                                </div>
                                            </ng-container>
                                            <ng-template #noCapacityTemplateRef>
                                                <i class="fa-solid fa-clipboard-check fa-fw"></i> 
                                                {{ selectedEventDate!.confirmedTickets > 0 ? selectedEventDate!.confirmedTickets: '0' }} ticket(s) sold.
                                            </ng-template>
                                        </div>
                                    </div>
                                </div>
                                <div nz-col nzFlex="auto">
                                    <div class="tnt-drawer-move-booking__result__icon">
                                        <i nz-icon nzType="check-circle" nzTheme="fill"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
    
                        <div class="tnt-widget-table tnt-drawer-move-booking__result__booking-table">
                            <div class="tnt-widget-table__content">
                                <nz-table #bookingTable [nzData]="bookings" [nzShowPagination]="false"
                                    [nzFrontPagination]="false" [nzScroll]="{ y: '200px' }">
                                    <thead>
                                        <tr>
                                            <th nzWidth="130px">Status</th>
                                            <th nzAlign="center" nzWidth="78px">People</th>
                                            <th>Customer</th>
                                            <th nzWidth="150px">Booking provider</th>
                                            <th nzWidth="250px">Ticket Type</th>
                                            <th nzWidth="150px">Order ID</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let booking of bookingTable.data">
                                            <td>
                                                <container-element [ngSwitch]="booking?.status">
                                                    <nz-tag *ngSwitchCase="BookingStatusEnum.PENDING" nzColor="warning">
                                                        {{ booking?.status | titlecase }}
                                                    </nz-tag>
            
                                                    <nz-tag *ngSwitchCase="BookingStatusEnum.CONFIRMED" nzColor="processing">
                                                        {{ booking?.status | titlecase }}
                                                    </nz-tag>
            
                                                    <nz-tag *ngSwitchCase="BookingStatusEnum.CANCELLED" nzColor="error">
                                                        {{ booking?.status | titlecase }}
                                                    </nz-tag>
            
                                                    <nz-tag *ngSwitchCase="BookingStatusEnum.CANCELLED_BY_DINER" nzColor="error">
                                                        {{ booking?.status?.replace('_', ' ') | titlecase }}
                                                    </nz-tag>
                                                </container-element>
                                            </td>
                                            <td nzAlign="center">{{ booking.people }}</td>
                                            <div class="tnt-event-card--sm">
                                                <div *ngIf="booking?.customer?.name" class="tnt-event-card--sm__title">
                                                    {{ booking?.customer?.name | titlecase }}
                                                </div>
                                                <div class="tnt-event-card--sm__subtitle">
                                                    <span *ngIf="booking?.customer?.email">{{ booking?.customer?.email }} <br/></span>
                                                    <span>{{ booking?.customer?.phone }}</span>
                                                </div>
                                            </div>
                                            <td>
                                                <nz-tag [nzColor]="booking._bookingProvider?._style?.background" [style.color]="booking._bookingProvider?._style?.color">
                                                    {{ booking._bookingProvider?.name }}
                                                </nz-tag>
                                            </td>
                                            <td>{{ booking.channelDate.channelTicketType.ticketName }}</td>
                                            <td>{{ booking.purchaseReference }}</td>
                                        </tr>
                                    </tbody>
                                </nz-table>
                            </div>
                        </div>
                    </div>
                    
                </ng-container>
            </div>
        </div>
    </ng-container>

    <ng-template #footerTpl>
        <div class="tnt-widget-drawer__footer">
          <button *ngIf="currentStep < 3" nz-button (click)="close()">Cancel</button>
          <button *ngIf="currentStep < 3" nz-button nzType="primary" (click)="prev()" [disabled]="currentStep === 0">Prev</button>
          <button *ngIf="currentStep < 2" nz-button nzType="primary" (click)="next()"
            [disabled]="(currentStep === 1 && !selectedEventDate) || (currentStep === 2 && !moveBookingMap.size)">Next</button>
          <button *ngIf="currentStep === 2" nz-button nzType="primary" (click)="onMoveButtonClick()" [nzLoading]="isMovingBookings">Move</button>
          <button *ngIf="currentStep === 3" nz-button nzType="primary" (click)="close()">Done</button>
        </div>
      </ng-template>
</nz-drawer>


