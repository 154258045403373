import { Injectable } from '@angular/core';
import { firstValueFrom, from, first } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService, User } from '@auth0/auth0-angular';

import { UserService } from '@core/services/user.service';
import { CompanyService } from '@shared/services/company.service';
import { MixpanelService } from '@shared/services/mixpanel.service';
import { PermissionService } from '@core/services/permission.service';
import { AuthenticationService } from '@core/services/authentication.service';
import { BookingProviderService } from '@shared/services/booking-provider.service';

import { MetaDataUtil } from '@shared/utils/meta-data-util';
import { UserContextUtil } from '@shared/utils/user-context-util';
import { AuthContextModel } from '@shared/models/security/auth-context.model';
import { AuthenticationUtil } from '@shared/utils/authentication-util';

import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { UserResponseModel } from '@shared/models/http/response/user/user-response.model';
import { CompanyResponseModel } from '@shared/models/http/response/company/company-response.model';

@UntilDestroy()
@Injectable({
  providedIn: 'root'
})
export class SetupService {

  private readonly defaultGroup = 1;

  constructor(
    private userService: UserService,
    private auth0Service: AuthService,
    private companyService: CompanyService,
    private mixpanelService: MixpanelService,
    private permissionService: PermissionService,
    private authenticationService: AuthenticationService,
    private bookingProviderService: BookingProviderService
  ) { }

  initialize(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      // Clear metadata information.
      MetaDataUtil.clear();
      // Get access token and initialize the application.
      // If you do want to use "getAccessTokenSilently" in APP_INITIALIZER, you can work around it by using localstorage (@see AuthModule -> cacheLocation).
      // Even tho that will still involve weird behaviour, it will get rid of the infinite loop.
      from(this.auth0Service.getAccessTokenSilently()).pipe(first()).subscribe(async (accessToken: string) => {
        const user = await firstValueFrom(this.auth0Service.user$);
        if (!user) {
          this.auth0Service.loginWithRedirect();
          return;
        }
        
        // Set AuthContext.
        AuthenticationUtil.setAuthContext(new AuthContextModel(accessToken));
        // Set UserInformation.
        await this.setUserInformation(user);
        // Initialize Mixpanel.
        this.mixpanelService.init();
        // Cache Booking Providers (Sales Channels)
        this.bookingProviderService.list().pipe(first()).subscribe();
        resolve();
      }, err => {
        // In order not to cause an infinite loop, we redirect to the auth0 Login page only in cases
        // where there is an authentication error.
        if (err.error === 'login_required') {
          this.auth0Service.loginWithRedirect();
        }
        if (err.error === 'consent_required') {
          this.auth0Service.loginWithRedirect();
        }
      })
    });
  }

  private async setUserInformation(user: User) {
      // Set User's Permissions
      const roles: UserRoleEnum[] = user['https://app.booktutti.com/roles'] ?? [];
      this.permissionService.setPermissions(roles);

      // Set User's Company
      const company = await this.setUsersCompanyInformation(user);
      if (!company) {
        return;
      }

      // Set User's UserContext
      this.setUserContext(user, company.id, roles);
  }

  private async setUsersCompanyInformation(user: User): Promise<CompanyResponseModel> {
    let companyId = user['https://app.booktutti.com/app_metadata']?.CompanyId;

    // Check if the user has either Admin or SalesChannel roles, as these roles will not have a CompanyID in the token
    const hasPermission = await this.permissionService.hasPermission([UserRoleEnum.Administrator, UserRoleEnum.SalesChannel]);

    if (hasPermission) {
      try {
          const companies = await firstValueFrom(this.companyService.listWithoutPagination().pipe(untilDestroyed(this)));
          // Set the CompanyId from UserContext if available, otherwise use the first company in the list
          const userContext = UserContextUtil.getUserContext();
          companyId = userContext?.companyId || companies[0]?.id;
      } catch (err) {
          console.error('Error while getting company list:', err);
          throw err;
      }
    }

    try {
      // Fetch the company information using the determined CompanyId
      const company = await firstValueFrom(this.companyService.getById(companyId).pipe(untilDestroyed(this)));
      // Set the fetched company as the active company in the service
      this.companyService.setActiveCompany(company);
      return company;
    } catch (err) {
        console.error(`Error while getting Company Information [CompanyId: ${companyId}, User: ${user.email}]`, err);
        this.authenticationService.logout();
        throw err;
    }
  }

  private setUserContext(user: User, companyId: string | number, roles: UserRoleEnum[]) {
    const userResponseModel: UserResponseModel = {...user, email: user.email!, roles: roles, companyId: companyId as number , group: { id: companyId as string }};
    UserContextUtil.setUserContext(userResponseModel);
    this.userService.setActiveUser(userResponseModel);
  }
}