import { Column } from '@shared/models/common/csv/column';
import { columns as templateSample } from 'assets/data/csv-columns-sample';
import { columns as templateDefault } from 'assets/data/csv-columns-default';
import { columns as templateCustom1 } from 'assets/data/csv-columns-template-1';
import { columns as templateCustom2 } from 'assets/data/csv-columns-template-2';

import { CSVParserModel } from '@shared/models/common/csv/csv-parser.model';
import { BookingStatusEnum } from '@shared/enums/booking-status.enum';
import { BookingResponseModel, CheckoutOptionModel } from '@shared/models/http/response/booking/booking-response.model';
import { BookingExportModel, FoodOrderModel } from '@shared/models/common/csv/booking-export.model';

enum TemplateType {
  SAMPLE,
  DEFAULT,
  TEMPLATE_1,
  TEMPLATE_2,
}

export class CSVExportBookingParserUtil {

  private static columnListFromSettings?: string[];

  static setExportSettings(columnList: string[]) {
    // Set column settings. Visibility, Order etc.
    CSVExportBookingParserUtil.columnListFromSettings = columnList;
  }

  static serializeTemplateSample(): CSVParserModel {
    // Get Fields
    const fields = this.getFields(TemplateType.SAMPLE);
    // Clone the same row 5 times
    const rows: string[] = ['1','2','3','4','5'];

    return new CSVParserModel(fields, rows);
  }

  static serializeTemplateDefault(bookings: BookingResponseModel[], csvColumnOrder?: string[]): CSVParserModel {
    // Get Fields
    const fields = this.getFields(TemplateType.DEFAULT);
    // Serialize the Rows
    let rows: Partial<BookingResponseModel>[] = [];
    bookings.forEach(element => {
      rows = [...rows, element];
      if (element._childeren && element._childeren.length) {
        for (let i = 1; i < element._childeren.length; i++) {
          const child = element._childeren[i];
          // Normally, bookings always have customers. But mail parsing can have issue.
          if (child.customer && child.customer.name) {
            rows = [...rows, { customer: child.customer }];
          }
        }
      }
    });

    return new CSVParserModel(fields, rows);
  }

  // "Boat Show Comedy" wanted this template!
  static serializeTemplate0(bookings: BookingResponseModel[], csvColumnOrder?: string[]): CSVParserModel {
    // Get Fields
    const fields = this.getFields(TemplateType.DEFAULT);
    // Serialize the Rows
    let rows: Partial<BookingResponseModel>[] = [];
    bookings.forEach(element => {

      // London Cocktail Club wanted this changes!
      const ticketName = element.channelDate.channelTicketType.ticketName;
      if (!ticketName || ticketName.trim() === '') {
        element.channelDate.channelTicketType.ticketName = element.channelDate.channelTicketType.eventName;
      }

      rows = [...rows, element];
      if (element._childeren && element._childeren.length) {
        for (let i = 1; i < element._childeren.length; i++) {
          const child = element._childeren[i];
          // Normally, bookings always have customers. But mail parsing can have issue.
          if (child.customer && child.customer.name) {
            rows = [...rows, { customer: child.customer }];
          }
        }
      }
    });

    return new CSVParserModel(fields, rows);
  }

  // "Tucked" wanted this template!
  static serializeTemplate1(bookings: BookingResponseModel[]): CSVParserModel {
    // Get Fields
    const fields = this.getFields(TemplateType.TEMPLATE_1);

    // Serialize the Rows
    let clonedBookings: BookingExportModel[] = JSON.parse(JSON.stringify(bookings));
    // Filter & Sort
    clonedBookings = clonedBookings.filter(item => item.status === BookingStatusEnum.CONFIRMED);
    clonedBookings = clonedBookings.sort((a,b) => b.people - a.people);

    clonedBookings.forEach((booking: BookingExportModel) => {
      if (booking.bookingOptions) {
        let foodOrders: { type: string, value: string, total: number }[] = [];
        booking.bookingOptions.forEach((element: CheckoutOptionModel) => {
          const t = element.option?.trim();
          const v =  element.value ? element.value.replace(/&amp;/g, '&').trim() : '';
          let foodOrder = foodOrders.find(item => item?.value?.trim()?.toLowerCase() === v?.trim()?.toLowerCase());
          if (foodOrder) {
            foodOrder.total = foodOrder.total + 1;
          } else {
            foodOrders = [...foodOrders, new FoodOrderModel(t, v, 1)];
          }
        });
        booking.foodOrders = foodOrders;
      }
    });

    let rows: any[] = [{ col1: '' }];
    clonedBookings.forEach((booking: BookingExportModel) => {
      const row: any = {
        col1: booking?.customer?.name,
        col2: booking?.purchaseReference,
        col3: booking?._bookingProvider?.name,
        col4: booking?.channelDate.channelTicketType.eventName,
        col5: booking?.people,
        col6: booking?.singlePrice,
        col7: booking?.totalPaid,
        col8: booking?.notes,
        col9: (() => {
          if (booking.foodOrders && booking.foodOrders.length > 0) {
            return `${booking.foodOrders[0].total} ${booking.foodOrders[0].value}`;
          }
          return;
        })()
      }
      rows = [...rows, row];

      // Latest Main Diner Position
      const mainDinerPosition = rows.length;

      // Set Child Diners
      let childCustomers: { col1: string, col6: string}[] = [];
      if (booking._childeren && booking._childeren.length) {
        for (let i = 1; i < booking._childeren.length; i++) {
          const childBooking = booking._childeren[i];
          if (childBooking.customer && childBooking.customer.name) {
            childCustomers = [...childCustomers, {
              col1: `${childBooking.customer.name}`,
              col6: `${childBooking.singlePrice}`,
            }];
          }
        }
      }

      for (let i = 0; i < childCustomers.length; i++) {
        rows = [...rows, childCustomers[i]];
      }

      // Set Food Orders
      let foodOrders: { col9: string }[] = [];
      if (booking.foodOrders && booking.foodOrders.length > 1) {
        for (let i = 1; i < booking.foodOrders.length; i++) {
          if (booking.foodOrders[i].value && booking.foodOrders[i].value.trim() !== '') {
            foodOrders = [...foodOrders, { col9: `${booking.foodOrders[i].total} ${booking.foodOrders[i].value}` }]
          }
        }
      }

      for (let i = 0; i < foodOrders.length; i++) {
        let position = mainDinerPosition;
        if (rows[position + i] !== undefined) {
          rows[position + i] = {...rows[position + i], ...foodOrders[i] };
        } else {
          rows = [...rows, foodOrders[i]];
        }
      }
      rows = [...rows, { col1: '' }];
    });

    rows = [...rows, ...[{ col3: '' }]];

    let totalFoodOrders: { type: string, value: string, total: number }[] = [];
    clonedBookings.forEach(booking => {
      booking.foodOrders.forEach((element: FoodOrderModel) => {
        const t = element.type;
        const v = element.value;
        let foodOrder = totalFoodOrders.find(item => item?.value?.trim()?.toLowerCase() === v?.trim()?.toLowerCase());
        if (foodOrder) {
          foodOrder.total = foodOrder.total + element.total;
        } else {
          totalFoodOrders = [...totalFoodOrders, new FoodOrderModel(t, v, element.total)];
        }
      });
    });

    rows = [...rows, ...[{
        col8: 'FOOD TOTALS', col9: (() => {
          if (totalFoodOrders.length > 0) {
            return `${totalFoodOrders[0].total} ${totalFoodOrders[0].value}`;
          }
          return;
        })()
      }]
    ];

    if (totalFoodOrders.length > 1) {
      for (let i = 1; i < totalFoodOrders.length; i++) {
        rows = [...rows, { col9: `${totalFoodOrders[i].total} ${totalFoodOrders[i].value}` }];
      }
    }

    let totalGuests = 0;
    clonedBookings.forEach(booking => {
      if (isFinite(Number(booking.people))) {
        totalGuests = totalGuests + Number(booking.people);
      }
    });
    rows = [...rows, ...[{ col5: 'TOTAL GUESTS', col6: totalGuests }]];

    let totalPaid = 0;
    clonedBookings.forEach(booking => {
      if (isFinite(Number(booking.people))) {
        totalPaid = totalPaid + Number(booking.totalPaid);
      }
    });
    rows = [...rows, ...[{ col5: 'TOTAL PAID', col6: totalPaid }]];
    rows = [...rows, ...[{ col9: '' }]];

    let tableGroup: any[] = []
    clonedBookings.forEach(booking => {
      if (isFinite(Number(booking.people))) {
        let item = tableGroup.find(item => item?.[0] === Number(booking.people));
        if (item) {
          tableGroup[tableGroup.indexOf(item)] = [...item, Number(booking.people)];
        } else {
          tableGroup = [...tableGroup, [Number(booking.people)]];
        }
      }
    });

    let sortedTalbleGroup = tableGroup.sort((a, b) => 0 - (a[0] > b[0] ? -1 : 1));
    if (sortedTalbleGroup.length) {
      rows = [...rows, ...[{ col8: '' }]];
      rows = [...rows, ...[{ col8: 'Tables', col9: `${sortedTalbleGroup[0].length} x ${sortedTalbleGroup[0][0]}` }]];

       if (sortedTalbleGroup.length > 1) {
        for (let i = 1; i < sortedTalbleGroup.length; i++) {
          rows = [...rows, { col9: `${sortedTalbleGroup[i].length} x ${sortedTalbleGroup[i][0]}` }];
        }
      }
    }

    return new CSVParserModel(fields, rows);
  }

  // "Fabulous Beyond Productions" wanted this template!
  static serializeTemplate2( bookings: BookingResponseModel[]): CSVParserModel {
    // Get Fields
    const fields = this.getFields(TemplateType.TEMPLATE_2);

    // Serialize the Rows
    let clonedBookings: BookingExportModel[] = JSON.parse(JSON.stringify(bookings));
    // Filter & Sort
    clonedBookings = clonedBookings.filter(item => item.status === BookingStatusEnum.CONFIRMED);
    clonedBookings = clonedBookings.sort((a,b) => b.people - a.people);

    clonedBookings.forEach((booking: BookingExportModel) => {
      booking.foodOrders = [];

      if (booking.bookingOptions) {
        let foodOrders: FoodOrderModel[] = [];
        booking.bookingOptions.forEach((element: CheckoutOptionModel) => {
          const t = element.option?.trim();
          let v =  element.value ? element.value.replace(/&amp;/g, '&').trim() : '';
          let total = 1;

          // Check if the option is multiple
          const found = v.match(/(^\d+\s?x\s)|(\sx\s?\d+$)/g);
          if (found && found.length > 0) {
              const match = found.pop()!;

              // Remove multiplicity from option
              v = v.replace(match, '');

              // Parse the multiplicity and sum to total
              const parsed = match.replace('x', '').trim();
              total += parseInt(parsed) - 1;
          }

          let foodOrder = foodOrders.find(item => item?.value?.trim()?.toLowerCase() === v?.trim()?.toLowerCase());
          if (foodOrder) {
            foodOrder.total = foodOrder.total + total;
          } else {
            foodOrders = [...foodOrders, new FoodOrderModel(t, v, total)];
          }
        });
        booking.foodOrders = foodOrders;
      }
    });

    let rows: any[] = [{ col1: '' }];
    clonedBookings.forEach((booking: BookingExportModel) => {

      // Try to fix number of people
      if (!booking?.people || booking.people <= 0) {
        booking.people = booking.foodOrders?.reduce((sum, fom) => sum + fom.total, 0) ?? 0;
      }

      const row: any = {
        col1: booking?.customer?.name,
        col2: '',
        col3: booking?._bookingProvider?.name,
        col4: booking.people,
        col5: booking?.notes,
        col6: (() => {
          if (booking.foodOrders && booking.foodOrders.length > 0) {
            return `${booking.foodOrders[0].total} ${booking.foodOrders[0].value}`;
          }
          return;
        })()
      }
      rows = [...rows, row];

      // Latest Main Diner Position
      const mainDinerPosition = rows.length;

      // Set Child Diners
      let childDiners: { col1: string }[] = [];
      if (booking._childeren && booking._childeren.length) {
        for (let i = 1; i < booking._childeren.length; i++) {
          const childBooking = booking._childeren[i];
          if (childBooking.customer && childBooking.customer.name) {
            childDiners = [...childDiners, { col1: `${childBooking.customer.name}` }];
          }
        }
      }

      for (let i = 0; i < childDiners.length; i++) {
        rows = [...rows, childDiners[i]];
      }

      // Set Food Orders
      let foodOrders: { col6: string }[] = [];
      if (booking.foodOrders && booking.foodOrders.length > 1) {
        for (let i = 1; i < booking.foodOrders.length; i++) {
          if (booking.foodOrders[i].value && booking.foodOrders[i].value.trim() !== '') {
            foodOrders = [...foodOrders, { col6: `${booking.foodOrders[i].total} ${booking.foodOrders[i].value}` }]
          }
        }
      }

      for (let i = 0; i < foodOrders.length; i++) {
        let position = mainDinerPosition;
        if (rows[position + i] !== undefined) {
          rows[position + i] = {...rows[position + i], ...foodOrders[i] };
        } else {
          rows = [...rows, foodOrders[i]];
        }
      }
      rows = [...rows, { col1: '' }];
    });

    rows = [...rows, ...[{ col3: '' }]];

    let totalFoodOrders: FoodOrderModel[] = [];
    clonedBookings.forEach(booking => {
      booking.foodOrders.forEach((element: FoodOrderModel) => {
        const t = element.type;
        const v = element.value;
        let foodOrder = totalFoodOrders.find(item => item?.value?.trim()?.toLowerCase() === v?.trim()?.toLowerCase());
        if (foodOrder) {
          foodOrder.total = foodOrder.total + element.total;
        } else {
          totalFoodOrders = [...totalFoodOrders, new FoodOrderModel(t, v, element.total)];
        }
      });
    });

    rows = [...rows, ...[{
      col5: 'FOOD TOTALS', col6: (() => {
        if (totalFoodOrders.length > 0) {
          return `${totalFoodOrders[0].total} ${totalFoodOrders[0].value}`;
        }
        return;
      })()
    }]
    ];

    if (totalFoodOrders.length > 1) {
      for (let i = 1; i < totalFoodOrders.length; i++) {
        rows = [...rows, { col6: `${totalFoodOrders[i].total} ${totalFoodOrders[i].value}` }];
      }
    }

    let totalGuests = 0;
    clonedBookings.forEach(booking => {
      if (isFinite(Number(booking.people))) {
        totalGuests = totalGuests + Number(booking.people);
      }
    });
    rows = [...rows, ...[{ col3: 'TOTAL GUESTS', col4: totalGuests }]];
    rows = [...rows, ...[{ col9: '' }]];

    let tableGroup: any[] = []
    clonedBookings.forEach(booking => {
      if (isFinite(Number(booking.people))) {
        let item = tableGroup.find(item => item?.[0] === Number(booking.people));
        if (item) {
          tableGroup[tableGroup.indexOf(item)] = [...item, Number(booking.people)];
        } else {
          tableGroup = [...tableGroup, [Number(booking.people)]];
        }
      }
    });

    let sortedTalbleGroup = tableGroup.sort((a, b) => 0 - (a[0] > b[0] ? -1 : 1));
    if (sortedTalbleGroup.length) {
      rows = [...rows, ...[{ col5: '' }]];
      rows = [...rows, ...[{ col5: 'Tables', col6: `${sortedTalbleGroup[0].length} x ${sortedTalbleGroup[0][0]}` }]];

       if (sortedTalbleGroup.length > 1) {
        for (let i = 1; i < sortedTalbleGroup.length; i++) {
          rows = [...rows, { col6: `${sortedTalbleGroup[i].length} x ${sortedTalbleGroup[i][0]}` }];
        }
      }
    }

    return new CSVParserModel(fields, rows);
  }

  private static getFields(type: TemplateType): { label: string, value: Function | string }[] {

    let columns: Column[] = [];

    switch (type) {
      case TemplateType.SAMPLE: {
        // Filter by export settings
        columns = this.filterBySettings(templateSample);
        break;
      }
      case TemplateType.DEFAULT: {
        // Filter by export settings
        columns = this.filterBySettings(templateDefault);
        break;
      }
      case TemplateType.TEMPLATE_1: {
        // No filtering option for custom templates
        columns = [...templateCustom1];
        break;
      }
      case TemplateType.TEMPLATE_2: {
        // No filtering option for custom templates
        columns = [...templateCustom2];
        break;
      }
      default: {
        columns = this.filterBySettings(templateDefault);
        break;
      }
    }

    // Serialize the fields
    let fields: { label: string, value: Function | string }[] = [];
    for (const column of columns) {
      fields = [...fields, { label: column.name, value: column.mapper }];
    }

    return fields;
  }

  private static filterBySettings(defaultColumns: Column[]): Column[] {
    let columns: Column[] = [];

    if (this.columnListFromSettings) {
      // Set column according to MetaData
      for (const columnName of this.columnListFromSettings) {
        const element = defaultColumns.find(item => item.name === columnName);
        if (element) {
          columns = [...columns, element];
        }
      }
    } else {
      // If the column order info is not exist in Metadata, use as is.
      columns = [...defaultColumns];
    }

    return columns;
  }
}

