import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { from, Observable } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { AuthService } from '@auth0/auth0-angular';

import { environment } from '@env/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  
  constructor(
    private auth0Service: AuthService,
  ) { }

  private static _isSecure(url: string) {
    const urlReg = new RegExp(`^${environment.sendGrid.url}`);
    return urlReg.test(url);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (AuthInterceptor._isSecure(req.url)) {
      return next.handle(req);
    }

    // Get the auth token from the service.
    return from(this.auth0Service.getAccessTokenSilently()).pipe(
      mergeMap(accessToken => {
        if (accessToken) {
          req = req.clone({ setHeaders: { Authorization: `Bearer ${accessToken}` } });
        }
        return next.handle(req);
      })
    )
  }
}
