import { AfterViewInit, Component, ElementRef, EventEmitter, HostListener, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzDrawerComponent } from 'ng-zorro-antd/drawer';

import { BookingDrawerComponent } from '../../booking/booking-drawer/booking-drawer.component';
import { EventDateEditDrawerComponent } from '../event-date-edit-drawer/event-date-edit-drawer.component';
import { EventDateNoteDrawerComponent } from '../event-date-note-drawer/event-date-note-drawer.component';

import { ExportService } from '@shared/services/export.service';
import { BookingService } from '@shared/services/booking.service';
import { EventDateService } from '@shared/services/event-date.service';

import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { BookingResponseModel } from '@shared/models/http/response/booking/booking-response.model';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';
import { BookingRequestQueryModel } from '@shared/models/http/request/booking/booking-request-query.model';

@UntilDestroy()
@Component({
  selector: 'app-event-date-list-drawer',
  templateUrl: './event-date-list-drawer.component.html',
})
export class EventDateListDrawerComponent implements AfterViewInit, OnDestroy, OnInit {

  @ViewChild('nzDrawerRef') nzDrawerRef!: NzDrawerComponent;
  @ViewChild('drawerContentRef') drawerContentRef!: ElementRef;
  @ViewChild('bookingDrawerRef') bookingDrawerRef!: BookingDrawerComponent;
  @ViewChild('eventDateNoteDrawerRef') eventDateNoteDrawerRef!: EventDateNoteDrawerComponent;
  @ViewChild('eventDateEditDrawerRef') eventDateEditDrawerRef!: EventDateEditDrawerComponent;

  @Output() onEventDateUpdated = new EventEmitter<EventDateResponseModel>();
  @Output() onEventDateDeleted = new EventEmitter<EventDateResponseModel>();

  eventDateNoteForm!: UntypedFormGroup;

  Math = Math;
  UserRoleEnum = UserRoleEnum;

  date?: Date;
  eventDates: EventDateResponseModel[] = [];
  selectedEventDate!: EventDateResponseModel;

  isDrawerVisible = false;
  isEventDateNoteModalVisible = false;

  eventTableScrollStyle = { y: '1000px' };

  constructor(
    private router: Router,
    private fb: UntypedFormBuilder,
    private modalService: NzModalService,
    private exportService: ExportService,
    private bookingService: BookingService,
    private eventDateService: EventDateService,
  ) { }

  @HostListener('window:resize', [])
  onWindowResize() {
    this.setTableHeight();
  }

  ngOnInit(): void {
    this.eventDateNoteForm = this.fb.group({
      note: [null]
    });
  }

  ngAfterViewInit() {
    this.nzDrawerRef.afterOpen.subscribe(() => {
      this.setTableHeight();
    });
  }

  open(date: Date, eventDates: EventDateResponseModel[]): void {
    if (!eventDates.length) {
      return;
    }

    this.date = date;
    this.eventDates = eventDates;
    // Open Drawer
    this.isDrawerVisible = true;
  }

  close(): void {
    this.isDrawerVisible = false;
  }

  onEventDateViewButtonClick(eventDate: EventDateResponseModel) {
    this.router.navigate(['/events/event-dates', eventDate.id]);
  }

  onEventDateEditButtonClick(eventDate: EventDateResponseModel) {
    this.eventDateEditDrawerRef.open(eventDate);
  }

  onEventDateUpdate(eventDate: EventDateResponseModel) {
    this.onEventDateUpdated.emit(eventDate);
    this.eventDates = this.eventDates.map((item: any) => {
      return item.id === eventDate.id ? eventDate : item;
    });
  }

  onEventDateExportButtonClick(eventDate: EventDateResponseModel) {
    const bookingRequestQueryModel = new BookingRequestQueryModel(eventDate.id);
    this.bookingService.listWithoutPagination(bookingRequestQueryModel).pipe(untilDestroyed(this))
      .subscribe((bookings: BookingResponseModel[]) => {
        this.exportService.exportBookingsAsCsv(bookings);
      }, err => {
        console.log('Error while listing Bookings. Error : ', err);
      });
  }

  onEventDateDeleteButtonClick(eventDate: EventDateResponseModel) {
    const modal = this.modalService.confirm({
      nzTitle: 'Important warning',
      nzContent: `
      <p>Are you sure you want to delete the Event?<p/>
      <p>If deleted, all bookings will be added to the bookings channel page and can be re-allocated to an event.</p>`,
      nzOkText: 'Yes, Delete it.',
      nzOkDanger: true,
      nzOnOk: () => new Promise(async resolve => {
        await this.deleteEventDate(eventDate).then(() => {
          this.eventDates = this.eventDates.filter(item => item.id !== eventDate.id);
          this.onEventDateDeleted.emit(eventDate);
          resolve();
        }).catch((err: any) => {
          return;
        });
        modal.updateConfig({
          nzOkLoading: false
        });
      })
    });
  }

  deleteEventDate(eventDate: EventDateResponseModel): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.eventDateService.delete(eventDate.id.toString()).pipe(untilDestroyed(this))
        .subscribe(() => {
          this.eventDates = this.eventDates.filter(item => item.id !== eventDate.id);
          resolve();
        }, err => {
          console.log('Error while deleting event date. Error : ', err);
          reject();
        });
    });
  }

  onEventDateNoteButtonClick(eventDate: EventDateResponseModel) {
    this.eventDateNoteDrawerRef.open(eventDate);
  }

  // EventDateTable Sorting
  sortEventDateTableByEventNameFn = (a: EventDateResponseModel, b: EventDateResponseModel) => a.event.name.localeCompare(b.event.name);
  sortEventDateTableByEventDateFn = (a: EventDateResponseModel, b: EventDateResponseModel) => new Date(a.start).getTime() - new Date(b.start).getTime();

  setTableHeight() {
    if (this.drawerContentRef && this.drawerContentRef.nativeElement) {
      const drawerHeight = this.drawerContentRef.nativeElement.offsetHeight;
      this.eventTableScrollStyle = { y: `${drawerHeight - 50}px` } ;
    }
  }

  async onAddBookingButtonClick(eventDate: EventDateResponseModel) {
    this.selectedEventDate = eventDate;
    this.bookingDrawerRef.openCreateDrawer(eventDate);
  }

  async onBookingCreate(booking: BookingResponseModel) {
    // Load the Event Date from the API to recalculate the sold tickets.
    this.eventDateService.getById(this.selectedEventDate.id).pipe(untilDestroyed(this))
      .subscribe((eventDate) => {
        // Find the item in filteredEventDates that matches the selectedEventDate.id.
        const index = this.eventDates.findIndex(item => item.id === this.selectedEventDate.id);
        // If a matching item is found, replace it with the updated eventDate.
        if (index !== -1) {
          this.eventDates[index] = eventDate;
          this.eventDates = [...this.eventDates]; // Trigger change detection
        }
      });
  }

  ngOnDestroy(): void {

  }
}
