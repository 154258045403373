import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { AuthService } from '@auth0/auth0-angular';

import { MetaDataUtil } from '@shared/utils/meta-data-util';
import { UserContextUtil } from '@shared/utils/user-context-util';

import { UserService } from '@core/services/user.service';
import { CompanyService } from '@shared/services/company.service';
import { PermissionService } from '@core/services/permission.service';
import { AuthenticationService } from '@core/services/authentication.service';

import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { CompanyResponseModel } from '@shared/models/http/response/company/company-response.model';

@UntilDestroy()
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  @Output() public collapsed = new EventEmitter<boolean>();
  
  isSidebarCollapsed = true;

  UserRoleEnum = UserRoleEnum;

  activeUser?: any;

  companies: CompanyResponseModel[] = [];
  selectedCompany?: CompanyResponseModel;

  isLoadingCompanies = true;
  isAccountDrawerVisible = false;

  constructor(
    private router: Router,
    public userService: UserService,
    private auth0Service: AuthService,
    private companyService: CompanyService,
    private permissionService: PermissionService,
    private authenticationService: AuthenticationService
  ) { }

  ngOnInit(): void {
    // Subscribe to the Auth0 service's user observable to get the active user and store it
    this.auth0Service.user$.pipe(untilDestroyed(this)).subscribe((user) => {
      this.activeUser = user;
    });

    // Check if the user has either Administrator or SalesChannel permissions
    this.permissionService.hasPermission([UserRoleEnum.Administrator, UserRoleEnum.SalesChannel])
      .then(hasPermission => {
        if (hasPermission) {
          this.listCompanies();
        }
      });
  }

  logout() {
    this.authenticationService.logout();
  }

  listCompanies() {
    this.isLoadingCompanies = true;
    this.companyService.listWithoutPagination().pipe(untilDestroyed(this))
      .subscribe((companies: CompanyResponseModel[]) => {
        // Sort the companies alphabetically by name and store them
        this.companies = companies.sort((a, b) => a.name.localeCompare(b.name));
        if (this.companies.length) {
          this.setSelectedCompany();
        }
        this.isLoadingCompanies = false;
      }, err => {
        console.log('Error while listing Providers. Error : ', err);
      });
  }

  setSelectedCompany() {
    const activeUser = this.userService.getActiveUser();
    if (activeUser && activeUser.companyId) {
      // Find and set the selected company based on the active user's company ID
      this.selectedCompany = this.companies.find(item => item.id === activeUser.companyId);
    }
  }

  onSidebarCollapsed() {
    this.isSidebarCollapsed = !this.isSidebarCollapsed;
    this.collapsed.emit(this.isSidebarCollapsed);
  }

  onCompanyChange(company: CompanyResponseModel) {
    MetaDataUtil.clear();
    // Update User Context
    UserContextUtil.updateUserContext({
      companyId: company.id,
      group: {
        id: company.id.toString()
      }
    });
    const userContext = UserContextUtil.getUserContext();
    if (userContext) {
      this.userService.setActiveUser(userContext);
    }
    // Update Company Context
    this.companyService.updateCompanyContext(company);
    this.router.navigate(['/']);
  }

  openAccountDrawer() {
    this.isAccountDrawerVisible = true;
  }

  closeAccountDrawer(): void {
    this.isAccountDrawerVisible = false;
  }
}
