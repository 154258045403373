import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, expand, map, reduce, tap, Observable, } from 'rxjs';
import { environment } from '@env/environment';

import { PaginationUtil } from '@shared/utils/pagination-util';
import { MixpanelService } from './mixpanel.service';

import { EventResponseModel } from '@shared/models/http/response/event/event-response.model';
import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { PaginationRequestModel } from '@shared/models/http/request/common/pagination-request.model';
import { PaginationResponseModel } from '@shared/models/http/response/common/pagination-response.model';
import { EventCreateRequestModel } from '@shared/models/http/request/event/event-create-request.model';
import { EventUpdateRequestModel } from '@shared/models/http/request/event/event-update-request.model';
import { EventAlertBulkUpdateRequestModel } from '@shared/models/http/request/event/event-alert-bulk-update-request.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  constructor(
    private http: HttpClient,
    private mixpanelService: MixpanelService
  ) { }

  listWithPagination(paginationRequestModel: PaginationRequestModel): Observable<PaginationResponseModel<EventResponseModel>> {
    const params = PaginationUtil.setPaginationParams(paginationRequestModel);
    return this.http.get<PaginationResponseModel<EventResponseModel>>(`${this.apiUrl}/events`, {
      params,
    });
  }

  listWithoutPagination(): Observable<EventResponseModel[]> {
    const paginationRequestModel = new PaginationRequestModel(1, PaginationUtil.defaultPageSize);
    return this.listWithPagination(paginationRequestModel).pipe(
      expand(response => {
        paginationRequestModel.page++;
        if (response && response.totalPages && paginationRequestModel.page <= response.totalPages) {
          return this.listWithPagination(paginationRequestModel);
        }
        return EMPTY;
      }),
      map((obj: any) => obj.data),
      reduce((acc, x: any) => acc.concat(x))
    );
  }
 
  getById(id: number): Observable<EventResponseModel> {
    return this.http.get<ApiDataResponseModel<EventResponseModel>>(`${this.apiUrl}/events/${id}`).pipe(
      map((response: ApiDataResponseModel<EventResponseModel>) => {
        return response.data;
      })
    );
  }

  create(eventCreateRequestModel: EventCreateRequestModel): Observable<EventResponseModel> {
    return this.http.post<ApiDataResponseModel<EventResponseModel>>(`${this.apiUrl}/events`, eventCreateRequestModel).pipe(
      map((response: ApiDataResponseModel<EventResponseModel>) => {
        return response.data;
      }),
      tap((event: EventResponseModel) => {
        this.mixpanelService.track('User created an event', event);
      })
    );
  }

  update(id: number, eventUpdateRequestModel: EventUpdateRequestModel): Observable<EventResponseModel> {
    return this.http.put<ApiDataResponseModel<EventResponseModel>>(`${this.apiUrl}/events/${id}`, eventUpdateRequestModel).pipe(
      map((response: ApiDataResponseModel<EventResponseModel>) => {
        return response.data;
      }),
      tap((event: EventResponseModel) => {
        this.mixpanelService.track('User updated an event', event);
      })
    );
  }

  updateRelatedEventDatesAlert(eventAlertBulkUpdateRequestModel: EventAlertBulkUpdateRequestModel): Observable<void> {
    return this.http.patch<void>(`${this.apiUrl}/bulk/events/dates/alert`, eventAlertBulkUpdateRequestModel);
  }
  
  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/events/${id}`).pipe(
      tap(() => {
        this.mixpanelService.track('User deleted an event', { eventId: id });
      })
    );
  }
}
