import { Injectable } from '@angular/core';
import { parseAsync } from 'json2csv';
import * as FileSaver from 'file-saver';

import { BookingMergeUtil } from '@shared/utils/booking-merge-util';
import { CSVExportBookingParserUtil } from '@shared/utils/csv-export-util';

import { CompanyEnum } from '@shared/enums/company.enum';
import { CompanyService } from './company.service';
import { CompanySettingService } from './company-setting.service';

import { BookingResponseModel } from '@shared/models/http/response/booking/booking-response.model';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';

@Injectable({
  providedIn: 'root'
})
export class ExportService {

  constructor(
    private companyService: CompanyService,
    private companySettingService: CompanySettingService
  ) { }

  async exportBookingsAsCsv(bookings: BookingResponseModel[]): Promise<void> {
    const company = this.companyService.getActiveCompany();
    if (!company) {
      return;
    }

    if (company.name === CompanyEnum.BOAT_SHOW_COMEDY) {
      bookings = BookingMergeUtil.generateMergedBookings(bookings);
      this.exportUsingTemplate0(bookings);
    }  else if (company.name === CompanyEnum.TUCKED) {
      bookings = BookingMergeUtil.generateMergedBookings(bookings);
      this.exportUsingTemplate1(bookings);
    } else if (company.name === CompanyEnum.FBP) {
      bookings = BookingMergeUtil.generateMergedBookings(bookings);
      this.exportUsingTemplate2(bookings);
    } else {
      bookings = BookingMergeUtil.generateMergedBookings(bookings);
      this.exportUsingDefaultTeplate(bookings);
    }
  }

  public exportSampleBookingsAsCsv() {
    this.setExportSettings();
    const serializedData = CSVExportBookingParserUtil.serializeTemplateSample();
    this.exportAsCsvAsync(serializedData.data, serializedData.fields, 'sample_export_fields.csv');
  }

  private exportUsingDefaultTeplate(bookings: BookingResponseModel[]) {
    this.setExportSettings();
    const fileName = this.generateFileName();
    const serializedData = CSVExportBookingParserUtil.serializeTemplateDefault(bookings);
    this.exportAsCsvAsync(serializedData.data, serializedData.fields, fileName);
  }

  private exportUsingTemplate0(bookings: BookingResponseModel[]) {
    this.setExportSettings();
    const fileName = this.generateFileName();
    const serializedData = CSVExportBookingParserUtil.serializeTemplate0(bookings);
    this.exportAsCsvAsync(serializedData.data, serializedData.fields, fileName);
  }

  private exportUsingTemplate1(bookings: BookingResponseModel[]) {
    const fileName = this.generateFileName();
    const serializedData = CSVExportBookingParserUtil.serializeTemplate1(bookings);
    this.exportAsCsvAsync(serializedData.data, serializedData.fields, fileName);
  }

  private exportUsingTemplate2(bookings: BookingResponseModel[]) {
    const fileName = this.generateFileName();
    const serializedData = CSVExportBookingParserUtil.serializeTemplate2(bookings);
    this.exportAsCsvAsync(serializedData.data, serializedData.fields, fileName);
  }

  private generateFileName(): string {
    return 'bookings_export.csv';
  }

  private exportAsCsvAsync(data: any[], fields?: any, fileName?: string): Promise<void> {
    const opts = { fields };
    return parseAsync(data, opts).then((csv: any) => {
        const blob = new Blob([csv], { type: 'text/plain;charset=utf-8' });
        FileSaver.saveAs(blob, fileName ?? 'data.csv');
      })
      .catch((err: any) => console.error(err));
  }

  private setExportSettings() {
    const settings = this.companySettingService.getFromCache();
    if (settings.csvColumnOrder) {
      CSVExportBookingParserUtil.setExportSettings(settings.csvColumnOrder);
    }
  }
}
