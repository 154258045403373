import * as moment from 'moment';
import { environment } from '@env/environment';
import { Column } from '@shared/models/common/csv/column';

export const columns: Column[] = [
  { id: 'VENUE_ID', name: 'Venue ID', mapper: () => 'Salsa London' },
  { id: 'VENUE', name: 'Venue', mapper: () => 'Salsa London' },
  { id: 'STATUS', name: 'Status', mapper: () => 'confirmed' },
  { id: 'EVENT_DATE', name: 'Event date', mapper: () => moment(new Date(), environment.timeZone).format('DD/MM/YYYY HH:mm') },
  { id: 'PEOPLE', name: 'People', mapper: () => '4' },
  { id: 'PRICE_PER_PERSON', name: 'Price per person', mapper: () => '10' },
  { id: 'PRODUCT', name: 'Product', mapper: () => 'My Friday Event' },
  { id: 'TICKET_TYPE', name: 'Ticket Type', mapper: () => 'Lorem Ipsum' },
  { id: 'ORDER_ID', name: 'Order id', mapper: () => 'XX00000' },
  { id: 'ORDER_DATE', name: 'Order date', mapper: () => moment(new Date(), environment.timeZone).format('DD/MM/YYYY HH:mm') },
  { id: 'TORAL_PAID', name: 'Total paid', mapper: () => '40' },
  { id: 'CUSTOMER_NAME', name: 'Customer name', mapper: () => 'Lorem Ipsum' },
  { id: 'CUSTOMER_EMAIL', name: 'Customer email', mapper: () => 'Lorem.Ipsum@domain.com' },
  { id: 'CUSTOMER_PHONE', name: 'Customer phone', mapper: () => '+4470000000' },
  { id: 'BOOKING_PROVIDER', name: 'Booking Provider', mapper: () => 'bottomlessbrunch' },
  { id: 'ADDITIONAL_INFO', name: 'Additional info', mapper: () => 'Lorem ipsum dolor sit amet' },
  { id: 'CHECKOUT_OPTION', name: 'Checkout options', mapper: () => 'Lorem ipsum dolor sit amet' },
];