import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SplashScreenService } from '@core/services/splash-screen.service';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html'
})
export class SplashScreenComponent implements OnInit {

  @ViewChild('splashScreen', { static: true }) splashScreen!: ElementRef;

  constructor(
    private splashScreenService: SplashScreenService
  ) { }

  ngOnInit(): void {
    this.splashScreenService.init(this.splashScreen);
  }
}
