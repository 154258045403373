import { environment } from '@env/environment';
import * as moment from 'moment-timezone';

export class DateTimeUtil {

    static listTimeOptions(): string[] {
        const hours: string[] = [];
        for (let hour = 0; hour < 24; hour++) {
            hours.push(moment({ hour }).format('HH:mm'));
            hours.push(moment({ hour, minute: 30 }).format('HH:mm'));
        }
        return hours;
    }

    static getTimeOption(date: moment.Moment): string {
        return moment(date).format('HH:mm');
    }

    static setTimeOption(date: moment.Moment, time: string): moment.Moment {
        const t = moment(time, 'HH:mm');
        date = date.tz(environment.timeZone);
        date.set({ hour: t.hour(), minute: t.minute(), second: 0, millisecond: 0 }).format('HH:mm');
        return date;
    }
}