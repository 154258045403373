<nz-drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" [nzWidth]="550" nzPlacement="right" [nzTitle]="titleTpl"
    [nzFooter]="footerTpl" (nzOnClose)="handleCancel()">
    <ng-container *nzDrawerContent>
        <div>
            <form nz-form [formGroup]="eventDateNoteForm" nzLayout="vertical">
                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-control>
                                <ng-container *ngxPermissionsExcept="[UserRoleEnum.SalesChannel]">
                                    <textarea rows="18" nz-input formControlName="note"></textarea>
                                </ng-container>
                                <ng-container *ngxPermissionsOnly="[UserRoleEnum.SalesChannel]">
                                    <textarea rows="18" nz-input formControlName="note" readonly></textarea>
                                </ng-container>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            </form>
        </div>
    </ng-container>

    <ng-template #titleTpl>
        <div nz-row class="tnt-widget-table__header">
            <div nz-col nzSpan="22">
                <i class="fa-solid fa-notes-medical fa-fw"></i> Note
            </div>
            <div nz-col nzSpan="2" class="tnt-widget-table__header__cross">
                <span (click)="handleCancel()">x</span>
            </div>
        </div>
    </ng-template>

    <ng-template #footerTpl>
            <div class="tnt-widget-drawer__footer">
                <button nz-button (click)="handleCancel()">Cancel</button>
                <ng-container *ngxPermissionsExcept="[UserRoleEnum.SalesChannel]">
                    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isEventDateUpdating">Update</button>
                </ng-container>
                <ng-container *ngxPermissionsOnly="[UserRoleEnum.SalesChannel]">
                    <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isEventDateUpdating" [disabled]="true">Update</button>
                </ng-container>
            </div>
    </ng-template>
</nz-drawer>
