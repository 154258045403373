import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EMPTY, expand, map, reduce, tap, Observable } from 'rxjs';
import { environment } from '@env/environment';

import { PaginationUtil } from '@shared/utils/pagination-util';
import { MixpanelService } from './mixpanel.service';

import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { CompanyResponseModel } from '@shared/models/http/response/company/company-response.model';
import { PaginationRequestModel } from '@shared/models/http/request/common/pagination-request.model';
import { PaginationResponseModel } from '@shared/models/http/response/common/pagination-response.model';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  activeCompany?: CompanyResponseModel;
  
  constructor(
    private http: HttpClient,
    private mixpanelService: MixpanelService,
  ) { }

  listWithPagination(paginationRequestModel: PaginationRequestModel): Observable<PaginationResponseModel<CompanyResponseModel>> {
    const params = PaginationUtil.setPaginationParams(paginationRequestModel);
    return this.http.get<PaginationResponseModel<CompanyResponseModel>>(`${this.apiUrl}/companies`, {
      params,
    });
  }

  listWithoutPagination(): Observable<CompanyResponseModel[]> {
    const paginationRequestModel = new PaginationRequestModel(1, PaginationUtil.defaultPageSize);
    return this.listWithPagination(paginationRequestModel).pipe(
      expand(response => {
        paginationRequestModel.page++;
        if (response && response.totalPages && paginationRequestModel.page <= response.totalPages) {
          return this.listWithPagination(paginationRequestModel);
        }
        return EMPTY;
      }),
      map((obj: any) => obj.data),
      reduce((acc, x: any) => acc.concat(x))
    );
  }
 
  getById(id: number): Observable<CompanyResponseModel> {
    return this.http.get<ApiDataResponseModel<CompanyResponseModel>>(`${this.apiUrl}/companies/${id}`).pipe(
      map((response: ApiDataResponseModel<CompanyResponseModel>) => {
        return response.data;
      })
    );
  }

  updateSettings(id: number, settings: { settings: any }): Observable<{ settings: any }> {
    return this.http.put<{ settings: any }>(`${this.apiUrl}/companies/${id}/settings`, settings).pipe(
      tap((settings: { settings: any }) => {
        this.mixpanelService.track('Company settings updated', settings);
      })
    );
  }

  getActiveCompany() {
    return this.activeCompany;
  }

  setActiveCompany(company: CompanyResponseModel) {
    this.activeCompany = company;
  }

  updateCompanyContext(company: CompanyResponseModel) {
    this.setActiveCompany(company);
  }
}
