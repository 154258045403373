import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@auth0/auth0-angular';
import { NgxPermissionsGuard } from 'ngx-permissions';
import { CustomPreloadingService } from '@core/setup/custom-preloading.service';

import { LayoutDefaultComponent } from '@layout/layout-default/layout-default.component';
import { LayoutPassportComponent } from '@layout/layout-passport/layout-passport.component';

const routes: Routes = [
  {
    path: '',
    component: LayoutDefaultComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'events',
        loadChildren: () => import('./routes/event/event.module').then(m => m.EventModule)
      },
      {
        path: 'bookings',
        loadChildren: () => import('./routes/booking/booking.module').then(m => m.BookingModule)
      },
      {
        path: 'booking-channels',
        loadChildren: () => import('./routes/booking-channel/booking-channel.module').then(m => m.BookingChannelModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            except: 'SalesChannel', // Deny access to users with the "SalesChannel" role
            redirectTo: '/' // Redirect "SalesChannel" users to the home page if they try to access this route
          }
        }
      },
      {
        path: 'finance',
        loadChildren: () => import('./routes/finance/finance.module').then(m => m.FinanceModule),
        canActivate: [NgxPermissionsGuard],
        data: {
          permissions: {
            except: 'SalesChannel', // Deny access to users with the "SalesChannel" role
            redirectTo: '/' // Redirect "SalesChannel" users to the home page if they try to access this route
          }
        }
      },
      {
        path: 'marketing',
        loadChildren: () => import('./routes/marketing/marketing.module').then(m => m.MarketingModule)
      },
      {
        path: 'notifications',
        loadChildren: () => import('./routes/notification/notification.module').then(m => m.NotificationModule)
      },
      {
        path: 'settings',
        loadChildren: () => import('./routes/setting/setting.module').then(m => m.SettingModule)
      }
    ]
  },

  {
    path: '',
    component: LayoutPassportComponent,
    children: [
      {
        path: 'auth',
        loadChildren: () => import('./routes/passport/passport.module').then(m => m.PassportModule)
      }
    ]
  },

  {
    path: 'error',
    children: [
      {
        path: '',
        loadChildren: () => import('./routes/error/error.module').then(m => m.ErrorModule)
      }
    ]
  },

  { path: '**', redirectTo: 'error/404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'top',
      preloadingStrategy: CustomPreloadingService
    }
  )
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }