<nz-drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" [nzWidth]="720" nzPlacement="right" nzTitle="Email"
    [nzFooter]="footerTpl" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div nz-row class="tnt-drawer-email">
            <ng-container *ngIf="mode === ModeEnum.FORM">
                <div nz-col nzSpan="24">
                    <div *ngIf="selectedEmailsCancelledStatusEnum === EmailsCancelledStatusEnum.ALL_CANCELLED" nz-row class="tnt-drawer-email__form__info">
                        <div nz-col nzSpan="24">
                            <nz-alert *ngIf="isBulkEmail" nzType="error" nzMessage="All selected bookings have been cancelled. Are you sure you still want to send an e-mail to canceled bookings?"></nz-alert>
                            <nz-alert *ngIf="!isBulkEmail" nzType="error" nzMessage="The selected booking has been cancelled. Are you sure you still want to send mail?"></nz-alert>
                        </div>
                    </div>
                    <div *ngIf="selectedEmailsCancelledStatusEnum === EmailsCancelledStatusEnum.SOME_CANCELLED" nz-row class="tnt-drawer-email__form__info">
                        <div nz-col nzSpan="24">
                            <nz-alert *ngIf="isBulkEmail" nzType="error" nzMessage="Some selected bookings have been cancelled. Please make sure whether or not you will send to cancelled bookings. You can use the switch button to change the sending status."></nz-alert>
                        </div>
                    </div>
                    <div *ngIf="isBulkEmail" nz-row class="tnt-drawer-email__form__info">
                        <div nz-col nzSpan="24" class="text-right">
                            <span class="tnt-drawer-email__form__info__text">Include cancelled?</span> 
                            <nz-switch [(ngModel)]="isSentToCancelledBookings" (ngModelChange)="onCancelledEmailSwitchChanged($event)"></nz-switch>
                        </div>
                    </div>
                    <form nz-form [formGroup]="emailForm" nzLayout="vertical">
                        <div nz-row>
                            <div nz-col nzSpan="24">
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input formControlName="to" placeholder="To" nzSize="large" />
                                    </nz-form-control>
                                </nz-form-item>
                    
                                <nz-form-item>
                                    <nz-form-control>
                                        <input nz-input formControlName="subject" placeholder="Subject" nzSize="large" />
                                    </nz-form-control>
                                </nz-form-item>
                            </div>
                        </div>
                    </form>
                
                    <ckeditor #ckEditor [editor]="Editor" [(ngModel)]="emailData" [config]="editorConfig"></ckeditor>
                </div>
            </ng-container>
        
            <ng-container *ngIf="mode === ModeEnum.PREVIEW">
                <div nz-col nzSpan="24">
                    <div class="tnt-drawer-email__preview">
                        <div class="tnt-drawer-email__preview__alert">
                            <nz-alert nzType="warning" [nzMessage]="previewAlertTextRef"></nz-alert>
                            <ng-template #previewAlertTextRef>
                                <strong>This message will be sent to a total of {{ recipients.length }} ticket holders.</strong>
                            </ng-template>
                        </div>
                
                        <div class="tnt-drawer-email__preview__header text-right">
                            <button nz-button nzType="primary" (click)="onTestEmailBtnClick(testEmailTemplateContent)">Send Test Email</button>
                        </div>
        
                        <nz-divider></nz-divider>
        
                        <div class="tnt-drawer-email__preview__subject">
                            <div [innerHtml]="emailForm.controls['subject']!.value"></div>
                        </div>
        
                        <nz-divider></nz-divider>
        
                        <div class="tnt-drawer-email__preview__body">
                            <div [innerHtml]="emailData"></div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </ng-container>

    <ng-template #footerTpl>
        <div class="tnt-widget-drawer__footer">
            <button nz-button (click)="close()">Cancel</button>
        
            <ng-container *ngIf="mode === ModeEnum.FORM">
                <button nz-button nzType="primary" (click)="mode = ModeEnum.PREVIEW" [disabled]="!isFormValid() || !recipients.length">Preview</button>
            </ng-container>
            
            <ng-container *ngIf="mode === ModeEnum.PREVIEW">
                <button nz-button nzType="default" (click)="mode = ModeEnum.FORM">Update text</button>
            </ng-container>
            
            <ng-container *ngIf="mode === ModeEnum.PREVIEW">
                <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isEmailSending">Send</button>
            </ng-container>
        </div>
      </ng-template>
</nz-drawer>

<ng-template #testEmailTemplateContent>
    <form [formGroup]="testEmailForm">
        <nz-form-item>
            <nz-form-control>
                <input nz-input formControlName="email" placeholder="Email" nzSize="large" autocomplete="off"/>
            </nz-form-control>
        </nz-form-item>
    </form>
</ng-template>