export enum OrderEnum {
  ASC = 'ASC',
  DESC = 'DESC',
}

export class PaginationRequestModel {
  constructor(page: number, size: number, sortBy?: string, order?: OrderEnum, filters?: string[]) {
    this.page = page;
    this.size = size;
    this.sortBy = sortBy;
    this.order = order;
    this.filters = filters;
  }

  page!: number;
  size!: number;
  sortBy?: string;
  order?: OrderEnum;
  filters?: string[];
}
