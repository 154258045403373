<nz-drawer #nzDrawerRef [nzClosable]="false" [nzVisible]="isDrawerVisible" [nzWidth]="'70%'" nzPlacement="right" nzTitle="Merge Bookings"
    [nzFooter]="footerTpl" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div #drawerContentRef class="tnt-widget-drawer__content">
            <div class="tnt-drawer-merge-booking">
                <div class="tnt-widget-table tnt-drawer-merge-booking__main-booking-table">
                    <div class="tnt-widget-table__content">
                        <nz-table *ngIf="mainBooking" #mainBookingTable [nzData]="[mainBooking]"
                            [nzShowPagination]="false" [nzFrontPagination]="false" [nzLoading]="isLoadingBookings"
                            [nzScroll]="{ x: '1400px', y: '200px' }">
                            <thead>
                                <tr>
                                    <th nzWidth="130px">Status</th>
                                    <th nzAlign="center" nzWidth="78px">People</th>
                                    <th nzCustomFilter>Customer</th>
                                    <th>Event</th>
                                    <th nzWidth="150px">Event date</th>
                                    <th nzWidth="150px">Booking provider</th>
                                    <th nzWidth="150px">Order ID</th>
                                    <th nzWidth="150px">Order date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let booking of mainBookingTable.data">
                                    <td>
                                        <container-element [ngSwitch]="booking?.status">
                                            <nz-tag *ngSwitchCase="BookingStatusEnum.PENDING" nzColor="warning">
                                                {{ booking?.status | titlecase }}
                                            </nz-tag>
    
                                            <nz-tag *ngSwitchCase="BookingStatusEnum.CONFIRMED" nzColor="processing">
                                                {{ booking?.status | titlecase }}
                                            </nz-tag>
    
                                            <nz-tag *ngSwitchCase="BookingStatusEnum.CANCELLED" nzColor="error">
                                                {{ booking?.status | titlecase }}
                                            </nz-tag>
    
                                            <nz-tag *ngSwitchCase="BookingStatusEnum.CANCELLED_BY_DINER" nzColor="error">
                                                {{ booking?.status?.replace('_', ' ') | titlecase }}
                                            </nz-tag>
                                        </container-element>
                                    </td>
                                    <td nzAlign="center">{{ booking.people }}</td>
                                    <td>
                                        <div class="tnt-event-car--smd">
                                            <div *ngIf="booking?.customer?.name" class="tnt-event-card--sm__title">
                                                {{ booking.customer.name | titlecase }}
                                            </div>
                                            <div class="tnt-event-card--sm__subtitle">
                                                <span *ngIf="booking?.customer?.email">{{ booking?.customer?.email }} <br/></span>
                                                <span>{{ booking?.customer?.phone }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="tnt-event-card--sm">
                                            <div class="tnt-event-card--sm__title">
                                                {{ booking.channelDate.channelTicketType.eventName }}
                                            </div>
                                            <div class="tnt-event-card--sm__subtitle">
                                                <span *ngIf="booking.channelDate.channelTicketType?.venueName">{{  booking.channelDate.channelTicketType.venueName | titlecase }} <br/></span>
                                                <span>{{ booking.channelDate.date | dateWithTimezone: 'dddd, MMM D, YYYY [at] HH:mm zz' }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ booking?.channelDate?.date  | dateWithTimezone }}</td>
                                    <td>
                                        <nz-tag [nzColor]="booking._bookingProvider?._style?.background" [style.color]="booking._bookingProvider?._style?.color">
                                            {{booking._bookingProvider?.name }}
                                        </nz-tag>
                                    </td>
                                    <td>{{ booking.purchaseReference }}</td>
                                    <td>{{ booking.purchaseDate | dateWithTimezone }}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
        
                <nz-divider></nz-divider>
        
                <div class="tnt-widget-table">
                    <div class="tnt-widget-table__content">
                        <nz-table #bookingTable [nzData]="bookings"
                        [nzShowPagination]="false" [nzFrontPagination]="false" [nzShowPagination]="false"
                        [nzLoading]="isLoadingBookings" [nzScroll]="bookingTableScrollStyle">
                            <thead>
                                <tr>
                                    <th nzLeft nzWidth="40px"></th>
                                    <th nzLeft nzWidth="110px"></th>
                                    <th nzWidth="130px">Status</th>
                                    <th nzAlign="center" nzWidth="78px">People</th>
                                    <th nzCustomFilter>Customer</th>
                                    <th>Event</th>
                                    <th nzWidth="150px">Event date</th>
                                    <th nzWidth="150px">Booking provider</th>
                                    <th nzWidth="150px">Order ID</th>
                                    <th nzWidth="150px">Order date</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let booking of bookingTable.data">
                                    <td nzLeft>
                                        <button nz-button nzType="primary" nzSize="small" nzShape="circle" (click)="onRemoveClick(booking)"
                                            [disabled]="booking.merge?.isPrincipal" nzDanger>
                                            <i class="fa-solid fa-minus"></i>
                                        </button>
                                    </td>
                                    <td nzLeft>
                                        <ng-container *ngIf="booking.merge?.isPrincipal">
                                            <nz-tag class="tnt-drawer-merge-booking__tag-main" nzColor="#3699FF">Main</nz-tag>
                                        </ng-container>
                                        <ng-container *ngIf="!booking.merge?.isPrincipal">
                                            <a (click)="setAsMain(booking)">Set as main</a>
                                        </ng-container>
                                    </td>
                                    <td>
                                        <container-element [ngSwitch]="booking?.status">
                                            <nz-tag *ngSwitchCase="BookingStatusEnum.PENDING" nzColor="warning">
                                                {{ booking.status | titlecase }}
                                            </nz-tag>
    
                                            <nz-tag *ngSwitchCase="BookingStatusEnum.CONFIRMED" nzColor="processing">
                                                {{ booking.status | titlecase }}
                                            </nz-tag>
    
                                            <nz-tag *ngSwitchCase="BookingStatusEnum.CANCELLED" nzColor="error">
                                                {{ booking.status | titlecase }}
                                            </nz-tag>
    
                                            <nz-tag *ngSwitchCase="BookingStatusEnum.CANCELLED_BY_DINER" nzColor="error">
                                                {{ booking.status.replace('_', ' ') | titlecase }}
                                            </nz-tag>
                                        </container-element>
                                    </td>
                                    <td nzAlign="center">{{ booking.people }}</td>
                                    <td>
                                        <div class="tnt-event-car--smd">
                                            <div *ngIf="booking.customer?.name" class="tnt-event-card--sm__title">
                                                {{ booking.customer.name | titlecase }}
                                            </div>
                                            <div class="tnt-event-card--sm__subtitle">
                                                <span *ngIf="booking.customer?.email">{{ booking.customer.email }} <br/></span>
                                                <span>{{ booking.customer.phone }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="tnt-event-card--sm">
                                            <div class="tnt-event-card--sm__title">
                                                {{ booking.channelDate.channelTicketType.eventName }}
                                            </div>
                                            <div class="tnt-event-card--sm__subtitle">
                                                <span *ngIf="booking.channelDate.channelTicketType?.venueName">{{  booking.channelDate.channelTicketType.venueName | titlecase }} <br/></span>
                                                <span>{{ booking?.channelDate?.date | dateWithTimezone: 'dddd, MMM D, YYYY [at] HH:mm zz' }}</span>
                                            </div>
                                        </div>
                                    </td>
                                    <td>{{ booking?.channelDate?.date  | dateWithTimezone }}</td>
                                    <td>
                                        <nz-tag [nzColor]="booking._bookingProvider?._style?.background" [style.color]="booking._bookingProvider?._style?.color">
                                            {{booking._bookingProvider?.name }}
                                        </nz-tag>
                                    </td>
                                    <td>{{ booking.purchaseReference }}</td>
                                    <td>{{ booking.purchaseDate | dateWithTimezone }}</td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>

    <ng-template #footerTpl>
        <div class="tnt-widget-drawer__footer">
          <button nz-button (click)="close()">Cancel</button>
          <button *ngIf="bookings.length > 1" nz-button nzType="primary" (click)="handleOk()" [nzLoading]="isMergingBookings">Merge</button>
          <button *ngIf="bookings.length < 2" nz-button nzType="primary" (click)="close()" [nzLoading]="isMergingBookings">Save and Exit</button>
        </div>
      </ng-template>
</nz-drawer>


