export class BookingProviderResponseModel {
    id!: number;
    name!: string;
    slug!: string;

    _style!: BookingProviderStyleModel;
}

export class BookingProviderStyleModel {
    constructor(color: string, background: string, backgroundDark: string) {
        this.color = color;
        this.background = background;
        this.backgroundDark = backgroundDark;
    }

    color!: string;
    background!: string;
    backgroundDark!: string;
}