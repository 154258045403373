export enum BookingProviderEnum {
    Airbnb = 'airbnb',
    Bottomlessbrunch = 'bottomlessbrunch',
    Chillisauce = 'chillisauce',
    Designmynight = 'designmynight',
    Dice = 'dice',
    DirectReservation = 'direct_reservation',
    CentralTickets = 'centraltickets',
    Ents24 = 'ents24',
    Eventbrite = 'eventbrite',
    Fever = 'fever',
    Fizzbox = 'fizzbox',
    Freedom = 'freedom',
    Groupon = 'groupon',
    MyAirExperience = 'myairexperience',
    Skiddle = 'skiddle',
    Opentable = 'opentable',
    OutSavvy = 'outsavvy',
    Other = 'other',
    Viator = 'viator'
}
