export class BookingParserUpdateRequestModel {

    find!: Find;
    changes!: Changes;
}

class Find {
    companyId!: number;
    bookingId!: number;
}

class Changes {
    eventDateId!: number;
    eventName!: string;
    ticketName?: string;
    venueName?: string;
    venueAddress?: string;
    venueCity?: string;
    eventDate?: string; // Sting as "YYYY-MM-DD HH:mm:ss" format
    people!: number;
    purchaseDate!: string; // Sting as "YYYY-MM-DD HH:mm:ss" format
    purchaseReference!: string; // Required field, but the value can be an empty string
    customerName!: string;
    customerEmail!: string; // Required field, but the value can be an empty string
    customerPhone!: string; // Required field, but the value can be an empty string
    singlePrice?: number;
    totalPaid?: number;
    notes!: string; // Required field, but the value can be an empty string
    status!: string;
    externalManageBookingUrl?: string;
    bookingOptions: BookingOption[] = [];
}

class BookingOption {
    option!: string;
    value!: string;
}
