import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, expand, map, reduce, tap, Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { environment } from '@env/environment';

import { MixpanelService } from './mixpanel.service';
import { BookingProviderService } from './booking-provider.service';

import { PaginationUtil } from '@shared/utils/pagination-util';
import { PaginationRequestModel } from '@shared/models/http/request/common/pagination-request.model';
import { PaginationResponseModel } from '@shared/models/http/response/common/pagination-response.model';

import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { BookingResponseModel } from '@shared/models/http/response/booking/booking-response.model';
import { BookingMoveRequestModel } from '@shared/models/http/request/booking/booking-move-request.model';
import { BookingRequestQueryModel } from '@shared/models/http/request/booking/booking-request-query.model';
import { BookingCreateRequestModel } from '@shared/models/http/request/booking/booking-create-request.model';
import { BookingUpdateRequestModel } from '@shared/models/http/request/booking/booking-update-request.model';
import { BookingStatusUpdateRequestModel } from '@shared/models/http/request/booking/booking-status-update-request.model';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  constructor(
    private http: HttpClient,
    private mixpanelService: MixpanelService,
    private bookingProviderService: BookingProviderService
  ) { }

  listWithPagination(bookingRequestQueryModel: BookingRequestQueryModel, paginationRequestModel: PaginationRequestModel): Observable<PaginationResponseModel<BookingResponseModel>> {
    let params = this.generateListQuery(bookingRequestQueryModel, paginationRequestModel);
    return this.http.get<PaginationResponseModel<BookingResponseModel>>(`${this.apiUrl}/bookings`, {
      params,
    }).pipe(
      map((response: PaginationResponseModel<BookingResponseModel>) => {
        this.bookingProviderService.setBookingsSalesChannels(response.data);
        return response;
      })
    );
  }

  listWithoutPagination(bookingRequestQueryModel: BookingRequestQueryModel): Observable<BookingResponseModel[]> {
    const paginationRequestModel = new PaginationRequestModel(1, PaginationUtil.defaultPageSize);
    return this.listWithPagination(bookingRequestQueryModel, paginationRequestModel).pipe(
      expand(response => {
        paginationRequestModel.page++;
        if (response && response.totalPages && paginationRequestModel.page <= response.totalPages) {
          return this.listWithPagination(bookingRequestQueryModel, paginationRequestModel);
        }
        return EMPTY;
      }),
      map((obj: any) => obj.data),
      reduce((acc, x: any) => acc.concat(x))
    );
  }

  getById(id: string): Observable<BookingResponseModel> {
    return this.http.get<ApiDataResponseModel<BookingResponseModel>>(`${this.apiUrl}/bookings/${id}`).pipe(
      map((response: ApiDataResponseModel<BookingResponseModel>) => {
        this.bookingProviderService.setBookingsSalesChannels([response.data]);
        return response.data;
      })
    );
  }

  create(bookingCreateRequestModel: BookingCreateRequestModel): Observable<BookingResponseModel> {
    return this.http.post<ApiDataResponseModel<BookingResponseModel>>(`${this.apiUrl}/bookings`, bookingCreateRequestModel).pipe(
      map((response: ApiDataResponseModel<BookingResponseModel>) => {
        this.bookingProviderService.setBookingsSalesChannels([response.data]);
        return response.data;
      }),
      tap((booking: BookingResponseModel) => {
        this.mixpanelService.track('User created a booking', booking);
      })
    );
  }

  update(id: number, bookingUpdateRequestModel: BookingUpdateRequestModel): Observable<BookingResponseModel> {
    return this.http.put<ApiDataResponseModel<BookingResponseModel>>(`${this.apiUrl}/bookings/${id}`, bookingUpdateRequestModel).pipe(
      map((response: ApiDataResponseModel<BookingResponseModel>) => {
        this.bookingProviderService.setBookingsSalesChannels([response.data]);
        return response.data;
      }),
      tap((booking: BookingResponseModel) => {
        this.mixpanelService.track('User updated a booking', booking);
      })
    );
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/bookings/${id}`).pipe(
      tap(() => {
        this.mixpanelService.track('User deleted a booking', { bookingId: id });
      })
    );
  }

  updateStatus(bookingStatusUpdateRequestModel: BookingStatusUpdateRequestModel): Observable<BookingResponseModel> {
    return this.http.patch<BookingResponseModel>(`${this.apiUrl}/bulk/bookings/status`, bookingStatusUpdateRequestModel);
  }

  moveToChannelDate(bookingMoveRequestModel: BookingMoveRequestModel): Observable<BookingResponseModel> {
    return this.http.patch<BookingResponseModel>(`${this.apiUrl}/bulk/bookings/channelDateId`, bookingMoveRequestModel);
  }

  private generateListQuery(bookingRequestQueryModel: BookingRequestQueryModel, paginationRequestModel?: PaginationRequestModel): HttpParams {
    let params = PaginationUtil.setPaginationParams(paginationRequestModel);

    if (bookingRequestQueryModel.eventDateId) {
      params = params.append('eventDateId', bookingRequestQueryModel.eventDateId);
    }

    if (bookingRequestQueryModel.channelDateId) {
      params = params.append('channelDateId', bookingRequestQueryModel.channelDateId);
    }

    if (bookingRequestQueryModel.bookingMergeId) {
      params = params.append('bookingMergeId', bookingRequestQueryModel.bookingMergeId);
    }

    if (bookingRequestQueryModel.bookingStatus) {
      params = params.append('bookingStatus', bookingRequestQueryModel.bookingStatus);
    }

    if (bookingRequestQueryModel.purchaseReference) {
      params = params.append('purchaseReference', bookingRequestQueryModel.purchaseReference);
    }

    if (bookingRequestQueryModel.purchaseDate) {
      const purchaseDate = moment(bookingRequestQueryModel.purchaseDate, environment.timeZone).format('YYYY-MM-DD');
      params = params.append('purchaseDate', purchaseDate);
    }

    if (bookingRequestQueryModel.customerName) {
      params = params.append('customerName', bookingRequestQueryModel.customerName);
    }

    if (bookingRequestQueryModel.customerEmail) {
      params = params.append('customerEmail', bookingRequestQueryModel.customerEmail);
    }

    if (bookingRequestQueryModel.from) {
      const from = moment(bookingRequestQueryModel.from, environment.timeZone).format('YYYY-MM-DD HH:mm:ss');
      params = params.append('From', from);
    }

    if (bookingRequestQueryModel.to) {
      const to = moment(bookingRequestQueryModel.to, environment.timeZone).format('YYYY-MM-DD HH:mm:ss');
      params = params.append('To', to);
    }

    return params;
  }
}
