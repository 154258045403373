export const VERDANA_FONT_WIDTHS_16_PX = {
  '0': 10,
  '1': 10,
  '2': 10,
  '3': 10,
  '4': 10,
  '5': 10,
  '6': 10,
  '7': 10,
  '8': 10,
  '9': 10,
  A: 11,
  B: 11,
  C: 11,
  D: 12,
  E: 10,
  F: 9,
  G: 12,
  H: 12,
  I: 7,
  J: 7,
  K: 11,
  L: 9,
  M: 13,
  N: 12,
  O: 13,
  P: 10,
  Q: 13,
  R: 11,
  S: 11,
  T: 10,
  U: 12,
  V: 11,
  W: 16,
  X: 11,
  Y: 10,
  Z: 11,
  a: 10,
  b: 10,
  c: 8,
  d: 10,
  e: 10,
  f: 6,
  g: 10,
  h: 10,
  i: 4,
  j: 6,
  k: 9,
  l: 4,
  m: 16,
  n: 10,
  o: 10,
  p: 10,
  q: 10,
  r: 7,
  s: 8,
  t: 6,
  u: 10,
  v: 9,
  w: 13,
  x: 9,
  y: 9,
  z: 8,
  '!': 6,
  '@': 16,
  '#': 13,
  $: 10,
  '%': 17,
  '^': 13,
  '&': 12,
  '*': 10,
  '(': 7,
  ')': 7,
  _: 10,
  '-': 7,
  '+': 13,
  '=': 13,
  ',': 6,
  '.': 6,
  '/': 7,
  "'": 4,
  ':': 7,
  '|': 7,
  '?': 9,
  ';': 7,
  '<': 13,
  '>': 13
};
