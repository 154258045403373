import { BookingProviderResponseModel } from "../booking-provider/booking-provider-response.model";

export class BookingResponseModel {

    id!: number;
    channelDate!: ChannelDate;
    status!: string;
    purchaseReference!: string;
    purchaseDate!: Date;
    people!: number;
    singlePrice?: number;
    totalPaid?: number;
    customer!: Customer;
    notes?: string;
    merge?: MergeResponseModel;
    bookingOptions: BookingOption[] = [];
    externalManageBookingUrl?: string;

    // Extra Parameters
    _childeren: BookingResponseModel[] = [];
    _disabled?: boolean;
    _isCollapsed?: boolean;
    _isGroupBookingLabel = false;
    _bookingProvider?: BookingProviderResponseModel;
}

class ChannelDate {
    id!: number;
    date!: Date;
    eventDateId!: number;
    channelTicketType!: ChannelTicketType;
}

class ChannelTicketType {
    id!: number;
    companyId!: number;
    salesChannelId!: number;
    eventName!: string;
    ticketName?: string;
    description?: string;
    venueName?: string;
    venueCity?: string;
    venueAddress?: string;
    price?: number;
}

export class Customer {

    constructor(name: string, email?: string, phone?: string) {
        this.name = name;
        this.email = email;
        this.phone = phone;
    }

    name!: string;
    email?: string;
    phone?: string;
}

class BookingOption {
    id!: number;
    option!: string;
    value!: string;
}

export class MergeResponseModel {

    constructor(mergeId: number, isPrincipal: boolean) {
        this.mergeId = mergeId;
        this.isPrincipal = isPrincipal;
    }

    mergeId!: number;
    isPrincipal!: boolean;
}

export class CheckoutOptionModel {
    option!: string;
    value!: string;
}
