import { BookingProviderEnum } from "@shared/enums/booking-provider.enum";
import { BookingProviderResponseModel, BookingProviderStyleModel } from "@shared/models/http/response/booking-provider/booking-provider-response.model";

export class BookingProviderUtil {

    static getStyle(bookingProvider: BookingProviderResponseModel | undefined): BookingProviderStyleModel {
        if (!bookingProvider) {
            return new BookingProviderStyleModel('#035E5A', '#C9F7F5', '#95FDF9');
        }

        let style: BookingProviderStyleModel;
        
        switch (bookingProvider.slug) {
            case BookingProviderEnum.Airbnb: {
                style = new BookingProviderStyleModel('#FFFFFF', '#FF385C', '#FF385C');
                break;
            }
            case BookingProviderEnum.Bottomlessbrunch: {
                style = new BookingProviderStyleModel('#FFA800', '#FFF4DE', '#FFA800');
                break;
            }
            case BookingProviderEnum.Designmynight: {
                style = new BookingProviderStyleModel('#8950FC', '#EEE5FF', '#EEE5FF');
                break;
            }
            case BookingProviderEnum.Dice: {
                style = new BookingProviderStyleModel('#FFFFFF', '#8186a9', '#8186a9');
                break;
            }
            case BookingProviderEnum.DirectReservation: {
                style = new BookingProviderStyleModel('#3699FF', '#E1F0FF', '#E1F0FF');
                break;
            }
            case BookingProviderEnum.Eventbrite: {
                style = new BookingProviderStyleModel('#FFF4DE', '#D1410C', '#D1410C');
                break;
            }
            case BookingProviderEnum.Fever: {
                style = new BookingProviderStyleModel('#181C32', '#D1D3E0', '#BDC0D1');
                break;
            }
            case BookingProviderEnum.Opentable: {
                style = new BookingProviderStyleModel('#F64E60', '#FFE2E5', '#FDACB4');
                break;
            }
            case BookingProviderEnum.Skiddle: {
                style = new BookingProviderStyleModel('#FFFFFF', '#8186a9', '#8186a9');
                break;
            }
            case BookingProviderEnum.Viator: {
                style = new BookingProviderStyleModel('#FFFFFF', '#8186a9', '#8186a9');
                break;
            }
            default: {
                style = new BookingProviderStyleModel('#035E5A', '#C9F7F5', '#95FDF9');
                break;
            }
        }

        return style;
    }
    
}