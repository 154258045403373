import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonTypeEnum } from '@shared/enums/button-type.enum';

@Component({
  selector: 'app-confirmation-bar',
  templateUrl: './confirmation-bar.component.html'
})
export class ConfirmationBarComponent implements OnInit {

  @Input() zIndex?: number;
  @Input() loading!: boolean;
  @Input() disabled!: boolean;
  @Input() submitButtonText = 'Save & Continue'; 
  @Input() cancelButtonText = 'Discard'; 
  
  @Output() public buttonClick = new EventEmitter<ButtonTypeEnum>();

  constructor() { }

  ngOnInit(): void {

  }

  onCancelClick() {
    this.buttonClick.emit(ButtonTypeEnum.CANCEL);
  }

  onSubmitClick() {
    this.buttonClick.emit(ButtonTypeEnum.SUBMIT);
  }
}
