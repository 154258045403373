import { Column } from '@shared/models/common/csv/column';

export const columns: Column[] = [
  { id: 'LEAD_NAME', name: 'LEAD NAME', mapper: 'col1' },
  { id: 'TICKET_NUMBER', name: 'TICKET NUMBER', mapper: 'col2' },
  { id: 'TICKET_PROVIDER', name: 'TICKET PROVIDER', mapper: 'col3' },
  { id: 'NUMBER_IN_PARTY', name: 'NUMBER IN PARTY', mapper: 'col4' },
  { id: 'FOOD_REQUEST', name: 'FOOD REQUEST / ALLERGEN', mapper: 'col5' },
  { id: 'FOOD_ORDER', name: 'FOOD ORDER', mapper: 'col6' },
  { id: 'NUMBER ARRIVED', name: 'PRICE PER TICKET', mapper: 'col7' },
  { id: 'FOOD AWAY', name: 'PRICE PAID', mapper: 'col8' },
];
