<div class="tnt-confirmation-bar" [style.z-index]="zIndex ? zIndex : '1999'">
    <nz-divider class="tnt-confirmation-bar__divider"></nz-divider>
    <div class="tnt-confirmation-bar__inner">
        <div nz-row nzJustify="space-around" nzAlign="middle">
            <div nz-col nzXs="24" nzMd="12">
                <button nz-button nzSize="large" nzType="default" class="tnt-confirmation-bar__inner__button" 
                    (click)="onCancelClick()">{{ cancelButtonText }}</button>
                <button nz-button nzSize="large" nzType="primary" class="tnt-confirmation-bar__inner__button"
                    (click)="onSubmitClick()" [disabled]="disabled" [nzLoading]="loading"> {{ submitButtonText }}</button>
            </div>
        </div>
    </div>
</div>
