import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NgxPermissionsService } from 'ngx-permissions';
import { MetaDataUtil } from '@shared/utils/meta-data-util';
import * as moment from 'moment-timezone';

import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { ChannelDateService } from '@shared/services/channel-date.service';

import { ChannelDateResponseModel } from '@shared/models/http/response/channel/date/channel-date-response.model';
import { ChannelDateRequestQueryModel } from '@shared/models/http/request/channel/date/channel-date-request-query.model';

@UntilDestroy()
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {

  @Input() isCollapsed!: boolean;

  UserRoleEnum = UserRoleEnum;
  isAdmin = false;

  unassignedChannelsCount = -1;
  isLoadingUnassignedChannels = false;

  constructor(
    private channelDateService: ChannelDateService,
    private permissionsService: NgxPermissionsService
  ) {
    this.permissionsService.hasPermission(UserRoleEnum.Administrator).then((isAdmin) => {
      this.isAdmin = isAdmin;
    })
  }

  ngOnInit(): void {
    this.initializeUnassignedChannels();
  }

  async initializeUnassignedChannels() {
    // Get unassigned channel count from MetaData for performance.
    let metaData = MetaDataUtil.get();
    if (metaData && metaData.unassignedChannelsCount) {
      this.unassignedChannelsCount = metaData.unassignedChannelsCount;
    }

    this.listUnassignedChannels();
  }

  private listUnassignedChannels() {
    this.isLoadingUnassignedChannels = true;
    const channelDateRequestQueryModel = new ChannelDateRequestQueryModel();
    channelDateRequestQueryModel.onlyUnassigned = true;
    channelDateRequestQueryModel.from = moment().startOf('day').subtract(1, 'days').toDate();
    channelDateRequestQueryModel.to = moment().endOf('day').add(3, 'years').toDate();

    this.channelDateService.listWithoutPagination(channelDateRequestQueryModel).pipe(untilDestroyed(this))
      .subscribe((channels: ChannelDateResponseModel[]) => {
        this.unassignedChannelsCount = channels.length;
        // Update MetaData
        const metaDataModel = MetaDataUtil.get();
        metaDataModel.unassignedChannelsCount = channels.length;
        MetaDataUtil.set(metaDataModel);

        this.isLoadingUnassignedChannels = false;
      }, (err: any) => {
        console.log('Error while listing Unassigned Channels. Error : ', err);
      });
  }

}
