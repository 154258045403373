<div class="layout-default__sidebar">
    <ul nz-menu nzTheme="dark" nzMode="inline">
        <li nz-menu-item [routerLink]="['/bookings']" nzMatchRouter nzMatchRouterExact
            nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Bookings' : ''">
          <i [style.font-size]="isCollapsed ? '16px' : '14px'" class="fa-solid fa-users fa-fw"></i>
          <label [style.opacity]="isCollapsed ? 0 : 1">Bookings</label>
        </li>
        <li nz-menu-item [routerLink]="['/events/event-dates']" nzMatchRouter nzMatchRouterExact
            nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Event List' : ''">
            <i [style.font-size]="isCollapsed ? '16px' : '14px'" class="fa-solid fa-calendar-week fa-fw"></i>
            <label [style.opacity]="isCollapsed ? 0 : 1">Event List</label>
        </li>
        <li nz-menu-item [routerLink]="['/events/event-cards']" nzMatchRouter nzMatchRouterExact
            nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'My Events' : ''">
            <i [style.font-size]="isCollapsed ? '16px' : '14px'" class="fa-solid fa-calendar-day fa-fw"></i>
            <label [style.opacity]="isCollapsed ? 0 : 1">My Events</label>
        </li>
        <li *ngxPermissionsExcept="[UserRoleEnum.SalesChannel]" nz-menu-item [routerLink]="['/booking-channels']" nzMatchRouter nzMatchRouterExact
            nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Sales Channels' : ''">
            <div *ngIf="isCollapsed">
                <i [style.font-size]="'14px'" class="fa-solid fa-wifi fa-fw"></i>
                <div class="layout-default__sidebar__badge--sm">
                    <nz-badge [nzDot]="isCollapsed"></nz-badge>
                </div>
            </div>
            <div [style.opacity]="isCollapsed ? 0 : 1">
                <i [style.font-size]="'16px'" class="fa-solid fa-wifi fa-fw"></i>
                <label>Sales Channels</label>
                <div class="layout-default__sidebar__badge--lg">
                    <nz-badge [nzCount]="10"></nz-badge>
                </div>
            </div>
        </li>

        <li *ngxPermissionsExcept="[UserRoleEnum.SalesChannel]" nz-menu-item [routerLink]="['/finance']" nzMatchRouter nzMatchRouterExact
            nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Financials' : ''">
            <i [style.font-size]="isCollapsed ? '16px' : '14px'" class="fa-solid fa-chart-line fa-fw"></i>
            <label [style.opacity]="isCollapsed ? 0 : 1">Financials</label>
        </li>

        <!--<ng-container *ngIf="isAdmin">
            <li nz-menu-item [routerLink]="['/marketing']" nzMatchRouter nzMatchRouterExact
                nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Marketing (coming soon)' : ''">
                <i [style.font-size]="isCollapsed ? '16px' : '14px'" class="fa-solid fa-ranking-star fa-fw"></i>
                <label [style.opacity]="isCollapsed ? 0 : 1">Marketing (coming soon)</label>
            </li>
        </ng-container>

        <ng-container *ngIf="!isAdmin">
            <li nz-menu-item [nzDisabled]="true" nzMatchRouterExact
                nz-tooltip nzTooltipPlacement="right" [nzTooltipTitle]="isCollapsed ? 'Marketing (coming soon)' : ''">
                <i [style.font-size]="isCollapsed ? '16px' : '14px'" class="fa-solid fa-ranking-star fa-fw"></i>
                <label [style.opacity]="isCollapsed ? 0 : 1">Marketing (coming soon)</label>
            </li>
        </ng-container>-->

    </ul>
</div>
