import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { environment } from '@env/environment';

import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { BookingMergeAddRequestModel } from '@shared/models/http/request/booking-merge/booking-merge-add-request.model';
import { BookingMergeCreateRequestModel } from '@shared/models/http/request/booking-merge/booking-merge-create-request.model';
import { BookingMergeCreateResponseModel } from '@shared/models/http/response/booking-merge/booking-merge-create-response.model';

@Injectable({
  providedIn: 'root'
})
export class BookingMergeService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  constructor(
    private http: HttpClient
  ) { }

  create(bookingMergeCreateRequestModel: BookingMergeCreateRequestModel): Observable<BookingMergeCreateResponseModel> {
    return this.http.post<any>(`${this.apiUrl}/bookings/merges`, bookingMergeCreateRequestModel).pipe(
      map((response: ApiDataResponseModel<BookingMergeCreateResponseModel>) => {
        return response.data;
      })
    );
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/bookings/merges/${id}`);
  }

  add(bookingMergeAddRequestModel: BookingMergeAddRequestModel): Observable<void> {
    return this.http.post<void>(`${this.apiUrl}/bookings/merges/add`, bookingMergeAddRequestModel);
  }

  remove(id: number, bookingId: number): Observable<void> {
    const params = new HttpParams().append('mergeId', id).append('bookingId', bookingId);
    return this.http.delete<void>(`${this.apiUrl}/bookings/merges/remove`, {
      params,
    });
  }

  setPrinciple(id: number, bookingId: number): Observable<void> {
    const bookingSetPrincipleRequestModel = { 'principalBookingId': bookingId };
    return this.http.put<void>(`${this.apiUrl}/bookings/merges/${id}`, bookingSetPrincipleRequestModel);
  }
}
