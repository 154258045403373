<nz-drawer #nzDrawerRef [nzClosable]="false" [nzVisible]="isDrawerVisible" nzPlacement="bottom" [nzTitle]="title"
    [nzFooter]="undefined" (nzOnClose)="close()" [nzHeight]="'60%'">
    <ng-container *nzDrawerContent>
        <div #drawerContentRef class="tnt-widget-drawer__content">
            <div class="tnt-widget-table" >
                <div class="tnt-widget-table__content">
                    <nz-table #eventDateDrawerTable [nzData]="eventDates" [nzShowPagination]="false"
                        [nzFrontPagination]="false" [nzScroll]="eventTableScrollStyle">
                        <thead>
                            <tr>
                                <th [nzSortFn]="sortEventDateTableByEventNameFn">Event</th>
                                <th nzWidth="200px">Event Note</th>
                                <th>
                                    <span *ngxPermissionsOnly="[UserRoleEnum.ReservationManager]; else elseVenueTitle">Restaurant</span>
                                    <ng-template #elseVenueTitle>
                                        <span>Venue</span>
                                    </ng-template>
                                </th>
                                <th nzWidth="180px" [nzSortFn]="sortEventDateTableByEventDateFn" [nzSortOrder]="'descend'">Event date</th>
                                <th nzAlign="center" nzWidth="200px">Tickets sold</th>
                                <ng-container *ngxPermissionsOnly="[UserRoleEnum.SalesChannel]; else elseAlertTitleBlock">
                                    <th nzWidth="220px"></th>
                                </ng-container>
                                <ng-template #elseAlertTitleBlock>
                                    <th nzAlign="center" nzWidth="100px">Alert</th>
                                    <th nzRight nzWidth="65px"></th>
                                </ng-template>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let eventDate of eventDateDrawerTable.data" (click)="onEventDateViewButtonClick(eventDate)" class="tnt-widget-table__content__href">
                                <td>
                                    <div class="tnt-event-card--sm">
                                        <div class="tnt-event-card--sm__title">
                                            {{ eventDate.event.name | titlecase }}
                                        </div>
                                        <div class="tnt-event-card--sm__subtitle">
                                            <span *ngIf="eventDate?.event?.venue?.name">{{ eventDate?.event?.venue?.name | titlecase }} <br/></span>
                                            <span>{{ eventDate.start | dateWithTimezone: 'dddd, MMM D, YYYY [at] HH:mm zz' }}</span>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <ng-container *ngIf="eventDate.notes && eventDate.notes.length; else noNoteRef">
                                        <div (click)="$event.stopPropagation(); $event.preventDefault()">
                                            <a (click)="onEventDateNoteButtonClick(eventDate)" class="tnt-ellipsis-popover"
                                                [style.-webkit-line-clamp]="2">{{ eventDate.notes }}</a>
                                        </div>
                                    </ng-container>
                                    <ng-template #noNoteRef>
                                        <ng-container *ngxPermissionsExcept="[UserRoleEnum.SalesChannel]">
                                            <div (click)="$event.stopPropagation(); $event.preventDefault()" class="tnt-ellipsis-popover__no-content">
                                                <a (click)="onEventDateNoteButtonClick(eventDate)">
                                                    <i class="fa-solid fa-plus"></i> Add note
                                                </a>
                                            </div>
                                        </ng-container>
                                    </ng-template>
                                </td>
                                <td>{{ eventDate?.event?.venue?.name }}</td>
                                <td>{{ eventDate.start | dateWithTimezone }}</td>
                                <td nzAlign="center">
                                    <ng-container *ngIf="eventDate.event?.capacity && eventDate.event.capacity > 0; else noCapacityTemplateRef">
                                        <div class="tnt-progress-bar--vertical">
                                            <div class="tnt-progress-bar--vertical__label">
                                                {{ eventDate.confirmedTickets > 0 ? eventDate.confirmedTickets: '0' }}/{{ eventDate.event.capacity }}
                                            </div>
                                            <div class="tnt-progress-bar--vertical__progress">
                                                <div class="tnt-progress-bar--vertical__progress__inner">
                                                    <nz-progress [nzPercent]="Math.floor((eventDate.confirmedTickets * 100)/eventDate.event.capacity)" [nzShowInfo]="false"></nz-progress>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #noCapacityTemplateRef>
                                        {{ eventDate.confirmedTickets > 0 ? eventDate.confirmedTickets: '0' }}
                                    </ng-template>
                                </td>
                                <ng-container *ngxPermissionsOnly="[UserRoleEnum.SalesChannel]; else elseAlertColumnBlock">
                                    <td nzAlign="center" (click)="$event.stopPropagation(); $event.preventDefault();">
                                        <button nz-button nzSize="small" (click)="onAddBookingButtonClick(eventDate) "class="tnt-button__primary--reverse">
                                            <i class="fa-solid fa-plus"></i> Add Booking
                                        </button>
                                    </td>
                                </ng-container>
                                <ng-template #elseAlertColumnBlock>
                                    <td nzAlign="center" (click)="$event.stopPropagation(); $event.preventDefault();">
                                        <ng-container *ngIf="eventDate?.isAlertOn; else alertOffRef">
                                            <i class="fa-solid fa-bell tnt-alert--on"></i>
                                        </ng-container>
                                        <ng-template #alertOffRef>
                                            <i class="fa-solid fa-bell tnt-alert--off"></i>
                                        </ng-template>
                                    </td>
                                    <td nzAlign="center" (click)="$event.stopPropagation(); $event.preventDefault();">
                                        <button nz-button nzType="default" nzShape="circle" class="tnt-dropdown__button"
                                            nz-dropdown
                                            nzTrigger="click"
                                            [nzDropdownMenu]="eventMenuRef"
                                            [nzPlacement]="'topRight'"
                                            nzOverlayClassName="tnt-dropdown__content">
                                            <i class="fa-solid fa-ellipsis-vertical"></i>
                                        </button>
                                        <nz-dropdown-menu #eventMenuRef="nzDropdownMenu">
                                            <ul nz-menu>
                                                <li nz-menu-item (click)="onEventDateViewButtonClick(eventDate)">View</li>
                                                <li nz-menu-item (click)="onEventDateNoteButtonClick(eventDate)">Edit Note</li>
                                                <li nz-menu-item (click)="onEventDateExportButtonClick(eventDate)">Export (csv)</li>
                                                <li nz-menu-divider></li>
                                                <li nz-menu-item class="danger" (click)="onEventDateDeleteButtonClick(eventDate)">Delete</li>
                                            </ul>
                                        </nz-dropdown-menu>
                                    </td>
                                </ng-template>
                            </tr>
                        </tbody>
                    </nz-table>
                </div>
            </div>
        </div>   
    </ng-container>
</nz-drawer>

<ng-template #title>
    <div nz-row class="tnt-widget-table__header">
        <div nz-col nzSpan="22">
            <i class="fa-solid fa-calendar-days"></i> {{ date | date: 'MMM d, y' }}
        </div>
        <div nz-col nzSpan="2" class="tnt-widget-table__header__cross">
            <span (click)="close()">x</span>
        </div>
    </div>
</ng-template>

<app-booking-drawer #bookingDrawerRef (onBookingCreate)="onBookingCreate($event)"></app-booking-drawer>
<app-event-date-note-drawer #eventDateNoteDrawerRef (onEventDateUpdate)="onEventDateUpdate($event)"></app-event-date-note-drawer>
<app-event-date-edit-drawer #eventDateEditDrawerRef (onEventDateUpdate)="onEventDateUpdate($event)"></app-event-date-edit-drawer>
