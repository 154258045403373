import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, Observable } from 'rxjs';
import { environment } from '@env/environment';

import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { CompanyResponseModel } from '@shared/models/http/response/company/company-response.model';
import { CompanySettingResponseModel } from '@shared/models/http/response/company/company-setting-response.model';
import { CompanySettingUpdateRequestModel } from '@shared/models/http/request/company/company-setting-update-request.model';

@Injectable({
  providedIn: 'root'
})
export class CompanySettingService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  private settings = new CompanySettingResponseModel();
  
  constructor(
    private http: HttpClient
  ) { }
 
  get(companyId: number): Observable<CompanySettingResponseModel> {
    return this.http.get<ApiDataResponseModel<CompanyResponseModel>>(`${this.apiUrl}/companies/${companyId}`).pipe(
      map((response: ApiDataResponseModel<CompanyResponseModel>) => {
        return response.data.settings ?? {};
      }),
      tap((settings: CompanySettingResponseModel) => {
        this.settings = settings;
      })
    );
  }

  update(companyId: number, companySettingUpdateRequestModel: CompanySettingUpdateRequestModel): Observable<CompanySettingResponseModel> {
    return this.http.patch<ApiDataResponseModel<CompanyResponseModel>>(`${this.apiUrl}/companies/${companyId}/settings`, companySettingUpdateRequestModel).pipe(
      map((response: ApiDataResponseModel<CompanyResponseModel>) => {
        return response.data.settings ?? {};
      }),
      tap((settings: CompanySettingResponseModel) => {
        this.settings = settings;
      })
    );
  }

  getFromCache(): CompanySettingResponseModel {
    return this.settings;
  }
}
