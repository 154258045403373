import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import * as moment from 'moment-timezone';
import { NzModalService } from 'ng-zorro-antd/modal';

import { DateTimeUtil } from '@shared/utils/date-time-util';
import { validateFormGroup } from '@shared/utils/form.util';

import { EventDateService } from '@shared/services/event-date.service';

import { DateModel } from '@shared/models/http/request/event-date/event-date-update-multiple-request.model';
import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { EventDateResponseModel } from '@shared/models/http/response/event-date/event-date-response.model';
import { EventDateUpdateMultipleRequestModel } from '@shared/models/http/request/event-date/event-date-update-multiple-request.model';

@UntilDestroy()
@Component({
  selector: 'app-event-date-bulk-edit-drawer',
  templateUrl: './event-date-bulk-edit-drawer.component.html',
})
export class EventDateBulkEditDrawerComponent implements OnDestroy, OnInit {

  @Output() public onEventDateBulkUpdate = new EventEmitter<void>();
  @Output() public onEventDateBulkDelete = new EventEmitter<void>();

  Math = Math;
  eventDateTableScrollStyle = { x: '850px', y: '300px'};

  UserRoleEnum = UserRoleEnum;
  eventDateForm!: UntypedFormGroup;

  eventDates!: EventDateResponseModel[];

  isDrawerVisible = false;
  isUpdatingEventDates = false;

  constructor(
    private fb: UntypedFormBuilder,
    private modalService: NzModalService,
    private eventDateService: EventDateService
  ) { }

  ngOnInit(): void {
    this.eventDateForm = this.fb.group({
      venueName: [null],
      venueCity: [null],
      capacity: [null],
      dateTime: this.fb.group({
        time: [null],
      })
    });
  }

  open(eventDates: EventDateResponseModel[]) {
    this.eventDateForm.reset();
    this.eventDates = eventDates;
    this.isDrawerVisible = true;
  }

  close(): void {
    this.isDrawerVisible = false;
  }

  onUpdateClick() {
    this.updateBulkEventDate();
  }

  onUpdateButtonClick() {
    const affectedEventCount = this.eventDates.length;
    const modal = this.modalService.confirm({
      nzTitle: 'Important warning',
      nzContent: `
      <p>Are you sure you want to update the selected event dates?</p>
      <p>This action will apply updates to all selected event dates and cannot be undone.</p>
      `,
      nzOkText: 'Yes, Update',
      nzOkDanger: true,
      nzOnOk: () => new Promise(async resolve => {
        await this.updateBulkEventDate().then(() => {
          resolve();
        }).catch((err: any) => {
          return;
        });
        modal.updateConfig({
          nzOkLoading: false
        });
      })
    });
  }

  updateBulkEventDate() {
    return new Promise<void>((resolve, reject) => {
      validateFormGroup(this.eventDateForm);
      if (this.eventDateForm.invalid) {
        return;
      }

      this.isUpdatingEventDates = true;
      const eventDateBulkUpdateRequestModel = this.generateEventDateBulkUpdateRequestModel();
      this.eventDateService.updateMultiple(eventDateBulkUpdateRequestModel).pipe(untilDestroyed(this))
        .subscribe(() => {
          this.onEventDateBulkUpdate.emit();
          setTimeout(() => {
            this.isUpdatingEventDates = false;
            this.isDrawerVisible = false;
          }, 500);
          resolve();
        }, err => {
          this.isUpdatingEventDates = false;
          console.log('Error while updating bulk Event Dates. Error : ', err);
          reject();
        });
    });
  }

  generateEventDateBulkUpdateRequestModel(): EventDateUpdateMultipleRequestModel {
    const eventDateForm = this.eventDateForm.value;
    let dates: DateModel[] = [];
    this.eventDates.forEach(eventDate => {
      let date = new DateModel();
      date.id = eventDate.id;
      
      if (eventDateForm.dateTime.time) {
        date.start = DateTimeUtil.setTimeOption(moment(eventDate.start), eventDateForm.dateTime.time).toDate();
      }

      if (eventDateForm.venueName) {
        date.venueName = eventDateForm.venueName;
      }

      if (eventDateForm.venueCity) {
        date.venueCity = eventDateForm.venueCity;
      }

      if (eventDateForm.capacity) {
        date.capacity = eventDateForm.capacity;
      }

      dates = [...dates, date];
    });

    return new EventDateUpdateMultipleRequestModel(this.eventDates[0].event.id, dates);
  }

  onDeleteButtonClick() {
    const modal = this.modalService.confirm({
      nzTitle: 'Important warning',
      nzContent: `
      <p>Are you sure you want to delete the selected event dates?<p/>
      <p>If deleted, all bookings will be added to the bookings channel page and can be re-allocated to an event.</p>`,
      nzOkText: 'Yes, Delete it.',
      nzOkDanger: true,
      nzOnOk: () => new Promise(async resolve => {
        await this.deleteBulkEventDate().then(() => {
          this.onEventDateBulkDelete.emit();
          setTimeout(() => {
            this.isUpdatingEventDates = false;
            this.isDrawerVisible = false;
          }, 500);
          resolve();
        }).catch((err: any) => {
          return;
        });
        modal.updateConfig({
          nzOkLoading: false
        });
      })
    });
  }

  deleteBulkEventDate(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      const ids = [...this.eventDates.map(item => item.id)];
      this.eventDateService.deleteMultiple(this.eventDates[0].event.id, ids).pipe(untilDestroyed(this))
        .subscribe(() => {
          resolve();
        }, err => {
          console.log('Error while deleting Event Dates. Error : ', err);
          reject();
        });
    });
  }

  ngOnDestroy(): void {

  }
}
