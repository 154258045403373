import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, expand, map, reduce, tap, Observable } from 'rxjs';
import * as moment from 'moment-timezone';
import { environment } from '@env/environment';

import { MixpanelService } from './mixpanel.service';
import { BookingProviderService } from './booking-provider.service';

import { PaginationUtil } from '@shared/utils/pagination-util';
import { ChannelDateResponseModel } from '@shared/models/http/response/channel/date/channel-date-response.model';
import { ChannelDateRequestQueryModel } from '@shared/models/http/request/channel/date/channel-date-request-query.model';
import { ChannelDateCreateRequestModel } from '@shared/models/http/request/channel/date/channel-date-create-request.model';

import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { PaginationRequestModel } from '@shared/models/http/request/common/pagination-request.model';
import { PaginationResponseModel } from '@shared/models/http/response/common/pagination-response.model';
import { ChannelDateAssignToEventDateRequestModel } from '@shared/models/http/request/channel/date/channel-date-assign-to-event-date-request.model';

@Injectable({
  providedIn: 'root'
})
export class ChannelDateService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  constructor(
    private http: HttpClient,
    private mixpanelService: MixpanelService,
    private bookingProviderService: BookingProviderService
  ) { }

  listWithPagination(channelDateRequestQueryModel: ChannelDateRequestQueryModel, paginationRequestModel: PaginationRequestModel): Observable<PaginationResponseModel<ChannelDateResponseModel>> {
    let params = this.generateListQuery(channelDateRequestQueryModel, paginationRequestModel);
    return this.http.get<PaginationResponseModel<ChannelDateResponseModel>>(`${this.apiUrl}/channels/dates`, {
      params,
    }).pipe(
      map((response: PaginationResponseModel<ChannelDateResponseModel>) => {
        this.bookingProviderService.setChannelsSalesChannels(response.data);
        return response;
      })
    );
  }

  listWithoutPagination(channelDateRequestQueryModel: ChannelDateRequestQueryModel): Observable<ChannelDateResponseModel[]> {
    const paginationRequestModel = new PaginationRequestModel(1, PaginationUtil.defaultPageSize);
    return this.listWithPagination(channelDateRequestQueryModel, paginationRequestModel).pipe(
      expand(response => {
        paginationRequestModel.page++;
        if (response && response.totalPages && paginationRequestModel.page <= response.totalPages) {
          return this.listWithPagination(channelDateRequestQueryModel, paginationRequestModel);
        }
        return EMPTY;
      }),
      map((obj: any) => obj.data),
      reduce((acc, x: any) => acc.concat(x))
    );
  }
 
  getById(id: number): Observable<ChannelDateResponseModel> {
    return this.http.get<ApiDataResponseModel<ChannelDateResponseModel>>(`${this.apiUrl}/channels/dates/${id}`).pipe(
      map((response: ApiDataResponseModel<ChannelDateResponseModel>) => {
        this.bookingProviderService.setChannelsSalesChannels([response.data]);
        return response.data;
      })
    );
  }

  create(channelDateCreateRequestModel: ChannelDateCreateRequestModel): Observable<ChannelDateResponseModel> {
    return this.http.post<ApiDataResponseModel<ChannelDateResponseModel>>(`${this.apiUrl}/channels/dates`, channelDateCreateRequestModel).pipe(
      map((response: ApiDataResponseModel<ChannelDateResponseModel>) => {
        this.bookingProviderService.setChannelsSalesChannels([response.data]);
        return response.data;
      })
    );
  }

  assignToEventDate(id: number, channelDateAssignToEventDateRequestModel: ChannelDateAssignToEventDateRequestModel) {
    return this.http.put<any>(`${this.apiUrl}/channels/dates/${id}/event-date`, channelDateAssignToEventDateRequestModel).pipe(
      tap(() => {
        this.mixpanelService.track('User assigned a channel to an Event Date.', { channelId: id, eventDateId: channelDateAssignToEventDateRequestModel.eventDateId });
      })
    )
  }

  removeFromEventDate(id: number, eventDateId: number) {
    let params = new HttpParams().append('eventDateId', eventDateId);
    return this.http.delete<any>(`${this.apiUrl}/channels/dates/${id}/event-date`, {
      params,
    }).pipe(
      tap(() => {
        this.mixpanelService.track('User removed channel from an Event Date.', { channelId: id, eventDateId: eventDateId });
      })
    )
  }
  
  private generateListQuery(channelDateRequestQueryModel: ChannelDateRequestQueryModel, paginationRequestModel?: PaginationRequestModel): HttpParams {
    let params = PaginationUtil.setPaginationParams(paginationRequestModel);

    if (channelDateRequestQueryModel.eventDateId) {
      params = params.append('eventDateId', channelDateRequestQueryModel.eventDateId);
    }

    if (channelDateRequestQueryModel.salesChannelId) {
      params = params.append('salesChannelId', channelDateRequestQueryModel.salesChannelId);
    }

    if (channelDateRequestQueryModel.onlyUnassigned) {
      params = params.append('onlyUnassigned', channelDateRequestQueryModel.onlyUnassigned);
    }

    if (channelDateRequestQueryModel.eventName) {
      params = params.append('eventName', channelDateRequestQueryModel.eventName);
    }

    if (channelDateRequestQueryModel.ticketName) {
      params = params.append('ticketName', channelDateRequestQueryModel.ticketName);
    }

    if (channelDateRequestQueryModel.from) {
      const from = moment(channelDateRequestQueryModel.from, environment.timeZone).format('YYYY-MM-DD');
      params = params.append('from', from);
    }

    if (channelDateRequestQueryModel.to) {
      const to = moment(channelDateRequestQueryModel.to, environment.timeZone).format('YYYY-MM-DD');
      params = params.append('to', to);
    }

    return params;
  }
}
