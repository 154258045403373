import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '@env/environment';

import { UserService } from '@core/services/user.service';
import { SplashScreenService } from '@core/services/splash-screen.service';
import { NavigationService } from '@shared/services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {

  constructor(
    private router: Router,
    private userService: UserService,
    private navigationService: NavigationService,
    private splashScreenService: SplashScreenService
  ) {
    
  }

  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // hide splash screen
        this.splashScreenService.hide();
  
        // to display back the body content
        setTimeout(() => {
          document.body.classList.add('page-loaded');
        }, 500);

        // Push URL information to Navigation Service history.
        this.navigationService.addUrlHistory(event, this.getCurrentPageTitle());
      }
    });
  }

  getCurrentPageTitle() {
    let route: ActivatedRoute = this.router.routerState.root;
    while (route!.firstChild) {
      route = route.firstChild;
    }
    return route.snapshot.data['title'];
  }
}
