export class CSVParserModel {

  constructor(fields: any, data: any[]) {
    this.fields = fields;
    this.data = data;
  }

  fields: any;
  data: any[];
}
