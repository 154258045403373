import { Pipe, PipeTransform } from '@angular/core';

/**
 * Split camel case to space-separated
 */
@Pipe({
  name: 'splitCamelCase',
})
export class SplitCamelCasePipe implements PipeTransform {

  transform(value: string): string {
    return value // insert a space before all caps
        .replace(/([A-Z])/g, ' $1')
        // uppercase the first character
        .replace(/^./, (str) => str.toUpperCase());
  }

}
