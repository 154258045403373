import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import * as moment from 'moment-timezone';
import { differenceInCalendarDays } from 'date-fns';

import { ObjectUtil } from '@shared/utils/object-util';
import { DateTimeUtil } from '@shared/utils/date-time-util';
import { validateFormGroup } from '@shared/utils/form.util';

import { EventResponseModel } from '@shared/models/http/response/event/event-response.model';
import { ChannelDateResponseModel } from '@shared/models/http/response/channel/date/channel-date-response.model';
import { DateModel, EventDateCreateMultipleRequestModel } from '@shared/models/http/request/event-date/event-date-create-multiple-request.model';

export enum EventPeriodEnum {
  ONCE = 'ONCE',
  DAILY = 'DAILY',
  WEEKLY = 'WEEKLY',
  MONTHLY = 'MONTHLY'
}

export enum MonthlyPeriodTypeEnum {
  BY_DAY_NUMBER,
  BY_DAY_NAME
}

export enum EndTypeEnum {
  BY_DATE = 'BY_DATE',
  BY_NUMBER = 'BY_NUMBER',
}

export class DayModel {
  name!: string;
  isSelected!: boolean
}

export class MonthlyPeriodModel {
  type!: MonthlyPeriodTypeEnum
  dayName!: string;
  dayNumber!: number;
  weekNumber!: number;
  label!: string;
  isSelected!: boolean
}

@Component({
  selector: 'app-event-date-schedule-drawer',
  templateUrl: './event-date-schedule-drawer.component.html',
})
export class EventDateScheduleDrawerComponent implements OnInit {

  @Output() scheduledEventDateSet = new EventEmitter<EventDateCreateMultipleRequestModel>();
  
  EventPeriodEnum = EventPeriodEnum;
  
  scheduleForm!: UntypedFormGroup;
  timeOptions: string[] = [];

  event!: EventResponseModel;
  channels: ChannelDateResponseModel[] = [];

  scheduledEventDateCreateMultipleRequestModel!: EventDateCreateMultipleRequestModel;

  selectedDay?: DayModel;
  isDrawerVisible = false;

  monthlyPeriodOptions: MonthlyPeriodModel[] = [];

  days = [
    { name: 'Mo', isSelected: false },
    { name: 'Tu', isSelected: false },
    { name: 'We', isSelected: false },
    { name: 'Th', isSelected: false },
    { name: 'Fr', isSelected: false },
    { name: 'Sa', isSelected: false },
    { name: 'Su', isSelected: false }
  ];

  disabledBeforeStartDate = (current: Date): boolean => {
    // Can not select days before today and today
    const scheduleForm = this.scheduleForm.value;
    if (scheduleForm.startDate) {
      return differenceInCalendarDays(current, scheduleForm.startDate) < 0;
    }
    return true;
  }

  constructor(
    private fb: UntypedFormBuilder
  ) {

  }
  
  ngOnInit(): void {
    this.scheduleForm = this.fb.group({
      startDate: [new Date(), [Validators.required]],
      startTime: [null, [Validators.required]],
      period: [EventPeriodEnum.ONCE, [Validators.required]],
      monthlyPeriod: [null],
      // endType: [EndTypeEnum.BY_DATE, [Validators.required]],
      endDate: [null, [Validators.required]],
      // endNumber: [null, [Validators.required]]
    });

    this.calculateDaySelection(EventPeriodEnum.WEEKLY);
  }

  open(event: EventResponseModel, channels: ChannelDateResponseModel[], startDate: Date): void {
    this.scheduleForm.controls['startDate'].patchValue(startDate);
    this.event = event;
    this.channels = channels;
    this.onStartDateChange(); 
    // Open Drawer
    this.isDrawerVisible = true;
  }

  close() {
    this.isDrawerVisible = false;
  }

  onSubmit() {
    this.setScheduledEvents();
  }

  setScheduledEvents() {
    validateFormGroup(this.scheduleForm);
    if (this.scheduleForm.invalid) {
      return;
    }

    const scheduleFormValue = this.scheduleForm.value;
    const period: EventPeriodEnum = scheduleFormValue.period;

    const startDate = DateTimeUtil.setTimeOption(moment(scheduleFormValue.startDate), scheduleFormValue.startTime).toDate();
    const endDate: Date = scheduleFormValue.endDate;
    const scheduleDateList = this.calculateScheduleList(period, startDate, endDate);

    let dates: DateModel[] = [];
    scheduleDateList.forEach(date => {
      dates = [...dates, new DateModel(date)];
    });

    const scheduledEventDateCreateMultipleRequestModel = this.generateScheduledEventDateCreateMultipleRequestModel(dates);
    this.scheduledEventDateSet.emit(scheduledEventDateCreateMultipleRequestModel);
    this.close();
  }

  calculateScheduleList(period: EventPeriodEnum, startDate: Date, endDate: Date): Date[] {
    switch (period) {
      case EventPeriodEnum.ONCE: {
        return [startDate];
      }
      case EventPeriodEnum.DAILY: {
        return this.getDaysBetweenDates(startDate, endDate);
      }
      case EventPeriodEnum.WEEKLY: {
        return this.getWeeksBetweenDates(startDate, endDate);
      }
      case EventPeriodEnum.MONTHLY: {
        const monthlyPeriod: MonthlyPeriodModel = this.scheduleForm.controls['monthlyPeriod'].value;
        return this.getMonthsBetweenDates(monthlyPeriod, startDate, endDate);
      }
    }
  }

  getDaysBetweenDates(startDate: Date, endDate: Date): Date[] {
    const now =  moment(startDate).clone(), dates = [];

    console.log('getDaysBetweenDates : startDate : ', startDate);
    console.log('getDaysBetweenDates : endDate : ', endDate);
    while (now.isSameOrBefore(moment(endDate))) {
        dates.push(now.toDate());
        now.add(1, 'days');
    }
    console.log('dates : ', dates);
    return dates;
  }

  getWeeksBetweenDates(startDate: Date, endDate: Date): Date[] {
    const now =  moment(startDate).clone(), dates = [];

    while (now.isSameOrBefore(moment(endDate))) {
        dates.push(now.toDate());
        now.add(7, 'days');
    }
    return dates;
  }

  getMonthsBetweenDates(monthlyPeriod: MonthlyPeriodModel, startDate: Date, endDate: Date): Date[]  {
    const scheduleFormValue = this.scheduleForm.value;
    const now =  moment(startDate).clone(), dates = [];

    if (monthlyPeriod.type == MonthlyPeriodTypeEnum.BY_DAY_NUMBER) {
      while (now.isSameOrBefore(moment(endDate))) {
        dates.push(now.toDate());
        now.add(1, 'months');
      }
    } else if (monthlyPeriod.type == MonthlyPeriodTypeEnum.BY_DAY_NAME) {
      while (now.isSameOrBefore(moment(endDate))) {
        // Get every days of month.
        let currentMonthDates = new Array(now.daysInMonth()).fill(null).map((x, i) => now.clone().startOf('month').add(i, 'days'));
        // Find the same day and week of the months. (Ex. Saturday of the 2nd week of every month.)
        currentMonthDates.forEach(element => {
          // Find week number of the month.
          const dayNumber = +(moment(element).format('D'));
          const weekNumber = Math.ceil(dayNumber / 7);
          // If day name and week number is same, add the day element to the array.
          if (monthlyPeriod.weekNumber === weekNumber && monthlyPeriod.dayName === moment(element).format('dddd')) {
            const dateWithTime = DateTimeUtil.setTimeOption(element.clone(), scheduleFormValue.startTime);
            dates.push(dateWithTime.toDate());
          }
        });
        now.add(1, 'months');
      }
    }

    return dates;
  }

  generateScheduledEventDateCreateMultipleRequestModel(dates: DateModel[]): EventDateCreateMultipleRequestModel {
    return new EventDateCreateMultipleRequestModel(this.event.id, dates, false, -1);
  }

  onStartDateChange() {
    const scheduleFormValue = this.scheduleForm.value;
    const period: EventPeriodEnum = scheduleFormValue.period;
    this.calculateDaySelection(period);
  }

  onPeriodChange(period: EventPeriodEnum) {
    this.calculateDaySelection(period);
  }

  calculateDaySelection(period: EventPeriodEnum) {
    switch (period) {
      case EventPeriodEnum.ONCE: {
        this.scheduleForm.controls['endDate'].clearValidators();
        this.days.forEach(element => {
          element.isSelected = false;
        });
        break;
      }
      case EventPeriodEnum.DAILY: {
        this.scheduleForm.controls['endDate'].addValidators([Validators.required]);
        this.days.forEach(element => {
          element.isSelected = true;
        });
        break;
      }
      case EventPeriodEnum.WEEKLY: {
        this.scheduleForm.controls['endDate'].addValidators([Validators.required]);
        const scheduleFormValue = this.scheduleForm.value;
        const startDate: Date = scheduleFormValue.startDate;
        if (!startDate) {
          break;
        }

        const day = moment(startDate).format('dd');
        this.days.forEach(element => {
          if (element.name === day) {
            element.isSelected = true;
          } else {
            element.isSelected = false;
          }
        });
        break;
      }
      case EventPeriodEnum.MONTHLY: {
        this.scheduleForm.controls['endDate'].addValidators([Validators.required]);
        const scheduleFormValue = this.scheduleForm.value;
        const startDate: Date = scheduleFormValue.startDate;
        if (!startDate) {
          break;
        }

        const day = moment(startDate).format('dd');
        this.days.forEach(element => {
          if (element.name === day) {
            element.isSelected = true;
          } else {
            element.isSelected = false;
          }
        });

        // Create Monthly Select Options
        const dayName = moment(startDate).format('dddd');
        const dayNumber = +(moment(startDate).format('D'));
        const week = Math.ceil(dayNumber / 7);
        const special = ['zeroth', 'first', 'second', 'third', 'fourth', 'fifth'];
        this.monthlyPeriodOptions = [
          {
            type: MonthlyPeriodTypeEnum.BY_DAY_NUMBER,
            weekNumber: week,
            dayName: dayName,
            dayNumber: dayNumber,
            label: `On the ${moment(startDate).format('Do')}`,
            isSelected: true
          },
          { 
            type: MonthlyPeriodTypeEnum.BY_DAY_NAME,
            weekNumber: week,
            dayName: dayName,
            dayNumber: dayNumber,
            label: `On the ${special[week]} ${dayName}`,
            isSelected: false
          }
        ];
        
        this.scheduleForm.controls['monthlyPeriod'].patchValue(this.monthlyPeriodOptions[0]);
        break;
      }
    }
  }

  originalOrder = () => 0;
}