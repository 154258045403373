import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, tap, Observable } from 'rxjs';
import { environment } from '@env/environment';

import { MixpanelService } from './mixpanel.service';

import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { PaginationResponseModel } from '@shared/models/http/response/common/pagination-response.model';

import { VenueResponseModel } from '@shared/models/http/response/venue/venue-response.model';
import { VenueRequestQueryModel } from '@shared/models/http/request/venue/venue-request-query.model';
import { VenueCreateRequestModel } from '@shared/models/http/request/venue/venue-create-request.model';
import { VenueUpdateRequestModel } from '@shared/models/http/request/venue/venue-update-request.model';

@Injectable({
  providedIn: 'root'
})
export class VenueService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  constructor(
    private http: HttpClient,
    private mixpanelService: MixpanelService
  ) { }

  list(venueRequestQueryModel: VenueRequestQueryModel): Observable<PaginationResponseModel<VenueResponseModel>> {
    return this.http.get<PaginationResponseModel<VenueResponseModel>>(`${this.apiUrl}/venues`);
  }
 
  getById(id: number): Observable<VenueResponseModel> {
    return this.http.get<ApiDataResponseModel<VenueResponseModel>>(`${this.apiUrl}/venues/${id}`).pipe(
      map((response: ApiDataResponseModel<VenueResponseModel>) => {
        return response.data;
      })
    );
  }

  create(venueCreateRequestModel: VenueCreateRequestModel): Observable<VenueResponseModel> {
    return this.http.post<ApiDataResponseModel<VenueResponseModel>>(`${this.apiUrl}/venues`, venueCreateRequestModel).pipe(
      map((response: ApiDataResponseModel<VenueResponseModel>) => {
        return response.data;
      }),
      tap((venue: VenueResponseModel) => {
        this.mixpanelService.track('User created a venue.', venue);
      })
    );
  }

  update(id: number, venueUpdateRequestModel: VenueUpdateRequestModel): Observable<VenueResponseModel> {
    return this.http.put<ApiDataResponseModel<VenueResponseModel>>(`${this.apiUrl}/venues/${id}`, venueUpdateRequestModel).pipe(
      map((response: ApiDataResponseModel<VenueResponseModel>) => {
        return response.data;
      }),
      tap((venue: VenueResponseModel) => {
        this.mixpanelService.track('User updated a venue.', venue);
      })
    );
  }

  delete(id: number): Observable<void> {
    return this.http.delete<void>(`${this.apiUrl}/venues/${id}`).pipe(
      tap(() => {
        this.mixpanelService.track('User deleted a venue.', { venueId: id });
      })
    );
  }
}
