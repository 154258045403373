<nz-drawer [nzClosable]="false" [nzVisible]="isDrawerVisible" [nzWidth]="720" nzPlacement="right" [nzTitle]="titleTpl"
    [nzFooter]="footerTpl" (nzOnClose)="close()">
    <ng-container *nzDrawerContent>
        <div class="tnt-drawer-booking">
            <form nz-form [formGroup]="bookingForm" nzLayout="vertical" class="tnt-drawer-booking__booking-form">
                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="status">Status</nz-form-label>
                            <nz-select formControlName="status" nzSize="large"
                                nzPlaceHolder="Select status">
                                <nz-option *ngFor="let status of StatusEnum | keyvalue" [nzLabel]="status.key | titlecase" [nzValue]="status.value"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="bookingProvider">Channels</nz-form-label>
                            <nz-select formControlName="bookingProvider" nzSize="large" (ngModelChange)="onBookingProviderChange($event)"
                                nzPlaceHolder="Select a provider" [nzDisabled]="isSalesChannelUser">
                                <nz-option *ngFor="let provider of bookingProviders" [nzLabel]="provider.name | splitCamelCase" [nzValue]="provider.id"></nz-option>
                            </nz-select>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="channelTicketType">
                                Ticket Type
                            </nz-form-label>
                            <nz-select formControlName="channelTicketType" nzSize="large" [nzDropdownRender]="ticketTypeDropdownRenderTemplate"
                                [nzNotFoundContent]="ticketTypeNotFoundContentTemplate" nzPlaceHolder="Select a ticket type" nzPlacement="bottomRight"
                                [nzLoading]="isLoadingChannelTicketTypes">
                                <ng-container *ngFor="let ticketType of ticketTypes">
                                    <nz-option *ngIf="!isLoadingChannelTicketTypes" [nzLabel]="ticketType.name! | splitCamelCase" [nzValue]="ticketType.id"></nz-option>
                                </ng-container>
                                <nz-option *ngIf="isLoadingChannelTicketTypes" nzDisabled nzCustomContent>
                                    <div class="text-center">
                                        <span nz-icon nzType="loading" class="loading-icon"></span> Loading Data...
                                    </div>
                                </nz-option>
                            </nz-select>
                        </nz-form-item>
                        <ng-template #ticketTypeNotFoundContentTemplate>
                            <div class="text-center">No ticket types available.</div>
                        </ng-template>
                        <ng-template #ticketTypeDropdownRenderTemplate>
                            <ng-container *ngIf="!isLoadingChannelTicketTypes">
                                <nz-divider nzDashed [nzText]="text" *ngIf="ticketTypes.length" style="margin: 12px 0">
                                    <ng-template #text>
                                        <span>+</span>
                                    </ng-template>
                                </nz-divider>
                                <div class="container">
                                    <button nz-button nzType="dashed" nzBlock (click)="openChannelTicketTypeCreateModal()">
                                        Create a new one
                                    </button>
                                </div>
                            </ng-container>
                        </ng-template>
                    </div>
                </div>
                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="people">People</nz-form-label>
                            <nz-form-control>
                                <nz-input-number formControlName="people" [nzMin]="1" [nzStep]="1"
                                    nzPlaceHolder="People" nzSize="large"></nz-input-number> 
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="pricePerPerson">Price per person</nz-form-label>
                            <nz-form-control>
                                <nz-input-number formControlName="singlePrice" [nzMin]="0" [nzStep]="1"
                                    nzPlaceHolder="Price per person" nzSize="large"></nz-input-number>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="totalPaid">Total paid</nz-form-label>
                            <nz-form-control>
                                <nz-input-number formControlName="totalPaid" [nzMin]="0" [nzStep]="1"
                                    nzPlaceHolder="Total paid" nzSize="large"></nz-input-number>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            
                <div nz-row nzGutter="24" formGroupName="customer">
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="name">Customer name</nz-form-label>
                            <nz-form-control>
                                <input nz-input formControlName="name" placeholder="Customer name" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="email">Customer email</nz-form-label>
                            <nz-form-control>
                                <input nz-input formControlName="email" placeholder="Customer email" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="phone">Customer phone</nz-form-label>
                            <nz-form-control>
                                <input nz-input formControlName="phone" placeholder="Customer phone" nzSize="large" />
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>

                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="purchaseReference">Order ID</nz-form-label>
                            <nz-form-control>
                                <nz-input-group nzSearch nzSize="large" [nzAddOnAfter]="suffixButton">
                                    <input nz-input formControlName="purchaseReference" placeholder="Order ID" nzSize="large" />
                                </nz-input-group>
                                <ng-template #suffixButton>
                                    <button nz-button nzSize="large" (click)="generateOrderId()">Generate</button>
                                </ng-template>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="purchaseDate">Order Date</nz-form-label>
                            <nz-form-control>
                                <nz-date-picker formControlName="purchaseDate" nzSize="large" nzFormat="dd/MM/yyyy"></nz-date-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                    <div nz-col nzSpan="8">
                        <nz-form-item>
                            <nz-form-label nzFor="purchaseTime">Order Time</nz-form-label>
                            <nz-form-control>
                                <nz-time-picker formControlName="purchaseTime" nzSize="large" nzFormat="HH:mm"></nz-time-picker>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            
                <div nz-row nzGutter="24">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-label nzFor="additionalInfo">Additional information</nz-form-label>
                            <nz-form-control>
                                <textarea nz-input formControlName="notes" placeholder="Additional information"
                                    [nzAutosize]="{ minRows: 4, maxRows: 4 }" nzSize="large"></textarea>
                            </nz-form-control>
                        </nz-form-item>
                    </div>
                </div>
            
                <div nz-row nzGutter="24" formArrayName="checkoutOptions">
                    <div nz-col nzSpan="24">
                        <nz-form-item>
                            <nz-form-label nzFor="phone">Checkout options</nz-form-label>
                            <nz-form-control>
                                <button nz-button nzType="dashed" class="add-button" (click)="addOptionItem()">
                                    <i nz-icon nzType="plus"></i>
                                    Add option
                                </button>
                            </nz-form-control>
                        </nz-form-item>
            
                        <ng-container *ngFor="let control of checkoutOptions.controls; let i = index">
                            <div nz-row nzGutter="24" [formGroupName]="i">
                                <div nz-col nzSpan="8">
                                    <nz-form-item>
                                        <nz-form-label nzFor="">Option</nz-form-label>
                                        <nz-form-control>
                                            <input nz-input formControlName="option" placeholder="Option" nzSize="large" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col nzSpan="8">
                                    <nz-form-item>
                                        <nz-form-label nzFor="value">Value</nz-form-label>
                                        <nz-form-control>
                                            <input nz-input formControlName="value" placeholder="Value" nzSize="large" />
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                                <div nz-col nzSpan="4">
                                    <nz-form-item>
                                        <nz-form-label></nz-form-label>
                                        <nz-form-control>
                                            <button nz-button nzType="dashed" class="add-button" (click)="removeOptionItem(i)"
                                                nzSize="large">
                                                <i nz-icon nzType="minus" nzTheme="outline"></i>
                                                Remove
                                            </button>
                                        </nz-form-control>
                                    </nz-form-item>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </form>
        </div>
        
    </ng-container>

    <ng-template #footerTpl>
        <div class="tnt-widget-drawer__footer">
          <button nz-button (click)="close()">Cancel</button>
          <button nz-button nzType="primary" [nzLoading]="selectedBooking ? isUpdatingBooking : isCreatingBooking" (click)="handleOk()">{{ nzDrawerzOkText }}</button>
        </div>
      </ng-template>

      <ng-template #titleTpl>
        <div nz-row class="tnt-widget-table__header">
            <div nz-col nzSpan="22">
                <i class="fa-solid fa-clipboard-check"></i> {{ nzDrawerTitle }}
            </div>
            <div nz-col nzSpan="2" class="tnt-widget-table__header__cross">
                <span (click)="close()">x</span>
            </div>
        </div>
    </ng-template>
</nz-drawer>

<nz-modal [(nzVisible)]="isChannelTicketTypeCreateModalVisible" [nzTitle]="modalTitle" [nzContent]="modalContent" [nzFooter]="modalFooter"
    (nzOnCancel)="handleChannelTicketTypeCreateModalCancel()" (nzOnOk)="handleChannelTicketTypeCreateModalOk()">
    <ng-template #modalTitle>Create a new ticket type</ng-template>

    <ng-template #modalContent>
        <form nz-form [formGroup]="channelTicketTypeForm" nzLayout="vertical">
            <nz-form-label nzFor="ticketType">Ticket type name:</nz-form-label>
            <nz-form-control>
                <input nz-input formControlName="ticketType" placeholder="Please enter the ticket type name..." nzSize="large" />
            </nz-form-control>
        </form>
    </ng-template>

    <ng-template #modalFooter>
        <button nz-button nzType="default" (click)="handleChannelTicketTypeCreateModalCancel()">Cancel</button>
        <button nz-button nzType="primary" (click)="handleChannelTicketTypeCreateModalOk()" [nzLoading]="isCreatingChannelTicketType" [disabled]="!channelTicketTypeForm.valid">Submit</button>
    </ng-template>
</nz-modal>


