export class MailRequestModel {

    constructor(companyId: number, emails: string[], subject: string, body: string, customArgs?: MailCustomArgumentModel) {
      this.companyId = companyId
      this.tos = emails;
      this.subject = subject;
      this.body = body;
      this.customArgs = customArgs;
    }
  
    
    companyId!: number;
    tos!: string[]; // Emails
    subject!: string;
    body!: string;
    customArgs?: MailCustomArgumentModel;
  }

  export class MailCustomArgumentModel {

    constructor(companyId: number, channelDateId: number, eventDateId: number) {
      this.companyId = companyId;
      this.channelDateId = channelDateId;
      this.eventDateId = eventDateId;
    }

    companyId!: number;
    channelDateId!: number;
    eventDateId?: number;
  }