export class EventDateRequestQueryModel {

    constructor(from?: Date, to?: Date) {
        this.from = from;
        this.to = to;
    }

    companyId!: number;
    eventId?: number;
    venueId?: number;
    from?: Date
    to?: Date
}
