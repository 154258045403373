export class DateRangeModel {

  constructor(startDate: Date, endDate: Date) {
    this.startDate = startDate;
    this.endDate = endDate;
  }

  startDate: Date;
  endDate: Date;
}
