export class ChannelDateRequestQueryModel {

    constructor(eventDateId?: number) {
        this.eventDateId = eventDateId;
    }

    eventDateId?: number;
    salesChannelId?: number;
    onlyUnassigned?: boolean;
    from?: Date
    to?: Date
    eventName?: string;
    ticketName?: string;
}
