import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { EMPTY, expand, map, reduce, Observable } from 'rxjs';
import { environment } from '@env/environment';

import { PaginationUtil } from '@shared/utils/pagination-util';
import { ApiDataResponseModel } from '@shared/models/http/response/common/api-data-response.model';
import { PaginationRequestModel } from '@shared/models/http/request/common/pagination-request.model';
import { PaginationResponseModel } from '@shared/models/http/response/common/pagination-response.model';
import { ChannelTicketTypeResponseModel } from '@shared/models/http/response/channel/ticket-type/channel-ticket-type-response.model';
import { ChannelTicketTypeRequestQueryModel } from '@shared/models/http/request/channel/ticket-type/channel-ticket-type-request-query.model';
import { ChannelTicketTypeCreateRequestModel } from '@shared/models/http/request/channel/ticket-type/channel-ticket-type-create-request.model';

@Injectable({
  providedIn: 'root'
})
export class ChannelTicketTypeService {

  private apiUrl = `${environment.apiUrl}/${environment.apiVersion}`;

  constructor(
    private http: HttpClient
  ) { }

  listWithPagination(channelTicketTypeRequestQueryModel: ChannelTicketTypeRequestQueryModel, paginationRequestModel: PaginationRequestModel): Observable<PaginationResponseModel<ChannelTicketTypeResponseModel>> {
    let params = this.generateListQuery(channelTicketTypeRequestQueryModel, paginationRequestModel);
    return this.http.get<PaginationResponseModel<ChannelTicketTypeResponseModel>>(`${this.apiUrl}/channels/ticket-types`, {
      params,
    });
  }

  listWithoutPagination(channelTicketTypeRequestQueryModel: ChannelTicketTypeRequestQueryModel): Observable<ChannelTicketTypeResponseModel[]> {
    const paginationRequestModel = new PaginationRequestModel(1, PaginationUtil.defaultPageSize);
    return this.listWithPagination(channelTicketTypeRequestQueryModel, paginationRequestModel).pipe(
      expand(response => {
        paginationRequestModel.page++;
        if (response && response.totalPages && paginationRequestModel.page <= response.totalPages) {
          return this.listWithPagination(channelTicketTypeRequestQueryModel, paginationRequestModel);
        }
        return EMPTY;
      }),
      map((obj: any) => obj.data),
      reduce((acc, x: any) => acc.concat(x))
    );
  }
 
  getById(id: number): Observable<ChannelTicketTypeResponseModel> {
    return this.http.get<any>(`${this.apiUrl}/channels/ticket-types/${id}`).pipe(
      map((response: ApiDataResponseModel<ChannelTicketTypeResponseModel>) => {
        return response.data;
      })
    );
  }

  create(channelTicketTypeCreateRequestModel: ChannelTicketTypeCreateRequestModel): Observable<ChannelTicketTypeResponseModel> {
    return this.http.post<any>(`${this.apiUrl}/channels/ticket-types`, channelTicketTypeCreateRequestModel).pipe(
      map((response: ApiDataResponseModel<ChannelTicketTypeResponseModel>) => {
        return response.data;
      })
    );
  }
  
  private generateListQuery(channelTicketTypeRequestQueryModel: ChannelTicketTypeRequestQueryModel, paginationRequestModel?: PaginationRequestModel): HttpParams {
    let params = PaginationUtil.setPaginationParams(paginationRequestModel);

    if (channelTicketTypeRequestQueryModel.companyId) {
      params = params.append('companyId', channelTicketTypeRequestQueryModel.companyId);
    }

    if (channelTicketTypeRequestQueryModel.salesChannelId) {
      params = params.append('salesChannelId', channelTicketTypeRequestQueryModel.salesChannelId);
    }

    if (channelTicketTypeRequestQueryModel.eventId) {
      params = params.append('eventId', channelTicketTypeRequestQueryModel.eventId);
    }

    if (channelTicketTypeRequestQueryModel.ticketName) {
      params = params.append('ticketName', channelTicketTypeRequestQueryModel.ticketName);
    }

    return params;
  }
}
