<div *ngIf="content" (click)="$event.stopPropagation(); $event.preventDefault()">
    <a #popoveContent nz-popover [nzPopoverContent]="contentTemplate" nzPopoverTrigger="click" class="tnt-ellipsis-popover"
        [style.-webkit-line-clamp]="maxLine">{{ content }}</a>
    <ng-template #contentTemplate>
        <div class="tnt-ellipsis-popover__popover">{{ content }}</div>
        <div class="text-right">
            <button nz-button nzSize="small" nzType="primary" (click)="triggerOutside.click()">Ok</button>
        </div>
    </ng-template>
    <div #triggerOutside></div>
</div>

