import { UserRoleEnum } from '@shared/enums/user-role.enum';
import { AuthContextModel } from '@shared/models/security/auth-context.model';

export class AuthenticationUtil {
    
    static getAuthContext(): AuthContextModel | undefined {
        const authContextString = localStorage.getItem('auth_context');
        if (!authContextString) {
            return;
        }
        return JSON.parse(authContextString) as AuthContextModel;
    }

    static setAuthContext(authContextModel: AuthContextModel) {
        localStorage.setItem('auth_context', JSON.stringify(authContextModel));
    }

    static hasRole(role: UserRoleEnum): boolean {
        const userContextString =  localStorage.getItem('user_context');
        if (!userContextString) {
          return false;
        }
    
        const userContext = JSON.parse(userContextString);
        if (userContext) {
            return role === userContext.role;
        }
        
        return false;
    }
}